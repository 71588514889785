import React, {useState} from 'react';
import FunctionResults from "./FunctionResults";
import FunctionDetail from "./FunctionDetail";

function FunctionListView() {
  const [selectedFunction, setSelectedFunction] = useState(null)
  const [detailNavigate, setDetailNavigate] = useState(false)

  return (
    <div>
      {!detailNavigate &&
        <FunctionResults setSelectedFunction={setSelectedFunction} setDetailNavigate={setDetailNavigate}/>
      }
      {detailNavigate &&
        <FunctionDetail selectedFunction={selectedFunction} setDetailNavigate={setDetailNavigate}/>
      }
    </div>
  );
}

export default FunctionListView;
