import React, {useState} from 'react';
import {Box, Container, Tab, Tabs} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import ProjectCreateUpdateForm from "../ProjectAddView/ProjectCreateUpdateForm";
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";
import {MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {getUriSetting, saveUriSetting, SELECTED_TAB} from "../../../utils/optionsUriLocalStorage";
import Webhook from "./Webhook";
import Config from "./Config";
import UploadLocalSite from "./UploadLocalSite";
import FunctionResults from "./Functions/FunctionListView/FunctionResults";
import FunctionListView from "./Functions/FunctionListView";
import {brandGetEnoughCredits} from "../../../utils/checkUtils";
import NoCredit from "../../NotCredit";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const ProjectDetailsView = () => {
  const classes = useStyles();
  const {currentBrand} = useAuth()
  const [currentTab, setCurrentTab] = useState(getUriSetting(SELECTED_TAB) || 'general');

  const handleTabsChange = (event, value) => {
    saveUriSetting(SELECTED_TAB, value)
    setCurrentTab(value);
  };

  return (
    <Page
      className={classes.root}
      title={localStrings.project}
    >
      {brandGetEnoughCredits(currentBrand()) ?
        <Container maxWidth="lg">
          <Header/>
          <Box mt={3}>

            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              textColor="secondary"
              variant="scrollable"
            >
              <Tab label={localStrings.general} value="general"/>
              <Tab label={localStrings.webhook} value="webhook"/>
              <Tab label={localStrings.config} value="config"/>
              <Tab label={localStrings.uploadLocalSile} value="uploadLocalSile"/>
              <Tab label={localStrings.functions} value="functions"/>

            </Tabs>
            {currentTab === 'general' && <ProjectCreateUpdateForm modeEdit={MODE_EDIT}/>}
            {currentTab === 'webhook' && <Webhook/>}
            {currentTab === 'config' && <Config/>}
            {currentTab === 'uploadLocalSile' && <UploadLocalSite/>}
            {currentTab === 'functions' && <FunctionListView/>}
          </Box>
        </Container>
        :
        <NoCredit/>
      }
    </Page>
  );
};

export default ProjectDetailsView;
