import React from 'react';
import Color from 'color';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {Link as RouterLink} from 'react-router-dom';
import localStrings from '../../localStrings';
import {makeStyles} from "@mui/styles";
import { isMobile } from 'react-device-detect';

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
}));

const useStyles = makeStyles(() => ({
  actionArea: {
    borderRadius: 16,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  card: ({ color }) => ({
    width: isMobile ? '100%' : 256,
    height: 430,
    borderRadius: 16,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: color,
      padding: '1rem 1.5rem 1.5rem',
      height: '100%'
    };

  },
  title: {
    // a: {
    //   '&::before': {
    //     textDecoration: 'none',
    //   }
    // },
    fontFamily: 'Keania One',
    fontSize: '1.75rem',
    color: '#fff',
    textTransform: 'uppercase',
    textDecoration: 'none',

  },
  subtitle: {
    fontFamily: 'Montserrat',
    color: '#fff',
    opacity: 0.87,
    marginTop: '2rem',
    fontWeight: 500,
    fontSize: 14,
  },
}));



export const SolidCards =
  React.memo(function SolidGameCard() {

    const SimpleCard = ({ classes, image, title, subtitle, link }) => {
      //const mediaStyles = useFourThreeCardMediaStyles();
      return (
        <RouterLink to={link} style={{ textDecoration: 'none' }}>
          {/*<CardActionArea className={classes.actionArea} onMouseOver={() => playSoundIfActive(playHover)}>*/}
          <Card style={{
            borderRadius: 16,
            borderColor: 'black',
            border: 'solid',
            boxShadow: 'none',
            width: "200px",
            minHeight: "300px",
          }}>

            <CardMedia
              component="img"
              width="180px"
              image={image}
            />
            {/*<CardMedia image={image} />*/}
            {/*<p>{image}</p>*/}
            <CardContent>
              <Typography variant={'h4'} style={{textAlign: "center"}}>
                {title}
              </Typography>
              {/*<Typography>{subtitle}</Typography>*/}
            </CardContent>
          </Card>
          {/*</CardActionArea>*/}
        </RouterLink>
      );
    };

    const gridStyles = useGridStyles();


    return (
      <div style={{width: '100%'}}>
        <Grid classes={gridStyles} container spacing={4} justifyContent="center">
          <Grid item >
            <SimpleCard
              //classes={styles}
              title={localStrings.projects}
              link="/app/projects"
              //subtitle={localStrings.productListSubTitle}
              image={
                '/static/icons/projectMgt/Clipboard-Management-Project-Setting-List.png'
              }
            />
          </Grid>
          <Grid item>
            <SimpleCard

              // classes={styles2}
              title={localStrings.devices}
              link={"/app/devices"}
              // subtitle={localStrings.orderListSubTitle}
              image={
                '/static/icons/projectMgt/Computer-File-Office Supplies-Plan-Stationery.png'
              }
            />
          </Grid>
          <Grid item>
            <SimpleCard

              // classes={styles2}
              title={localStrings.boards}
              link={"/app/boards"}
              // subtitle={localStrings.orderListSubTitle}
              image={
                '/static/icons/projectMgt/Analytics-Graph-Browser-Statistics-Online.png'
              }
            />
          </Grid>

          <Grid item>
            <SimpleCard

              // classes={styles2}
              title={localStrings.stripePayAccounts}
              link={"/app/stripePayAccounts"}
              // subtitle={localStrings.orderListSubTitle}
              image={
                '/static/icons/projectMgt/Money Bag-Banking-Network-Saving-Connection.png'
              }
            />
          </Grid>

        </Grid>
      </div>
    );
  });
export default SolidCards
