import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, CardHeader, Divider, Link} from "@mui/material";
import localStrings from "../../../localStrings";
import {executeQueryUtil} from "../../../utils/gqlUtil";
import {getDeviceQuery} from "../../../gql/DeviceGql";
import {useParams} from "react-router-dom";

function Logs({}) {
  let {deviceId} = useParams();
  const [device, setDevice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res =  await executeQueryUtil(getDeviceQuery(deviceId));
      setDevice(res.data?.getDevice);
    }
    fetchData()
      .catch(console.error);
  }, [])

  return (
    <div style={{ width: '100%' }}>
      <Card>

        <CardHeader title={localStrings.commandsToDevice}/>
        <Divider/>
        <CardContent>
          <Box>

            <>
              {device && device.commandLogUrl &&
                <Link href={device.commandLogUrl} target="new">
                  <Button variant="contained">{localStrings.browseCommandLogs}</Button>
                </Link>
              }
              {device && device.wsLogUrl &&
                <Link href={device.wsLogUrl} target="new">
                  <Button variant="contained">{localStrings.browseWsLogs}</Button>
                </Link>
              }
            </>
          </Box>

        </CardContent>
      </Card>
    </div>
  );
}

export default Logs;
