import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Avatar, Box, Chip, Hidden, IconButton, Toolbar, Tooltip} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import Logo from './Logo';
import useAuth from "../hooks/useAuth";
import localStrings from "../localStrings";
import {doc, getFirestore} from "../lib/firebase";
import {BRAND_COLLECTION} from "../utils/constants";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {useNavigate} from "react-router";
import SwitchBrand from "./SwitchBrand";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const {currentBrand} = useAuth()
  const db = getFirestore();
  const navigate = useNavigate();
  const refBrand = doc(db, BRAND_COLLECTION, currentBrand()?.id || "-1");
  const [brandDb, brandDbLoading, brandDbError] = useDocumentData(refBrand, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const { logout, superAdmin, userInDb } = useAuth();

  function getCredit() {
    if (brandDb?.credit == undefined) {
      return "";
    }
    return parseFloat(brandDb.credit) + " " + localStrings.kioskCredits;
  }

  return (
    <AppBar
      style={{ background: '#1B81D3' }}
      elevation={0}
      {...rest}
    >
      {/*<p>{JSON.stringify(brandDb)}</p>*/}
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden xlDown>
          {(superAdmin || (userInDb && userInDb.allowedBrandIds != null && userInDb.allowedBrandIds.length > 0 &&
              !(userInDb?.allowedBrandIds.length == 1 && userInDb?.allowedBrandIds[0] == userInDb.brandId)
            )) &&
            <SwitchBrand />
          }
          <Chip
            onClick={() => navigate('/app/account', { replace: true })}
            avatar={<Avatar>¤</Avatar>}
            label={getCredit()}
            variant="outlined"/>

          <Tooltip title={localStrings.logout}>
            <IconButton color="inherit" size="large" onClick={() => logout()}>
              <InputIcon />
            </IconButton>
          </Tooltip>


        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
