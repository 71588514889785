import React from 'react';
import {FormControlLabel, Grid, Switch, TextField} from "@mui/material";
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import localStrings from "../../../localStrings";
import {getAppRoute, stripePayAccountsRoute} from "../../../routes";
import {useNavigate} from "react-router";
import TextFieldWithCopy from "../../../components/TextFieldWithCopy";
import conf from "../../../conf/config.json";
import {
  addDataStripePayAccountToCache,
  createStripePayAccountMutation,
  getStripePayAccountQuery,
  updateStripePayAccountMutation
} from "../../../gql/stripePayAccountGql";
import TextFieldPassword from "../../../components/TextFieldPassword";


export const getAccount = async (brandId, accountId, modeEdit) => {
  if (modeEdit == MODE_CREATE) {
    return null;
  }
  if (!modeEdit || modeEdit == MODE_EDIT) {
    var items =  await executeQueryUtil(getStripePayAccountQuery(brandId, accountId));
    return items;
  }
  return null;
}

export const saveForm = async (item, values, modeEdit, brandId, navigate) => {
  let data;
  if (!modeEdit || modeEdit == MODE_EDIT) {
    data = {
      ...item,
      ...values,
    };
    await executeMutationUtil(updateStripePayAccountMutation(brandId, data));
  }

  else if (modeEdit == MODE_CREATE){
    data = {...values};
    const projectCreated = await executeMutationUtil(createStripePayAccountMutation(brandId, data));
    await addDataStripePayAccountToCache(brandId, [projectCreated.data?.addStripePayAccount]);
  }
  if (navigate) {
    navigate(getAppRoute(stripePayAccountsRoute))
  }

  return data;
}

const StripePayAccountCreateUpdateForm = ({className, modeEdit}) => {
  let {stripePayAccountId} = useParams();
  const { currentBrand } = useAuth();
  const navigate = useNavigate();


  const buildFormValues = (item) => {
    return {
      initialValues: {
        name: item.name || '',
        stripePrivateKey: item.stripePrivateKey || '',
      },
      validationSchema: {
        name: Yup.string().max(255).required(localStrings.check.fieldRequired),
        stripePrivateKey: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label={localStrings.stripePayAccountName}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.name}
            variant="outlined"
          />
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextFieldPassword
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.stripePrivateKey && errors.stripePrivateKey)}
            fullWidth
            helperText={touched.stripePrivateKey && errors.stripePrivateKey}
            label={localStrings.stripePrivateKey}
            name="stripePrivateKey"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.stripePrivateKey}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }




  return <GeneralEditor getItemFunction={() => getAccount(currentBrand().id, stripePayAccountId, modeEdit)}
                        className={className}
                        buildFormValues={buildFormValues}
                        getCardContentForm={getCardContentForm}
                        saveForm={(item, values) => saveForm(item, values, modeEdit, currentBrand().id, navigate)}
                        applyButton={modeEdit == MODE_CREATE ? localStrings.create : localStrings.update}
                        resetFormProp={modeEdit == MODE_CREATE}
                        messageSuccess={modeEdit == MODE_CREATE ?
                          localStrings.notif.projectCreated : localStrings.notif.stripePayAccountUpdated}
                        titleForm={modeEdit == MODE_CREATE ? localStrings.createStripePayAccount : localStrings.general}
                        getPathToData={(result) => {
                          return  result.data.getStripePayAccount;
                        }}
  />
};

export default StripePayAccountCreateUpdateForm;
