import React, {useEffect, useState} from 'react';
import {
  Autocomplete,
  Box, Card, CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl, FormControlLabel,
  Grid, InputLabel, MenuItem, Select, Switch,
  TextField
} from "@mui/material";
import {useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {executeMutationUtil, executeQueryUtil, executeQueryUtilSync} from "../../../utils/gqlUtil";
import localStrings from "../../../localStrings";
import {devicesRoute, getAppRoute} from "../../../routes";
import {useNavigate} from "react-router";
import {getDeviceQuery, updateDeviceMutation} from "../../../gql/DeviceGql";
import {getAllProjectsQuery} from "../../../gql/projectGql";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import conf from "../../../conf/config.json";
import TextFieldWithCopy from "../../../components/TextFieldWithCopy";

export const saveFormDevice = async (item, values, modeEdit, navigate) => {
  let data;
  delete values.changeChromeAddressUrl;
  delete values.printTicketUrl;
  delete values.rebootUrl;
  delete values.cameraUrl;
  delete values.speakUrl;
  delete values.version;

  if (!modeEdit || modeEdit == MODE_EDIT) {
    const projectId = values.project?.id;
    delete values.project;
    delete values.lite;

    let itemCopy = {...item};
    delete itemCopy.lite;
    data = {
      ...itemCopy,
      ...values,
      projectId: projectId || item.projectId
    };
    await executeMutationUtil(updateDeviceMutation(data));
  }
  navigate(getAppRoute(devicesRoute))
  return data;
}

const DeviceCreateUpdateForm = ({className, modeEdit}) => {
  let {deviceId} = useParams();
  const { currentBrand } = useAuth();
  const navigate = useNavigate();
  const [projectItems, setProjectItems] = useState(null);

  useEffect( () => {
    executeQueryUtilSync(getAllProjectsQuery(currentBrand().id)).then(result => {
      setProjectItems(result.data?.getProjects || []);
    })
  }, [currentBrand()]);

  const getDevice = async () => {
    if (modeEdit == MODE_CREATE) {
      return null;
    }
    if (modeEdit == MODE_EDIT) {
      var items =  await executeQueryUtil(getDeviceQuery(deviceId));
      return items;
    }
    return null;
  }

  function getChangeChromeUrl() {
    return conf.baseEventUrl + `/changeChromeAddress/${deviceId}`
  }

  function getPrinterTicketUrl() {
    return conf.baseEventUrl + `/printTicket/${deviceId}`;
  }

  function getRebootUrl() {
    return conf.baseEventUrl + `/reboot/${deviceId}`
  }

  function getSpeakUrl() {
    return conf.baseEventUrl + `/speak/${deviceId}`
  }

  const buildFormValues = (item) => {
    return {
      initialValues: {
        id: item.id || '',
        name: item.name || '',
        usbDevice: item.usbDevice || false,
        localPrinterIp: item.localPrinterIp || '',
        classicPrinterName: item.classicPrinterName || '',
        project: projectItems.find(p => p.id === item.projectId),
        changeChromeAddressUrl: getChangeChromeUrl(),
        printTicketUrl: getPrinterTicketUrl(),
        rebootUrl: getRebootUrl(),
        speakUrl: getSpeakUrl(),
        barCodeUsbDeviceName: item.barCodeUsbDeviceName || '',
        mouseSystemDevPath: item.mouseSystemDevPath || '',
        cameraUrl: conf.cameraUrl,
        wsLogUrl: item.wsLogUrl || '',
        commandLogUrl: item.commandLogUrl || '',
        screenSize: item.commandLogUrl || '1920,1080',
        screenRotation: item.screenRotation || 0,
      },
      validationSchema: {
        //name: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <>

        <Grid
          container
          spacing={4}
        >

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextFieldWithCopy
              inputProps={{
                autocomplete: "new-password"
              }}
              error={Boolean(touched.id && errors.id)}
              fullWidth
              helperText={touched.id && errors.id}
              label={localStrings.deviceId}
              name="id"
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
              value={values.id}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete: "new-password"
              }}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label={localStrings.deviceName}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <FormControlLabel
              control={(
                <Switch
                  checked={values.usbDevice}
                  edge="start"
                  name="direction"
                  onChange={() => {
                    setFieldValue('usbDevice', !values.usbDevice)
                  }}
                />
              )}
              label={localStrings.usbDevice}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete: "new-password"
              }}
              error={Boolean(touched.localPrinterIp && errors.localPrinterIp)}
              fullWidth
              helperText={touched.localPrinterIp && errors.localPrinterIp}
              label={values.usbDevice ? localStrings.printerUsbPath : localStrings.localPrinterIp}
              name="localPrinterIp"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.localPrinterIp}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.barCodeUsbDeviceName && errors.barCodeUsbDeviceName)}
              fullWidth
              helperText={touched.barCodeUsbDeviceName && errors.barCodeUsbDeviceName}
              label={localStrings.barCodeUsbDeviceName}
              name="barCodeUsbDeviceName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.barCodeUsbDeviceName}
              variant="outlined"
            />
          </Grid>


          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.classicPrinterName && errors.classicPrinterName)}
              fullWidth
              helperText={touched.classicPrinterName && errors.classicPrinterName}
              label={localStrings.classicPrinterName}
              name="classicPrinterName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.classicPrinterName}
              variant="outlined"
            />
          </Grid>


          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.wsLogUrl && errors.wsLogUrl)}
              fullWidth
              helperText={touched.wsLogUrl && errors.wsLogUrl}
              label={localStrings.wsLogUrl}
              name="wsLogUrl"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.wsLogUrl}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.commandLogUrl && errors.commandLogUrl)}
              fullWidth
              helperText={touched.commandLogUrl && errors.commandLogUrl}
              label={localStrings.commandLogUrl}
              name="commandLogUrl"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.commandLogUrl}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.screenSize && errors.screenSize)}
              fullWidth
              helperText={touched.screenSize && errors.screenSize}
              label={localStrings.screenSize}
              name="screenSize"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.screenSize}
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <FormControl fullWidth>
              <InputLabel>{localStrings.screenRotation}</InputLabel>
              <Select
                fullWidth
                value={values.screenRotation}
                label={localStrings.screenRotation}
                onChange={(event, value) => {
                  setFieldValue("screenRotation", event.target.value);
                }}
              >
                <MenuItem value={0}>{localStrings.rotationNormal}</MenuItem>
                <MenuItem value={1}>{localStrings.rotationRight}</MenuItem>
                <MenuItem value={2}>{localStrings.rotationFlip}</MenuItem>
                <MenuItem value={3}>{localStrings.rotationLeft}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <FormControl variant="outlined" fullWidth
                         error={Boolean(touched.category && errors.category)}
            >
              <Autocomplete
                noOptionsText={localStrings.noProjectAssociated}
                value={values.project}
                id="tags-outlined"
                options={projectItems || []}
                getOptionLabel={(item) => item.name || ""}
                onChange={(event, value) => {
                  setFieldValue("project", value);
                }
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    style={{marginBottom: 10}}
                    {...params}
                    helperText={touched.project && errors.project}
                    error={Boolean(touched.project && errors.project)}
                    variant="outlined"
                    label={localStrings.project}
                    placeholder={localStrings.project}
                  />
                )}
              />
            </FormControl>

          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Box mt={3}>
              <CardHeader title={localStrings.driveUrls} />
              <Divider />
              <Card>
                <CardContent>
                  <TextField
                    style={{marginBottom: 10}}
                    inputProps={{
                      autocomplete:"new-password"
                    }}
                    error={Boolean(touched.changeChromeAddressUrl && errors.changeChromeAddressUrl)}
                    fullWidth
                    helperText={touched.changeChromeAddressUrl && errors.changeChromeAddressUrl}
                    label={localStrings.changeChromeAddressUrl}
                    name="changeChromeAddressUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={values.changeChromeAddressUrl}
                    variant="outlined"
                  />

                  <TextField
                    style={{marginBottom: 10}}
                    inputProps={{
                      autocomplete:"new-password"
                    }}
                    error={Boolean(touched.printTicketUrl && errors.printTicketUrl)}
                    fullWidth
                    helperText={touched.printTicketUrl && errors.printTicketUrl}
                    label={localStrings.printTicketUrl}
                    name="printTicketUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={values.printTicketUrl}
                    variant="outlined"
                  />

                  <TextField
                    style={{marginBottom: 10}}
                    inputProps={{
                      autocomplete:"new-password"
                    }}
                    error={Boolean(touched.rebootUrl && errors.rebootUrl)}
                    fullWidth
                    helperText={touched.rebootUrl && errors.rebootUrl}
                    label={localStrings.rebootUrl}
                    name="rebootUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={values.rebootUrl}
                    variant="outlined"
                  />

                  <TextField
                    style={{marginBottom: 10}}
                    inputProps={{
                      autocomplete:"new-password"
                    }}
                    error={Boolean(touched.speakUrl && errors.speakUrl)}
                    fullWidth
                    helperText={touched.speakUrl && errors.speakUrl}
                    label={localStrings.speakUrl}
                    name="speakUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={values.speakUrl}
                    variant="outlined"
                  />
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <Box mt={3}>
              <CardHeader title={localStrings.camera} />
              <Divider />
              <Card>
                <CardContent>
                  <TextField
                    style={{marginBottom: 10}}
                    inputProps={{
                      autocomplete:"new-password"
                    }}
                    error={Boolean(touched.cameraUrl && errors.cameraUrl)}
                    fullWidth
                    helperText={touched.cameraUrl && errors.cameraUrl}
                    label={localStrings.cameraUrl}
                    name="cameraUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={values.cameraUrl}
                    variant="outlined"
                  />
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>

      </>
    );
  }


  return (
    <>
      {/*<p>{JSON.stringify( getDevice(modeEdit) || {})}</p>*/}
      {projectItems ?
        <GeneralEditor getItemFunction={() => getDevice(modeEdit)}
                       className={className}
                       buildFormValues={buildFormValues}
                       getCardContentForm={getCardContentForm}
                       saveForm={(item, values) => saveFormDevice(item, values, modeEdit, navigate)}
                       applyButton={modeEdit == MODE_CREATE ? localStrings.create : localStrings.update}
                       resetFormProp={modeEdit == MODE_CREATE}
                       messageSuccess={modeEdit == MODE_CREATE ?
                         localStrings.notif.deviceAdded : localStrings.notif.deviceUpdated}
                       titleForm={modeEdit == MODE_CREATE ?
                         localStrings.addDevice : localStrings.editDevice}
                       getPathToData={(result) => {
                         return result.data.getDevice;
                       }}
        />
        :
        <ClipLoaderComponent/>
      }
    </>
  )
};

export default DeviceCreateUpdateForm;
