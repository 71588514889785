import React from 'react';

import useAppUtil from "../../hooks/useAppUtil";
import ModalPresenter from './ModalPresenter';
import {makeStyles} from "@mui/styles";
import {Navigate} from "react-router";
import {Alert, AlertTitle, Modal} from "@mui/material";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    minWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
  },
}));

export default function APPUtilNotification() {
  const { error, removeError, warning, removeWarning,
    errorRedirect, removeErrorAndRedirect, redirectPageUri, removeRedirectPageUri, presenter, showPresenter } = useAppUtil();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  if (errorRedirect) {
    return (<Navigate to="/error" />);
  }

  return (
    <React.Fragment>
      {
        redirectPageUri &&
        <Navigate to={redirectPageUri} />
      }
      <Modal
        open={!!error}
        onClose={removeError}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Alert severity="error" onClose={removeError}>
            {/*<AlertTitle>{localStrings.error}</AlertTitle>*/}
            <strong>{error ? error.message : ""}</strong>
          </Alert>
        </div>
      </Modal>

      <Modal
        open={!!warning}
        onClose={removeWarning}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Alert severity="warning" onClose={removeWarning} >
            {/*<AlertTitle>{localStrings.error}</AlertTitle>*/}
            <strong>{warning ? warning.message : ""}</strong>
          </Alert>
        </div>
      </Modal>

      <Modal
        open={!!presenter}
        onClose={() => showPresenter(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <ModalPresenter content={presenter}/>
        </div>
      </Modal>
    </React.Fragment>
  )
}
