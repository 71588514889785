import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, CardHeader} from "@mui/material";
import localStrings from "../../../localStrings";
import DropZoneFile from "../../../components/DropZoneFile";
import {useParams} from "react-router-dom";
import {ref, storage, uploadBytes, getDownloadURL} from "../../../lib/firebase";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import {enqueueSnackbar} from "notistack";
import useAppUtil from "../../../hooks/useAppUtil";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {getProjectQuery, updateProjectMutation} from "../../../gql/projectGql";
import useAuth from "../../../hooks/useAuth";
import cloneDeep from "clone-deep";

function UploadLocalSite() {
  let {projectId} = useParams();
  const {currentBrand} = useAuth();
  const [uploading, setUploading] = useState(false);
  const [project, setProject] = useState(false);
  const { addError } = useAppUtil();
  function download() {
    alert("download")
  }

  useEffect(() => {
    const loadProject = async () => {
      const projectRes = await executeQueryUtil(getProjectQuery(currentBrand().id, projectId));
      const projectData = projectRes?.data?.getProject;
      setProject(projectData)
    }
    loadProject();
  }, [])

  async function upload(file) {
    setUploading(true);
    console.log(file);
    let pathRemote = `projects/${projectId}/site/siteContent.zip`;
    const zipRef = ref(storage, pathRemote);

    await uploadBytes(zipRef, file)
    const url = await getDownloadURL(ref(storage, pathRemote))


    if (project) {
      const projectClone = cloneDeep(project);
      projectClone.webSiteZipUrl = url;
      let resUpdate = await executeMutationUtil(updateProjectMutation(currentBrand().id, projectClone));
      setProject(resUpdate?.data?.updateProject);
    }

    enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.siteUploaded);
    setUploading(false);

    // uploadTask.on('state_changed',
    //   (snapshot) => {
    //     // // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    //     // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     // console.log('Upload is ' + progress + '% done');
    //     // switch (snapshot.state) {
    //     //   case 'paused':
    //     //     console.log('Upload is paused');
    //     //     break;
    //     //   case 'running':
    //     //     console.log('Upload is running');
    //     //     break;
    //     // }
    //   },
    //   (error) => {
    //     alert(JSON.stringify(error));
    //   },
    //   () => {
    //     // Upload completed successfully, now we can get the download URL
    //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //       console.log('File available at', downloadURL);
    //     });
    //   }
    // );
  }

  return (
    <div style={{ width: '100%' }}>
      <Card>
        <CardHeader title={localStrings.uploadDeviceWebSite}/>
        <CardContent>
          {uploading ?
            <ClipLoaderComponent/>
            :
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  p: 1,
                  m: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <DropZoneFile uploadCallBack={upload}
                              downloadLink={project?.webSiteZipUrl} />
              </Box>
            </>
          }

        </CardContent>
      </Card>
    </div>
  );
}

export default UploadLocalSite;
