import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, Card, CardContent, CardHeader, Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip
} from "@mui/material";
import localStrings from "../../../localStrings";
import DialogConfirm from "../../../components/DialogConfirm";
import {useParams} from "react-router-dom";
import {executeQueryUtil} from "../../../utils/gqlUtil";
import {getDeviceQuery} from "../../../gql/DeviceGql";
import axios from "axios";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import {getProjectQuery} from "../../../gql/projectGql";
import useAuth from "../../../hooks/useAuth";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import {useSnackbar} from "notistack";
import SendToMobileIcon from '@mui/icons-material/Send';

const config = require('../../../conf/config.json')


function SendCommands({}) {
  let {deviceId} = useParams();
  const [confirmRebootOpen, setConfirmRebootOpen] = useState(false);
  const [device, setDevice] = useState(null);
  const [project, setProject] = useState(null);
  const {currentBrand} = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = React.useState({
    qrCode: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const res =  await executeQueryUtil(getDeviceQuery(deviceId));
      setDevice(res.data?.getDevice);
      const resProject =  await executeQueryUtil(getProjectQuery(currentBrand().id, res.data?.getDevice.projectId));
      setProject(resProject.data?.getProject);
    }

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])


  async function changeUrl() {

    const url = config.baseEventUrl + "/simulateQrCode/" + device.id;
    try {
      await axios.post(url, {
        qrCode: values.qrCode
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project.authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.QrCodeScanSent);
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {(device && project) ?
        <Card>

          <CardHeader title={localStrings.simulateEventsOnDevice}/>

          <Divider />

          <CardContent>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <FormControl sx={{ flexGrow: 1 }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{localStrings.qrCoreValue}</InputLabel>
                <OutlinedInput
                  value={values.qrCode}
                  onChange={handleChange('qrCode')}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip title={localStrings.simulateQrCodeScan}>
                      <IconButton
                        onClick={changeUrl}
                        edge="end"
                      >
                        <SendToMobileIcon/>
                      </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Box>

          </CardContent>
        </Card>
        :
        <ClipLoaderComponent/>
      }
    </div>
  );
}

export default SendCommands;
