import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { ApolloProvider } from '@apollo/client';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import apolloClient from './apolloClient/ApolloClient';
import {AuthProvider} from "./contexts/FirebaseAuthContext";
import {SnackbarProvider} from "notistack";
import APIErrorProvider from "./contexts/APPUtilProvider";
import APPUtilNotification from "./contexts/APPUtilProvider/APPUtilNotification";

const App = () => {
  const content = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          <APIErrorProvider>
            <ApolloProvider client={apolloClient}>
              <AuthProvider>
                <GlobalStyles />
                {content}
                <APPUtilNotification/>
              </AuthProvider>
            </ApolloProvider>
          </APIErrorProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
