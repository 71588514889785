import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
} from "@mui/material";
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useAppUtil from '../../hooks/useAppUtil';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useSound from 'use-sound';
import useSettings from '../../hooks/useSettings';
import enqueueSnackbarWithSound from '../../utils/SnackBarWithSound';
import ClipLoaderComponent from '../ClipLoaderComponent';
import {makeStyles} from "@mui/styles";
import { useSnackbar } from 'notistack';

const config = require("../../conf/config.json");

const useStyles = makeStyles(() => ({
  root: {}
}));

export const MODE_EDIT = 1;
export const MODE_CREATE = 2;

const GeneralEditor = ({ getItemFunction,
                         itemSource,
                         externalLoading,
                         className,
                         buildFormValues,
                         getCardContentForm,
                         saveForm,
                         messageSuccess,
                         applyButton,
                         titleForm,
                         getPathToData,
                         children,
                         resetFormProp,
                         componentTitle,
                         noCard,
                         displaySaveButton,
                         hideApplyButton,
                       }) => {
  const classes = useStyles();
  const [item, setItem] = useState({});
  const { playSoundIfActive } = useSettings()
  const [dataLoaded, setDataLoaded] = useState(false);

  const [playDone] = useSound(
    config.sound.done,
    { volume: 0.5 }
  );
  const isMountedRef = useIsMountedRef();
  const getItem = useCallback(async () => {
    try {
      var result = itemSource ? itemSource : await getItemFunction();
      if (isMountedRef.current && result) {
        let pathToData = getPathToData(result);
        if (pathToData) {
          setItem(pathToData);
        }
        else
        {
          setItem({});
        }

        setDataLoaded(true);
      }
      if (result == null) {
        setDataLoaded(true);
      }
    } catch (err) {
      console.log(err);
      addError(err.message);
      setItem({});
    }
  }, [isMountedRef, itemSource]);
  useEffect(() => {
    getItem();
  }, []);

  const { addError } = useAppUtil();
  const { enqueueSnackbar } = useSnackbar();
  const formValues = buildFormValues(item);

  return (
    <div>

      {
        !dataLoaded || externalLoading ?
          <ClipLoaderComponent/>
          :

          <Formik
            enableReinitialize
            initialValues={formValues.initialValues}
            validationSchema={Yup.object().shape(
              formValues.validationSchema,
            )}

            onSubmit={async (values, {
              resetForm,
              setErrors,
              setStatus,
              setSubmitting,
            }) => {
              try {
                setSubmitting(true);
                setDataLoaded(false);

                let dataUpdated = await saveForm(item, values);


                //if (!doNotUpdateDataAfterSave) {
                setItem(dataUpdated || {});
                // }
                // else {
                //   setItem(itemSource)
                // }
                setDataLoaded(true);
                //if (mode == )

                setStatus({success: true});
                setSubmitting(false);
                if (messageSuccess) {
                  enqueueSnackbarWithSound(enqueueSnackbar, playDone, playSoundIfActive, messageSuccess);
                }
                if (resetFormProp) {
                  resetForm();
                }
              } catch (err) {
                setDataLoaded(true);
                console.error(err);
                setStatus({success: false});
                setErrors({submit: err.message});
                setSubmitting(false);
                console.log(err);
                addError(err.message)
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values
              }) => (
              // <form onSubmit={handleSubmit}>
              <form onSubmit={handleSubmit}>
                {!noCard ?
                  <Card
                    //className={clsx(classes.root)}
                  >
                    <Grid
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        {titleForm && <CardHeader title={titleForm} />}
                      </Grid>

                      {componentTitle &&
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        {componentTitle()}

                      </Grid>
                      }
                    </Grid>

                    <Divider />
                    {item &&
                    <CardContent>
                      {getCardContentForm({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        touched,
                        values
                      })}
                      {errors.submit && (
                        <Box mt={3}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                    </CardContent>
                    }
                    <Divider />
                    <Box
                      p={2}
                      display="flex"
                      justifyContent="flex-end"
                      alignContent="space-around"
                    >
                      {children}
                      {!hideApplyButton &&
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        style={{ marginLeft: "2px" }}
                      >
                        {applyButton}
                      </Button>
                        }
                    </Box>
                  </Card>
                  :
                  <>
                    {getCardContentForm({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values
                    })}
                    {displaySaveButton &&
                    <Box
                      p={2}
                      display="flex"
                      justifyContent="flex-end"
                      alignContent="space-around"
                    >
                      {children}
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        style={{ marginLeft: "2px" }}
                      >
                        {applyButton}
                      </Button>
                    </Box>
                    }

                  </>


                }
              </form>
            )}
          </Formik>
      }
    </div>
  );
}

export default GeneralEditor;
