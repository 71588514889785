import React, {useEffect, useState} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider, FormControl,
  FormControlLabel,
  Grid, InputLabel,
  Link, MenuItem, Select, Switch,
  TextField
} from "@mui/material";
import localStrings from "../../../localStrings";
import {executeQueryUtil} from "../../../utils/gqlUtil";
import {getAllDevicesByBrandIdQuery, getAllDevicesQuery, getDeviceQuery} from "../../../gql/DeviceGql";
import {useParams} from "react-router-dom";
import {getProject, saveFormProject} from "../../Projects/ProjectAddView/ProjectCreateUpdateForm";
import GeneralEditor, {MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import * as Yup from "yup";
import {
  DEFAULT_VOICE,
  GO_TO_HOME_PAGE_ACTION,
  GO_TO_PREVIOUS_PAGE_ACTION, TRIGGER_WEB_HOOK_ACTION
} from "../../Projects/ProjectDetailsView/Config";
import voiceList from "../../Projects/ProjectDetailsView/voices.json";
import TextFieldPassword from "../../../components/TextFieldPassword";
import {useNavigate} from "react-router";
import {saveFormDevice} from "../DeviceAddView/DeviceCreateUpdateForm";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import {getAllStripePayAccountsQuery, getStripePayAccountQuery} from "../../../gql/stripePayAccountGql";
import useAuth from "../../../hooks/useAuth";
import config from "../../../conf/config.json";
import axios from "axios";

const NO_VALUE_ID = "-1";

function PaymentTerminal({}) {
  let {deviceId} = useParams();
  const [device, setDevice] = useState(null);
  const [stripeAccounts, setStripeAccounts] = useState([]);
  const [selectedStripeAccountId, setSelectedStripeAccountId] = useState(null);
  const [stripeLocations, setStripeLocations] = useState(null);
  const [allDevicesTerminalId, setAllDevicesTerminalId] = useState(null);
  const [stripeTerminals, setStripeTerminals] = useState(null);
  const [selectedStripeLocationId, setSelectedStripeLocationId] = useState(null);
  const [selectedStripeTerminalId, setSelectedStripeTerminalId] = useState(null);
  const navigate = useNavigate();
  const {currentBrand} = useAuth();

  const addNoValue = (initialList) => {
    return [...initialList, {id: NO_VALUE_ID}]
  }

  useEffect(() => {
    const fetchData = async () => {
      const res =  await executeQueryUtil(getDeviceQuery(deviceId));
      setDevice(res.data?.getDevice);
      setSelectedStripeAccountId(res.data?.getDevice?.stripeAccountId);
      setSelectedStripeLocationId(res.data?.getDevice?.stripeLocationId);

      const resAccount = await executeQueryUtil(getAllStripePayAccountsQuery(currentBrand().id));
      setStripeAccounts(addNoValue(resAccount.data?.getStripePayAccounts))
      const resDevices = await executeQueryUtil(getAllDevicesByBrandIdQuery(currentBrand().id))
      //setAllDevicesTerminalId([])
      setAllDevicesTerminalId(resDevices.data?.getDevicesByBrandId
        .filter(d => d.id != deviceId && d.stripeTerminalId != NO_VALUE_ID && d.stripeTerminalId)
        .map(d => d.stripeTerminalId))
    }
    fetchData()
      .catch(console.error);
  }, [])

  const loadStripeLocations = async (stripeAccountId) => {
    if (!stripeAccountId || stripeAccountId == NO_VALUE_ID) {
      return;
    }
    const url = config.paymentServiceUrl + "/terminal/getStripeLocations/" + currentBrand().id + "/" + stripeAccountId;
    try {
      const res = await axios.get(url,{
        headers: {
          'Content-Type': 'application/json',
        }
      })
      setStripeLocations({...res.data, data:addNoValue(res.data?.data || [])});
    }
    catch (error) {
      console.log(error);
    }
  }

  const loadStripeReaders = async (stripeAccountId, stripeLocationId) => {
    if (!stripeAccountId || !stripeLocationId || stripeAccountId == NO_VALUE_ID) {
      return;
    }
    const url = config.paymentServiceUrl + "/terminal/getStripeTerminals/" + currentBrand().id +
      "/" + stripeAccountId + "/" + stripeLocationId;
    try {
      const res = await axios.get(url,{
        headers: {
          'Content-Type': 'application/json',
        }
      })
      setStripeTerminals({...res.data, data:addNoValue(res.data?.data || [])});
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (!selectedStripeAccountId || selectedStripeAccountId === NO_VALUE_ID) {
      setStripeLocations([]);
      return;
    }
    loadStripeLocations(selectedStripeAccountId)
  }, [selectedStripeAccountId])

  useEffect(() => {
    if (!selectedStripeAccountId || !selectedStripeLocationId ||
      selectedStripeLocationId === NO_VALUE_ID || selectedStripeAccountId === NO_VALUE_ID) {
      setStripeTerminals([]);
      return;
    }
    loadStripeReaders(selectedStripeAccountId, selectedStripeLocationId)
  }, [ selectedStripeLocationId, selectedStripeAccountId])

  const buildFormValues = (item) => {
    return {
      initialValues: {
        stripeTerminalId: item.stripeTerminalId || NO_VALUE_ID,
        stripeLocationId: item.stripeLocationId || NO_VALUE_ID,
        stripeAccountId: item.stripeAccountId || NO_VALUE_ID,
      },

      validationSchema: {
        stripeTerminalId: Yup.string().max(255).required(localStrings.check.fieldRequired),
        stripeLocationId: Yup.string().max(255).required(localStrings.check.fieldRequired),
        stripeAccountId: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  function getTerminalsIds() {
    if (!allDevicesTerminalId) {
      return [];
    }
    return (stripeTerminals?.data || [])
      .filter(t => !allDevicesTerminalId.includes(t.id))
      .map(t => t.id) || [];
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {

    return (
      <Grid
        container
        spacing={4}
      >
        {/*<p>{JSON.stringify(allDevicesTerminalId || [])}</p>*/}
        {/*<p>{JSON.stringify(stripeLocations || [])}</p>*/}
        {/*<br/>*/}
        {/*<p>{JSON.stringify(stripeTerminals || [])}</p>*/}
        <Grid
          item
          md={12}
          xs={12}
        >

          <FormControl variant="outlined" fullWidth
                       error={Boolean(touched.category && errors.category)}
          >
            <Autocomplete
              noOptionsText={localStrings.noStripePayAccountAssociated}
              value={values.stripeAccountId}
              id="tags-outlined"
              options={(stripeAccounts || []).map(a => a.id) || []}
              getOptionLabel={(item) =>
              {
                if (item == NO_VALUE_ID) {
                  return localStrings.noValue;
                  setFieldValue("stripeLocationId", NO_VALUE_ID);
                  setFieldValue("stripeTerminalId", NO_VALUE_ID);
                }
                return stripeAccounts.find(a => a.id == item)?.name || ""
              }}
              onChange={(event, value) => {
                setFieldValue("stripeAccountId", value);
                setSelectedStripeAccountId(value);
              }
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  style={{marginBottom: 10}}
                  {...params}
                  helperText={touched.stripeAccountId && errors.stripeAccountId}
                  error={Boolean(touched.stripeAccountId && errors.stripeAccountId)}
                  variant="outlined"
                  label={localStrings.stripePayAccount}
                  placeholder={localStrings.stripePayAccount}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/*{stripeLocations && stripeLocations.data.length > 0 &&*/}
          < Grid
            item
            md={12}
            xs={12}
          >

            <FormControl variant="outlined" fullWidth
                         error={Boolean(touched.category && errors.category)}
            >
              <Autocomplete
                noOptionsText={localStrings.noStripeLocation}
                value={values.stripeLocationId}
                id="tags-outlined"
                options={(stripeLocations?.data || []).map(a => a.id) || []}
                //getOptionLabel={(item) => stripeLocations?.data.find(a => a.id == item)?.address?.display_name || ""}
                getOptionLabel={(item) => {
                  if (item == NO_VALUE_ID) {
                    return localStrings.noValue;
                  }
                  return (stripeLocations?.data || []).find(s => s.id == item)?.display_name || ""
                }}
                onChange={(event, value) => {
                  setFieldValue("stripeLocationId", value);
                  setSelectedStripeLocationId(value);
                }
                }
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    style={{marginBottom: 10}}
                    {...params}
                    helperText={touched.stripeLocationId && errors.stripeLocationId}
                    error={Boolean(touched.stripeLocationId && errors.stripeLocationId)}
                    variant="outlined"
                    label={localStrings.stripeLocationId}
                    placeholder={localStrings.stripeLocationId}
                  />
                )}
              />
            </FormControl>
          </Grid>
        {/*}*/}
        {/*<p>{values.stripeTerminalId}</p>*/}
        <Grid
          item
          md={12}
          xs={12}
        >
          <FormControl variant="outlined" fullWidth
                       error={Boolean(touched.category && errors.category)}
          >
            <Autocomplete
              noOptionsText={localStrings.noStripeTerminal}
              value={values.stripeTerminalId}
              id="tags-outlined"
              options={getTerminalsIds()}
              //getOptionLabel={(item) => stripeLocations?.data.find(a => a.id == item)?.address?.display_name || ""}
              getOptionLabel={(item) => {
                if (item == NO_VALUE_ID) {
                  return localStrings.noValue;
                }
                return (stripeTerminals?.data || []).find(s => s.id == item)?.label || ""
              }}
              onChange={(event, value) => {
                setFieldValue("stripeTerminalId", value);
                setSelectedStripeTerminalId(value);
              }
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  style={{marginBottom: 10}}
                  {...params}
                  helperText={touched.stripeTerminalId && errors.stripeTerminalId}
                  error={Boolean(touched.stripeTerminalId && errors.stripeTerminalId)}
                  variant="outlined"
                  label={localStrings.stripeTerminalId}
                  placeholder={localStrings.stripeTerminalId}
                />
              )}
            />
          </FormControl>
        </Grid>

      </Grid>
    );
  }

  return (
    <>
      {
        device ?
          <GeneralEditor getItemFunction={() => device}
                         buildFormValues={buildFormValues}
                         getCardContentForm={getCardContentForm}
                         saveForm={(item, values) => saveFormDevice(device, values, MODE_EDIT, navigate)}
                         applyButton={localStrings.update}
                         messageSuccess={localStrings.notif.projectUpdated}
                         titleForm={localStrings.config}
                         getPathToData={(result) => result}

          />
          :
          <ClipLoaderComponent/>
      }
    </>
  );
}

export default PaymentTerminal;
