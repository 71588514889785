import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, CardHeader, Divider, TextField} from "@mui/material";
import * as Yup from "yup";
import config from "../../../conf/config.json";
import axios from "axios";
import {useNavigate} from "react-router";
import useAuth from "../../../hooks/useAuth";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import localStrings from "../../../localStrings";

function SelectAmountToPay() {

  const [errorAmount, setErrorAmount] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [amount, setAmount] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [priceCredit, setPriceCredit] = useState(false);
  const navigate = useNavigate();
  const {currentBrand} = useAuth();

  const validationSchema = Yup.object({
    amount: Yup.number(localStrings.check.amountFormat)
      .min(50, localStrings.check.minAmount)
      .test(
        "is-decimal",
        localStrings.check.amountFormat,

        (val) => {
          if (val) {
            return parseFloat(val) % 1 === 0;
          }
          return false;
        }
      )
      .required(localStrings.check.required)
  })

  function getBaseUrl() {
    //alert(window.location.href + "/app/brand")
    return window.location.protocol + '//' + window.location.host;
  }

  async function getPriceCredit() {
    const url = config.paymentServiceUrl + "/stripe/priceCredit";
    try {
      const res = await axios.get(url,{
        headers: {
          'Content-Type': 'application/json',
        }
      })
      setPriceCredit(res?.data?.priceCredit)
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPriceCredit();
  }, [])


  async function callPayment() {
    setLoading(true)
    const url = config.paymentServiceUrl + "/stripe/checkout/" + currentBrand().id;
    try {
      const res = await axios.post(url, {
        quantity: amount,
        baseUrl: getBaseUrl()
      },{
        headers: {
          'Content-Type': 'application/json',
        }
      })

      const paymentUrl = res?.data?.paymentUrl;
      if (paymentUrl) {
        window.location.assign(paymentUrl);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Card>

        <CardHeader title={localStrings.formatString(localStrings.chargeMyAccountWithPrice, priceCredit || "...")}/>
        <Divider/>
        <CardContent>
          {loading ?
            <ClipLoaderComponent/>
            :
            <>
              <Box mb={2}>
                <TextField
                  error={Boolean(errorAmount)}
                  fullWidth
                  label={localStrings.amountCredit}
                  helperText={errorText}
                  onChange={(e) => {
                    setAmount(e.target.value)
                    validationSchema.validate({amount: e.target.value})
                      .then(function(value) {
                        setErrorText(null)
                        setErrorAmount(false)
                        // setAmount(value.amount);
                      })
                      .catch(function(err) {
                        setErrorAmount(true)
                        setErrorText(err.message)
                        //setAmount(value.amount);
                      });
                  }}
                  value={amount}
                  variant="outlined"
                />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={errorAmount}
                  onClick={callPayment}>
                  {localStrings.pay}
                </Button>
              </Box>
            </>
          }

        </CardContent>
      </Card>
    </div>
  );
}

export default SelectAmountToPay;
