import React, { useCallback, useState } from 'react';

export const APPUtilContext = React.createContext({
  error: null,
  warning: null,
  errorRedirect: null,
  redirectPageUri: null,
  blobs: [],
  files: [],
  setBlobs: () => {},
  setFiles: () => {},
  addError: () => {},
  showPresenter: () => {},
  addWarning: () => {},
  removeError: () => {},
  removeWarning: () => {},
  addErrorAndRedirect: () => {},
  removeErrorAndRedirect: () => {},
  redirectPage: () => {},
  removeRedirectPageUri: () => {},
});

export default function APIErrorProvider({ children }) {
  const [error, setError] = useState(null);
  const [presenter, setPresenter] = useState(null);
  const [warning, setWarning] = useState(null);
  const [errorRedirect, setErrorRedirect] = useState(null);
  const [redirectPageUri, setRedirectPageUri] = useState(null);
  const [blobs, setBlobs] = useState([]);
  const [files, setFiles] = useState([]);

  const removeError = () => setError(null);
  const removeWarning = () => setWarning(null);
  const removeErrorAndRedirect = () => setErrorRedirect(null);
  const removeRedirectPageUri = () => {
    setRedirectPageUri(null);
  }

  const addError = (message, status) => {
    setError({ message, status });
  }

  const showPresenter = (content) => {
    setPresenter(content);
  }
  //showPresenter

  const addWarning = (message, status) => {
    setWarning({ message, status });
  }

  const addErrorAndRedirect = (message) => {
    setErrorRedirect({ message });
  }

  const redirectPage = (pageUri) => {
    setRedirectPageUri(pageUri);
  }

  const contextValue = {
    error,
    presenter,
    warning,
    errorRedirect,
    redirectPageUri,
    blobs,
    files,
    setBlobs: useCallback((blobs) => setBlobs(blobs), []),
    setFiles: useCallback((files) => setFiles(files), []),
    addError: useCallback((message, status) => addError(message, status), []),
    showPresenter: useCallback((content) => showPresenter(content), []),

    addErrorAndRedirect: useCallback((message) => addErrorAndRedirect(message), []),
    redirectPage: useCallback((pageUri) => redirectPage(pageUri), []),
    removeRedirectPageUri: useCallback(() => removeRedirectPageUri(), []),
    removeError: useCallback(() => removeError(), []),
    removeErrorAndRedirect: useCallback(() => removeErrorAndRedirect(), []),
    addWarning: useCallback((message, status) => addWarning(message, status), []),
    removeWarning: useCallback(() => removeWarning(), [])
  };

  return (
    <APPUtilContext.Provider value={contextValue}>
      {children}
    </APPUtilContext.Provider>
  );
}
