import { cloneDeep } from '@apollo/client/utilities';

export async function applyReorder(itemsClone, initialList, updateFunc) {
  applyChainInList(itemsClone);

  for (let i = 0; i < itemsClone.length; i++) {
    const itemToUpdate = itemsClone[i];
    //find original
    let initialItem = initialList.find(item => item.id === itemToUpdate.id);
    if (initialItem?.chainedListItem?.previousId !== itemToUpdate.chainedListItem?.previousId ||
      initialItem?.chainedListItem?.nextId !== itemToUpdate.chainedListItem?.nextId
    ) {
      await updateFunc(itemToUpdate);
    }
  }
}

export function applyChainInList(items, clone) {
  for (let i = 0; i < items.length; i++) {
    const previous = i > 0 && items[i - 1];
    const itemWithoutChain = clone ? cloneDeep(items[i]) : items[i];
    const next = i < items.length - 1 && items[i + 1];
    //updateDone = true;
    itemWithoutChain.chainedListItem = {};
    itemWithoutChain.chainedListItem.previousId = previous?.id || "";
    itemWithoutChain.chainedListItem.nextId = next?.id || "";
  }
}

export async function checkUpdateChainList(itemSource, updateFunction) {

  let itemsWithoutChain = itemSource.filter(item => !item.chainedListItem ||
    (!item.chainedListItem.previousId && !item.chainedListItem.nextId));

  //alert("itemsWithoutChain.length " + itemsWithoutChain.length)
  if (itemsWithoutChain.length != itemSource.length) {
    return false;
  }

  let cloneItemsSource = cloneDeep(itemSource);
  applyChainInList(cloneItemsSource);

  for (let i = 0; i < cloneItemsSource.length; i++) {
    const itemsWithoutChainElement = cloneItemsSource[i];
    updateFunction(itemsWithoutChainElement);
  }
  return true;
}

export function sortChainList(itemSource) {
  if (!itemSource || itemSource.length === 0) {
    return [];
  }
  let sorted = [];
  let firstItem = itemSource.find(item => item.chainedListItem &&
    (!item.chainedListItem.previousId || item.chainedListItem.previousId=="") && item.chainedListItem.nextId);
  if (!firstItem) {
    return itemSource;
  }
  sorted.push(firstItem);


  var current = firstItem;
  let counter = 0;
  while ((current?.chainedListItem?.nextId || (current?.chainedListItem?.nextId && current?.chainedListItem?.nextId != "")) && counter <= itemSource.length) {
    const nextId = current.chainedListItem?.nextId;
    current = itemSource.find(item => item.id === nextId);
    if (current) {
      sorted.push(current);
    }
    counter++
  }
  if (counter > itemSource.length) {
    return itemSource;
  }

  return sorted;
}

export async function insertAtEnd(items, newItem, updateFunc) {
  const lastItem = items.find(item => item.chainedListItem && item.chainedListItem.nextId === "");

  if (lastItem) {
    console.log("lastItem.id " + lastItem.id);
    console.log("newItem " + newItem.id);

    newItem.chainedListItem = {
      previousId: lastItem.id,
      nextId: "",
    }

    lastItem.chainedListItem = {
      ...lastItem.chainedListItem,
      nextId: newItem.id
    }

    await updateFunc(lastItem);
    await updateFunc(newItem);
  }

}


export function insertAtEndPromise(items, newItem, updateFunc) {

  const promise = new Promise((resolve, reject) => {
    const lastItem = items.find(item => item.chainedListItem && item.chainedListItem.nextId === "");
    //if (lastItem) {
    //   console.log("lastItem.id " + lastItem.id);
    //   console.log("newItem " + newItem.id);

      newItem.chainedListItem = {
        previousId: lastItem?.id || "",
        nextId: "",
      }

      if (lastItem) {
        lastItem.chainedListItem = {
          ...lastItem.chainedListItem,
          nextId: newItem.id
        }
        updateFunc(lastItem).then(data => {
          updateFunc(newItem).then(res => {
            resolve(res)
          });
        })
      }

      else {
        updateFunc(newItem).then(res => {
          resolve(res)
        });
      }


    //}
    //else (resolve(null))
  });


  return promise

}
