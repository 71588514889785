import React, { useEffect, useState } from 'react';
import { TableCell } from "@mui/material";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";
import useAppUtil from "../../../hooks/useAppUtil";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {getAllProjectsQuery, bulkDeleteProjectMutation, deleteProjectCache} from "../../../gql/projectGql"
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";
import {bulkDeleteBoardMutation, deleteBoardCache, getAllBoardsQuery} from "../../../gql/boardGql";

const Results = ({className, ...rest}) => {
  const { currentBrand } = useAuth();
  const { addWarning, addError} = useAppUtil();
  const [itemSource, setItemSource] = useState({});

  useEffect(() => {
    async function setItemSourceAsync() {
      let result = await getBoards();
      setItemSource(result);
    }
    setItemSourceAsync();
  }, [currentBrand]);


  const getBoards = async () => {
    return await executeQueryUtil(getAllBoardsQuery(currentBrand().id));
  }

  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.boardName}
        </TableCell>
      </React.Fragment>
    )
  }

  const getTableCell = (item) => {
    return(
      <React.Fragment>
        <TableCell>
          {item.name}
        </TableCell>
      </React.Fragment>
    )
  }

  const deleteBoards = async (ids, size) => {
    await executeMutationUtil(bulkDeleteBoardMutation(currentBrand().id, ids));
    await deleteBoardCache(currentBrand().id, ids);
    let itemUpdate = await getBoards();
    setItemSource(itemUpdate)
    return true;
  }


  const sortOptions = [
    {
      value: 'tag|asc',
      label: localStrings.projectName
    },
  ];

  return (
    <>
      { Object.keys(itemSource).length > 0 ?
        <GeneralResult
          className={className}
          getPathToData={(result) => result.data.getBoards}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          getEditLink={(item) => "/app/boards/detail/" + item.id}
          deleteDialogText={localStrings.confirmMessage.deleteBoard}
          deleteItemsFunction={deleteBoards}
          deleteItemNotifMessage={localStrings.notif.boardDeleted}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >
        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default Results;
