import React from 'react';
import {Box, Button, Step, StepLabel, Stepper, Typography} from "@mui/material";
import PaymentForm from "./PaymentForm";
import WrapperPayment from "./WrapperPayment";
import localStrings from "../../localStrings";
import SelectAmount from "./SelectAmount";


function StepperPayment() {

  const [activeStep, setActiveStep] = React.useState(0);
  const [amount, setAmount] = React.useState(10);
  return (
    <Box sx={{ width: '100%'}}>
      <Box mb={2}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{localStrings.selectAmount}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{localStrings.formatString(localStrings.payAmount, amount)}</StepLabel>
          </Step>
        </Stepper>
      </Box>

      {activeStep == 1 &&
        <WrapperPayment amount={amount * 100} backCallBack={() => setActiveStep(0)}/>
      }
      {activeStep == 0 &&
        <SelectAmount
          amount={amount}
          setAmount={setAmount}
          nextCallBack={() =>
          setActiveStep(1)
        }/>
      }

    </Box>
  );
}

export default StepperPayment;
