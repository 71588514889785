import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Alert, Box, Button, Container, FormHelperText, Link, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import localStrings from "../localStrings";
import useAuth from "../hooks/useAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useFormik} from "formik";
import * as Yup from "yup";
import {passwordRegEx} from "./Register";
import {LoadingButton} from "@mui/lab";
import {loginRoute} from "../routes";
import {confirmPasswordReset} from "firebase/auth";


const AuthChange = () => {
  const query = new URLSearchParams(window.location.search);
  const mode = query.get('mode');
  const actionCode  = query.get('oobCode');
  const lang =  query.get('lang') || 'en';
  const [emaiVerified, setEmaiVerified] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [resetting, setResetting] = useState(false);

  async function resetPassword(values) {
    try {
      setResetting(true)
      await verifyPasswordResetCodeContext(actionCode);
      await confirmPasswordResetContext(actionCode, values.password);
      setPasswordChanged(true);
      //await fillUserContext(user, setAuthenticated, setEmailVerified, setContextDbUser)
    }
    catch (err) {
      if (err.code == "auth/invalid-action-code") {
        setErrorDisplay(localStrings.warningMessage.invalidActionCode)
      }
      else {
        setErrorDisplay(err.message)
      }
      console.log(err)
    }
    finally {
      setResetting(false);
    }

  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().matches(passwordRegEx, localStrings.check.passwordNotValid)
        .required(localStrings.check.required),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], localStrings.check.passwordsMustMatch),
    }),
    onSubmit: resetPassword
  });

  const { applyActionCodeContext, verifyPasswordResetCodeContext, confirmPasswordResetContext} = useAuth();
  const navigate = useNavigate();
  function handleVerifyEmail(actionCode) {
    try {
      if (emaiVerified) {
        return;
      }
      applyActionCodeContext(actionCode).then(() => {
        setEmaiVerified(true);
      })
      .catch((err) => {
        if (err.code == "auth/invalid-action-code") {
          setErrorDisplay(localStrings.warningMessage.invalidActionCode)
        }
        else {
          setErrorDisplay(err.message)
        }

        console.log(err)
      });
    }
    catch (err) {

    }
  }


  // function changePassword(newPassword) {
  //   try {
  //     verifyPasswordResetCodeContext(actionCode).then((email) => {
  //       setEmaiVerified(true);
  //     })
  //     .catch((err) => {
  //       setErrorDisplay(err.message)
  //       console.log(err)
  //     });
  //   }
  //   catch (err) {
  //
  //   }
  // }

  if (mode == 'verifyEmail') {
    handleVerifyEmail(actionCode);
  }

  // useEffect(() => {
  //     if (isAuthenticated && emailVerified) {
  //       if (currentBrand()) {
  //         navigate('/app/dashboard', { replace: true });
  //       }
  //       else {
  //         navigate('/app/account', { replace: true });
  //       }
  //     }
  //   },
  //   [isAuthenticated, emailVerified]);



  return (
    <>
      <Helmet>
        <title>{localStrings.authChange}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}
      >
        <Container maxWidth="sm">

          {passwordChanged &&
            <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
              <Alert severity="success">{localStrings.passwordChanged}</Alert>
            </Stack>
          }
          {errorDisplay &&
            <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
              <Alert severity="error">{errorDisplay}</Alert>
            </Stack>
          }

          {emaiVerified && !passwordChanged &&
          <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
            <Alert severity="success">{localStrings.validationEmailDone}</Alert>
          </Stack>
          }

          {mode == 'resetPassword' && !passwordChanged &&
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{my: 3}}>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  {localStrings.resetPassword}
                </Typography>
              </Box>


              <TextField
                error={Boolean(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label={localStrings.newPassword}
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation)}
                fullWidth
                helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                label={localStrings.newPasswordConfirmation}
                margin="normal"
                name="passwordConfirmation"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.passwordConfirmation}
                variant="outlined"
              />

              <Box sx={{py: 2}}>
                <LoadingButton
                  color="primary"
                  //disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={resetting}
                  loadingPosition="end"
                >
                  {localStrings.resetPassword}
                </LoadingButton>
              </Box>
            </form>
          }

          <Link
            href="/login"
            passHref
          >
            <Button
              component="a"
              startIcon={<ArrowBackIcon fontSize="small" />}
            >
              {localStrings.backToLogin}
            </Button>
          </Link>

        </Container>
      </Box>

    </>
  );
};

export default AuthChange;
