import React, {useEffect, useState} from 'react';
import useAuth from "../../../hooks/useAuth";
import * as Yup from "yup";
import * as yup from "yup";
import localStrings from "../../../localStrings";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {
  addDataTempBrandUserToCache,
  bulkDeleteTempBrandUserMutation,
  createTempBrandUserMutation,
  deleteTempBrandUserCache
} from "../../../gql/tempBrandUserGql";
import {
  getProductUserQuery,
  getProductUsersByBrandIdQuery,
  updateProductUserMutation
} from "../../../gql/productUserGql";
import {Box, Grid, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {uuid} from "uuidv4";
import GeneralResultWithEdition from "../../../components/CrudFrom/GeneralResultWithEdition";
import {roles} from "./brandUsers";
import cloneDeep from "clone-deep";

function ExistingUserTable(props) {
  const {currentBrand, userInDb} = useAuth();
  const [existingUsers, setExistingUsers] = useState(null)

  async function loadUsers() {
    if (!currentBrand()) {
      return;
    }
    const resExisting = await executeQueryUtil(getProductUsersByBrandIdQuery(currentBrand().id))
    setExistingUsers({data: (resExisting.data?.getProductUsersByBrandId || []).filter(u => u.id != userInDb.id)})
  }

  useEffect(() => {
    loadUsers();
  }, [])

  const getColumns = () => {
    return(
      [
        {
          title: localStrings.email,
          field: 'email',
          editable: 'never'
        },

        {
          title: localStrings.roles,
          field: 'roles',
          render: rowData =>
            <Autocomplete
              multiple
              options={roles}
              value={rowData.roles || []}
              disabled
              getOptionLabel={(role) => localStrings.roleList[role]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={localStrings.roles}
                  placeholder={localStrings.roles}
                />
              )}
            />
          ,
          editComponent: (props) => {
            return (
              <Autocomplete
                multiple
                filterSelectedOptions
                onChange={(event, newValue) => {
                  props.onChange(newValue)
                }}
                options={roles}
                value={props.value || []}
                getOptionLabel={(role) => localStrings.roleList[role]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={localStrings.roles}
                    placeholder={localStrings.roles}
                  />
                )}
              />
            )
          }
        },
      ]
    );
  }

  const addItem = async (item) => {
    const res = await executeMutationUtil(createTempBrandUserMutation({...item, brandId: currentBrand().id}));
    await addDataTempBrandUserToCache(currentBrand().id, [res.data?.addTempBrandUser]);
    return {
      id: uuid,
      data: {
        ...res.data?.addTempBrandUser
      }}
  }

  const updateItem = async (item) => {
   let resProductUser = await executeMutationUtil(updateProductUserMutation(item));
   return resProductUser.data.updateProductUser;
  }

  const deleteItem = async (item) => {
    const resProductUser = await executeQueryUtil(getProductUserQuery(item.id));
    if (!resProductUser.data?.getProductUser) {
      return;
    }
    const productUser = cloneDeep(resProductUser.data?.getProductUser);

    if (productUser.allowedBrandIds?.includes(currentBrand()?.id)) {
      productUser.allowedBrandIds = productUser.allowedBrandIds.filter(brandId => brandId != currentBrand()?.id);

    }
    if (productUser.allowedBrandIds.length > 0) {
      productUser.brandId = productUser.allowedBrandIds[0];
    }
    else {
      productUser.brandId = null;
    }
    await executeMutationUtil(updateProductUserMutation(productUser));
  }

  let schema = yup.object().shape({
  });

  return (
    <Box mt={3}>
      {existingUsers &&
        <GeneralResultWithEdition
          getItemsFunction={() => existingUsers}
          addItemFunction={addItem}
          updateItemFunction={updateItem}
          deleteItemFunction={deleteItem}
          schemaValidation={schema}
          getPathToData={(result) => result}
          titleForm={localStrings.usersInBrand}
          getColumns={getColumns}
          messageDelete={localStrings.notif.userDeleted}
          messageCreate={localStrings.notif.userCreated}
          messageUpdate={localStrings.notif.userUpdated}
        >
        </GeneralResultWithEdition>
      }
    </Box>
  );
}

export default ExistingUserTable;
