import {gql} from "@apollo/client";

const common = `
  total
  billingMonthItem {
    total
    date
    billingDayItem {
      total
      date
      billingDayDeviceItem {
        date
        deviceId
        boardId
        total
      }
    }
  }
`

export const getBillingQuery = (brandId) => {
  var debug = `
    query {
      getBilling(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getBilling(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}


