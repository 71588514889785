import React, {useState} from 'react';
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {Visibility, VisibilityOff} from "@mui/icons-material";

function TextFieldPassword({value, title, ...rest}) {

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  return (

    <TextField
      type={showPassword ? 'text' : 'password'}
      value={value}
      {...rest}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
      }}
    />
  );
}

export default TextFieldPassword;
