import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField, Typography, Tooltip
} from "@mui/material";
import localStrings from "../../../localStrings";
import DialogConfirm from "../../../components/DialogConfirm";
import {useParams} from "react-router-dom";
import {executeQueryUtil} from "../../../utils/gqlUtil";
import {getDeviceQuery} from "../../../gql/DeviceGql";
import axios from "axios";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import {getProjectQuery} from "../../../gql/projectGql";
import useAuth from "../../../hooks/useAuth";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import {useSnackbar} from "notistack";
import SendToMobileIcon from '@mui/icons-material/Send';
import {getSystemVersionQuery} from "../../../gql/versionGql";

const config = require('../../../conf/config.json')
const mustache = require("mustache");
const localDeviceHomeUrl = "http://localhost";

export function getButtonWithDesc(button, desc) {
  return <Box
    sx={{display: 'flex', p: 1, bgcolor: 'background.paper', borderRadius: 1}}
  >
    <Box sx={{flexGrow: 1}}>
      <Typography
        variant="body1"
        color="textPrimary"
      >
        {desc}
      </Typography>
    </Box>
    <Box>
      {button}
    </Box>

  </Box>;
}

function SendCommands({}) {
  let {deviceId} = useParams();
  const [confirmRebootOpen, setConfirmRebootOpen] = useState(false);
  const [confirmUpdateOpen, setConfirmUpdateOpen] = useState(false);
  const [sendingCommand, setSendingCommand] = useState(false);
  const [device, setDevice] = useState(null);
  const [systemVersion, setSystemVersion] = useState("");
  const [project, setProject] = useState(null);
  const {currentBrand} = useAuth()
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = React.useState({
    displayUrl: '',
    printerSourceCode: '',
    speakText: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const res =  await executeQueryUtil(getDeviceQuery(deviceId));
      setDevice(res.data?.getDevice);
      const resProject =  await executeQueryUtil(getProjectQuery(currentBrand().id, res.data?.getDevice.projectId));
      setProject(resProject.data?.getProject);

      const resSystemVersion = await executeQueryUtil(getSystemVersionQuery())
      setSystemVersion(resSystemVersion?.data?.getSystemVersion)
    }

    // call the function
    fetchData()
      .catch(console.error);
  }, [])


  async function uploadWebSite() {
    setSendingCommand(true)
    //alert("uploadWebSite")
    const url = config.baseEventUrl + "/deployWebSite/" + device.id;
    try {
      await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.siteUploaded);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function takePicture() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/takePicture/" + device.id;
    try {
      await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.takePicture);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function reboot() {
    setSendingCommand(true)
    setConfirmRebootOpen(false)
    const url = config.baseEventUrl + "/reboot/" + device.id;
    try {
      await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.rebootSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function update() {
    setSendingCommand(true)
    setConfirmUpdateOpen(false)
    const url = config.baseEventUrl + "/update/" + device.id;
    try {
      await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.rebootSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function speak() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/speak/" + device.id;
    try {
      await axios.post(url, {
        speakText: values.speakText
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.speakSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function changeUrl() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/changeChromeAddressUrl/" + device.id;
    try {
      await axios.post(url, {
        targetUrl: values.displayUrl
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.changeUrlSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function goToHomePage() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/changeChromeAddressUrl/" + device.id;

    let homePageUrl = project.homePageUrl;
    if (!homePageUrl) {
      return;
    }
    const urlRendered = mustache.render(homePageUrl, {
      deviceId: device.id,
    })

    try {
      await axios.post(url, {
        targetUrl: urlRendered
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.changeUrlSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function goToDeviceHomeSite() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/changeChromeAddressUrl/" + device.id;
    try {
      await axios.post(url, {
        targetUrl: localDeviceHomeUrl
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.changeUrlSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }


  async function startRdp() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/startRdp/" + device.id;
    try {
      await axios.post(url, {
        targetUrl: localDeviceHomeUrl
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.rdpStarted);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function stopRdp() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/stopRdp/" + device.id;
    try {
      await axios.post(url, {
        targetUrl: localDeviceHomeUrl
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project?.authKey || currentBrand().authKey,
        }
      })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.rdpStopped);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }

  async function sendPrintSourceCode() {
    setSendingCommand(true)
    const url = config.baseEventUrl + "/printTicket/" + device.id;
    try {
      await axios.post(url, values.printerSourceCode
        , {
          headers: {
            'Content-Type': 'text/plain',
            'Authorization': project?.authKey || currentBrand().authKey,
          }
        })
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.changeUrlSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setSendingCommand(false)
    }
  }



  return (
    <div style={{ width: '100%' }}>

      <Dialog
        open={sendingCommand}
        maxWidth="lg"
        //minWidth={minWidth}
        //style={{ paper: { minWidth: minWidth }}}
      >
        <DialogTitle>{localStrings.sendingCommand}</DialogTitle>
        <DialogContent>
          <ClipLoaderComponent/>
        </DialogContent>
      </Dialog>
      <DialogConfirm
        dialogOpen={confirmRebootOpen}
        handleClose={() => setConfirmRebootOpen(false)}
        handleConfirm={reboot}
        deleteDialogText={localStrings.confirmMessage.reboot}
      />



      <DialogConfirm
        dialogOpen={confirmUpdateOpen}
        handleClose={() => setConfirmUpdateOpen(false)}
        handleConfirm={update}
        deleteDialogText={localStrings.confirmMessage.update}
      />

      {(device) ?
        <Card>

          <CardHeader title={localStrings.commandsToDevice}/>

          <Divider/>

          <CardContent>
            {getButtonWithDesc(
              <Button
                disabled={device.version === systemVersion.version}
                sx={{margin: 1}}
                onClick={() => setConfirmUpdateOpen(true)}
                color="primary"
                variant="contained">
                {localStrings.actionUpdateReboot}
              </Button>
            , localStrings.formatString(localStrings.actionUpdateRebootDesc,device.version,systemVersion.version))}


            {getButtonWithDesc(
              <Button
                sx={{margin: 1}}
                onClick={() => setConfirmRebootOpen(true)}
                color="primary"
                variant="contained">
                {localStrings.actionReboot}
              </Button>
              , localStrings.actionRebootDesc)}

            {!device.lite && getButtonWithDesc(
              <Button
                sx={{margin: 1}}
                onClick={takePicture}
                color="primary"
                variant="contained">
                {localStrings.actionTakePicture}
              </Button>
              , localStrings.actionTakePictureDesc)}


            {project && getButtonWithDesc(
              <Button
                sx={{margin: 1}}
                onClick={uploadWebSite}
                color="primary"
                variant="contained">
                {localStrings.actionUpdateWebSite}
              </Button>
              , localStrings.actionUpdateWebSiteDesc)}


            {getButtonWithDesc(
              <Button
                sx={{margin: 1}}
                onClick={startRdp}
                color="primary"
                variant="contained">
                {localStrings.actionStartRdp}
              </Button>
              , localStrings.actionStartRdpDesc)}

            {getButtonWithDesc(
              <Button
                sx={{margin: 1}}
                onClick={stopRdp}
                color="primary"
                variant="contained">
                {localStrings.actionStopRdp}
              </Button>
              , localStrings.actionStopRdpDesc)}

            {!device.lite && getButtonWithDesc(
              <Button
                sx={{margin: 1}}
                onClick={goToHomePage}
                color="primary"
                variant="contained">
                {localStrings.actionToHomePage}
              </Button>
              , localStrings.actionToHomePageDesc)}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                p: 1,
                m: 1,
                borderRadius: 1,
              }}
            >
            </Box>
            {!device.lite &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  p: 1,
                  m: 1,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                }}
              >
                <FormControl sx={{flexGrow: 1}} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{localStrings.sendDisplayUrlToDevice}</InputLabel>
                  <OutlinedInput
                    value={values.displayUrl}
                    onChange={handleChange('displayUrl')}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={localStrings.displayUrlOnDevice}>
                          <IconButton
                            onClick={changeUrl}
                            edge="end"
                          >
                            <SendToMobileIcon/>
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Box>
            }

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <TextField
                inputProps={{
                  autocomplete: "new-password"
                }}
                fullWidth
                label={localStrings.sendSourceToPrinter}
                onChange={handleChange('printerSourceCode')}
                multiline
                rows={10}
                value={values.printerSourceCode}
                variant="outlined"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Button
                onClick={sendPrintSourceCode}
                color="primary"
                variant="contained">
                {localStrings.sendSourceToPrinter}
              </Button>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <TextField
                inputProps={{
                  autocomplete: "new-password"
                }}
                fullWidth
                label={localStrings.sendSpeakToDevice}
                onChange={handleChange('speakText')}
                multiline
                rows={10}
                value={values.speakText}
                variant="outlined"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Button
                onClick={speak}
                color="primary"
                variant="contained">
                {localStrings.sendSpeakToDevice}
              </Button>
            </Box>
          </CardContent>
        </Card>
        :
        <ClipLoaderComponent/>
      }
    </div>
  );
}

export default SendCommands;
