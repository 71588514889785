import React from 'react';
import {IconButton, InputAdornment, OutlinedInput, TextField} from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';

function TextFieldWithCopy({value, ...rest}) {
  return (
    <TextField
      value={value}
      {...rest}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              onClick={() => navigator.clipboard.writeText(value)}
              edge="end"
            >
              <FileCopyIcon/>
            </IconButton>
          </InputAdornment>
      }}
    />
  );
}

export default TextFieldWithCopy;
