import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Search as SearchIcon } from 'react-feather';
import useIsMountedRef from '../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  listItemTextSelected: {
    marginRight: theme.spacing(1),
    fontWeight: "bold",
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}));

const ListWithSearch = ({
                          getItemsFunc,
                          getTextDisplayItem,
                          getIconItem,
                          getItemId,
                          selectItem,
                          getMessageDone,
                          title,
                          searchTitle,
                          getIconTop,
                          toolTip,
                          activeItem,
                          itemsSource,
                        }) => {
  const classes = useStyles();
  const ref = useRef(null);
  const {enqueueSnackbar} = useSnackbar();

  const isMountedRef = useIsMountedRef();
  const [items, setItems] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const getItems = useCallback(async () => {
    if (getItemsFunc) {
      setItems(await getItemsFunc());
    }
  }, [isMountedRef]);

  useEffect(() => {
    getItems();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (getItemsFunc) {
      let itemsFunc = getItemsFunc();
      setItems(itemsFunc)
    }

  }, []);

  return (
    <>
      <Tooltip title={toolTip}>
        <IconButton
          color="inherit"
          onClick={handleOpen}
          ref={ref}
        >
          {getIconTop()}

        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >

        <Typography
          variant="h4"
          color="textPrimary"
        >
          {title}
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder={searchTitle}
            value={searchValue}
            variant="outlined"
          />
        </Box>
        <Box mt={2}>
          <List disablePadding>
            {(itemsSource || items) && Array.isArray((itemsSource || items)) && (itemsSource || items).map(item => {
              //const contact = contacts.byId[contactId];
              if (searchValue && searchValue !== '' && !getTextDisplayItem(item).toLowerCase().includes(searchValue.toLowerCase()) ) {
                return;
              }
              return (
                <ListItem
                  button
                  key={getItemId(item)}
                  onClick={(event) => {
                    if(getMessageDone) {
                      var message = getMessageDone(item);
                      enqueueSnackbar(message, {
                        variant: 'success'
                      } );
                    }
                    selectItem(item)
                    handleClose();
                  }}
                >
                  {
                    getIconItem &&
                    <ListItemAvatar>
                      <Avatar
                        alt="Person"
                        src={getItemId(item)}
                      />
                    </ListItemAvatar>
                  }
                  {activeItem && activeItem(item) &&  <CheckIcon/>}
                  <ListItemText
                    className={activeItem ?
                      (activeItem(item) ? classes.listItemTextSelected : classes.listItemText) : classes.listItemText}
                    primary={(
                      getTextDisplayItem(item)
                    )}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default ListWithSearch;
