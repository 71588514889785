import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, CardHeader, Divider, TableCell, TextField} from "@mui/material";
import * as Yup from "yup";
import config from "../../../conf/config.json";
import axios from "axios";
import {useNavigate} from "react-router";
import useAuth from "../../../hooks/useAuth";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import localStrings from "../../../localStrings";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
//import { DataGrid } from '@mui/x-data-grid';

function SelectAmountToPay() {

  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const {currentBrand} = useAuth();


  async function getPayments() {
    setLoading(true)
    const url = config.paymentServiceUrl + "/stripe/payments/" + currentBrand().id;
    try {
      const res = await axios.get(url,{
        headers: {
          'Content-Type': 'application/json',
        }
      })

      setPayments(res.data || []);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getPayments()
  }, [])

  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.amount}
        </TableCell>
        <TableCell>
          {localStrings.kioskCredits}
        </TableCell>
        <TableCell>
          {localStrings.creationDate}
        </TableCell>

      </React.Fragment>
    )
  }

  function formatAmount(item) {
    return (parseFloat(item.amount) / 100).toFixed(2);
  }

  function formatCreationDate(item) {
    return moment.unix(item.created).format('LLLL')
    //return moment(item.created).format();
  }

  const getTableCell = (item) => {

    return(
      <React.Fragment>
        <TableCell>
          {formatAmount(item)}
        </TableCell>
        <TableCell>
          {item.metadata.newCredits}
        </TableCell>
        <TableCell>
          {formatCreationDate(item)}
        </TableCell>
      </React.Fragment>
    )
  }

  const sortOptions = [
    {
      value: 'created|desc',
      label: localStrings.creationDate
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Card>

        <CardHeader title={localStrings.paymentHistory}/>
        <Divider/>
        <CardContent>
          {loading ?
            <ClipLoaderComponent/>
            :
            <>
              <GeneralResult
                disableSearch
                getPathToData={(result) => result}
                getTableCell={getTableCell}
                getTableCellColumn={getTableCellColumn}
                //getEditLink={(item) => "/app/devices/detail/" + item.id}
                deleteDialogText={localStrings.confirmMessage.removeDevice}
                //deleteItemsFunction={removeDevices}
                //deleteItemNotifMessage={localStrings.notif.deviceRemoved}
                sortOptions={sortOptions}
                //searchProperties={['name']}
                itemsSource={payments}
              >
              </GeneralResult>
            </>
          }

        </CardContent>
      </Card>
    </div>
  );
}

export default SelectAmountToPay;
