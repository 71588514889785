import React from 'react';
import { Box, Container } from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import Results from './Results';
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: 100
  }
}));

const DeviceListView = () => {
  const classes = useStyles();


  return (
    <Page
      className={classes.root}
      title={localStrings.deviceList}
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results/>
        </Box>
      </Container>
    </Page>
  );
};

export default DeviceListView;
