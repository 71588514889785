import { useContext } from 'react';
import {APPUtilContext} from "../contexts/APPUtilProvider";

function useAppUtil() {
  const { error, addError, removeError, warning, addWarning, showPresenter, presenter,
    removeWarning, addErrorAndRedirect, errorRedirect, removeErrorAndRedirect,
    redirectPage, removeRedirectPageUri, redirectPageUri, setBlobs, blobs, setFiles, files} = useContext(APPUtilContext);
  return { error, addError, removeError,
    warning, addWarning, showPresenter, presenter, removeWarning, addErrorAndRedirect, errorRedirect, removeErrorAndRedirect,
    redirectPage, removeRedirectPageUri, redirectPageUri, setBlobs, blobs, setFiles, files};
}

export default useAppUtil;
