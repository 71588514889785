import React from 'react';
import {Box, Container} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import localStrings from "../../localStrings";
import {makeStyles} from "@mui/styles";
import PaymentDone from "./PaymentDone";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const PaymentSucess = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title={localStrings.paymentSuccess}
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <PaymentDone/>
          {/*<WrapperPayment/>*/}
        </Box>
      </Container>
    </Page>
  );
};

export default PaymentSucess;
