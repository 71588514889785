import React from 'react';
import clsx from 'clsx';
import {Box, Breadcrumbs, Button, Container, Grid, Link, SvgIcon, Typography} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Link as RouterLink } from 'react-router-dom';
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {}
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      spacing={3}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div style={{ width: '100%' }}>
        <Container>
          <Box
            sx={{
              mt: 2,
              ml: 2,
              display: 'flex',
              // justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="body1"
              color="textPrimary"
            >
              {localStrings.stripePayAccount}
            </Typography>
            <RouterLink to="/app/Projects">
              <Button
                color="primary"
                variant="contained"
                className={classes.action}
                startIcon={
                  <SvgIcon fontSize="small">
                    <NavigateBeforeIcon />
                  </SvgIcon>
                }
              >
                {localStrings.backToStripePayAccountList}
              </Button>
            </RouterLink>
          </Box>
        </Container>
      </div>

    </Grid>
  );
}

export default Header;
