import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import GraphQl from "./pages/GraphQl";
import ProjectListView from "./pages/Projects/ProjectListView";
import ProjectDetailsView from "./pages/Projects/ProjectDetailsView";
import ProjectAddView from "./pages/Projects/ProjectAddView";
import DeviceListView from "./pages/Devices/DeviceListView";
import DeviceAddView from "./pages/Devices/DeviceAddView";
import DeviceDetailsView from "./pages/Devices/DeviceDetailsView";
import BrandCreateUpdateView from "./pages/Brands/AccountCreateUpdateView";
import Payment from "./pages/Payment";
import PaymentSucess from "./pages/PaymentSucess";
import DisplayEvents from "./pages/DisplayEvents";
import MainLayoutNoNavBar from "./components/MainLayoutNoNavBar";

import BoardListView from "./pages/Boards/BoardListView";
import BoardDetailsView from "./pages/Boards/BoardDetailsView";
import BoardAddView from "./pages/Boards/BoardAddView";
import StripePayAccountListView from "./pages/StripePayAccounts/StripePayAccountListView";
import StripePayAccountAddView from "./pages/StripePayAccounts/StripePayAccountAddView";
import StripePayAccountDetailsView from "./pages/StripePayAccounts/StripePayAccountDetailsView";
import Register from "./pages/Register";
import AuthChange from "./pages/AuthChange";
import ResetPassword from "./pages/ResetPassword";
import AccountAddView from "./pages/Brands/AccountAddView";

export const loginRoute = 'login';
export const registerRoute = 'register';
export const resetPasswordRoute = 'resetPassword';
export const emailVerificationRoute = 'emailVerification';
export const authChangeRoute = 'authChange';

export const accountRoute = 'account';
export const accountAddRoute = 'account/add';

export const projectsRoute = 'projects';
export const projectAddRoute = 'projects/add';
export const projectDetailRoute = 'projects/detail/:projectId';

export const devicesRoute = 'devices';
export const deviceAddRoute = 'devices/add';
export const deviceDetailRoute = 'devices/detail/:deviceId';

export const boardsRoute = 'boards';
export const boardAddRoute = 'boards/add';
export const boardDetailRoute = 'boards/detail/:boardId';

export const stripePayAccountsRoute = 'stripePayAccounts';
export const stripePayAccountAddRoute = 'stripePayAccounts/add';
export const stripePayAccountDetailRoute = 'stripePayAccounts/detail/:stripePayAccountId';

export const paymentSuccessRoute = 'paymentSuccess';
export const dashBoardRoute = 'dashboard';
export const settingsRoute = 'settings';
export const graphQlRoute  = 'GraphQl';
export const paymentRoute  = 'payment';
export const notFoundRoute = '404';
export const displayEventsFoundRoute = 'displayEvents/:brandId/:boardId';

export const getAppRoute = (route) => {
  return "/app/" + route;
}

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: projectsRoute, element: <ProjectListView/> },
      { path: projectAddRoute, element: <ProjectAddView/> },
      { path: projectDetailRoute, element: <ProjectDetailsView/> },

      { path: devicesRoute, element: <DeviceListView/> },
      { path: deviceAddRoute, element: <DeviceAddView/> },
      { path: deviceDetailRoute, element: <DeviceDetailsView/> },

      { path: boardsRoute, element: <BoardListView/> },
      { path: boardAddRoute, element: <BoardAddView/> },
      { path: boardDetailRoute, element: <BoardDetailsView/> },

      { path: stripePayAccountsRoute, element: <StripePayAccountListView/> },
      { path: stripePayAccountAddRoute, element: <StripePayAccountAddView/> },
      { path: stripePayAccountDetailRoute, element: <StripePayAccountDetailsView/> },

      { path: dashBoardRoute, element: <Dashboard /> },

      { path: accountRoute, element: <BrandCreateUpdateView/> },
      { path: accountAddRoute, element: <AccountAddView/> },

      { path: paymentRoute, element: <Payment/> },
      { path: paymentSuccessRoute, element: <PaymentSucess/> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: resetPasswordRoute, element: <ResetPassword /> },
      { path: registerRoute, element: <Register /> },
      { path: authChangeRoute, element: <AuthChange /> },
      { path: loginRoute, element: <Login /> },
      { path: graphQlRoute , element: <GraphQl />},
      { path: notFoundRoute, element: <NotFound /> },
      { path: '/', element: <Navigate to={getAppRoute(dashBoardRoute)} /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayoutNoNavBar />,
    children: [
      { path: displayEventsFoundRoute, element: <DisplayEvents /> },
    ]
  }
];

export default routes;
