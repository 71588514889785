import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
  webHookEventUrl
  homePageUrl
  authKey
  useInactivityTouchScreen
  inactivityTouchScreenAction
  durationInactivityTouchScreen
  
  useCamera
  cameraCancelPictureAction
  
  cloudTextToSpeechVoiceName
  
  webSiteZipUrl
`

export const getAllProjectsQuery = (brandId) => {
  var debug = `
    query {
      getProjects(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getProjects(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}


export const getProjectQuery = (brandId, projectId) => {
  var debug = `
    query {
      getProject(brandId: "${brandId}", projectId: "${projectId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getProject(brandId: "${brandId}", projectId: "${projectId}") {
        ${common}
      }
    }
  `;
}

export const bulkDeleteProjectMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteProject(brandId: "${brandId}", projectIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteProject(brandId: "${brandId}", projectIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const createProjectMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
    ["inactivityTouchScreenAction", "cameraCancelPictureAction"] );

  var debug = `
    mutation {
      addProject(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addProject(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const updateProjectMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"],
    ["inactivityTouchScreenAction", "cameraCancelPictureAction"] );

  var debug = `
    mutation {
      updateProject(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateProject(brandId: "${brandId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const deleteProjectCache = async (brandId, ids) => {
  const query = getAllProjectsQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getProjects.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getProjects: [...newData]
      },
    });
  }
}

export const addDataProjectToCache = async (brandId, datas) => {
  if (!datas) {
    return
  }
  const query = getAllProjectsQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getProjects: [...data.data.getProjects, ...datas]
      },
    });
  }
}

