import {gql} from "@apollo/client";

// const common = `
//   total
//   billingMonthItem {
//     date
//     total
//     billingDayItem: {
//       date
//       total
//       billingDayDeviceItem {
//         date
//         deviceId,
//         total
//       }
//     }
//   }
// `

const common = `
  email
`

export const getSuperUserWhiteListQuery = () => {
  var debug = `
    query {
      getSuperUsers {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getSuperUsers {
        ${common}
      }
    }
  `;
}


