import React, {useEffect, useState} from 'react';
import {Box, Container, Tab, Tabs} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import BrandCreateUpdateForm from "./BrandCreateUpdateForm";
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";
import {getUriSetting, saveUriSetting, SELECTED_TAB} from "../../../utils/optionsUriLocalStorage";
import SelectAmountToPay from "./SelectAmountToPay";
import PaymentList from "./PaymentList";
import ChargeHistory from "./ChargeHistory";
import useAuth from "../../../hooks/useAuth";
import BrandUsers, {ROLE_ADMIN} from "./brandUsers";
import LifeCheckWebHook from "./LifeCheckWebHook";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const AccountCreateUpdateView = () => {
  const {currentBrand, userInRole} = useAuth();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(getUriSetting(SELECTED_TAB) || 'general');
  const handleTabsChange = (event, value) => {
    saveUriSetting(SELECTED_TAB, value)
    setCurrentTab(value);
  };

  useEffect(() => {
    if (!currentBrand()) {
      setCurrentTab("general")
    }
  }, [])

  return (
    <Page
      className={classes.root}
      title={localStrings.account}
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>

          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            textColor="secondary"
            variant="scrollable"
          >
            <Tab label={localStrings.brand} value="general" />
            {/*<p>{JSON.stringify(currentBrand() || {})}</p>*/}
            {/*<p>{currentTab}</p>*/}
            {currentBrand() &&
                <Tab label={localStrings.chargeMyAccount} value="chargeAccount" />
            }
            {currentBrand() &&
              <Tab label={localStrings.paymentHistory} value="paymentList"/>
            }
            {currentBrand() &&
                <Tab label={localStrings.chargeHistory} value="chargeHistory" />
            }
            {currentBrand() && userInRole(ROLE_ADMIN) &&
              <Tab label={localStrings.brandUsers} value="brandUser" />
            }
            {currentBrand() &&
              <Tab label={localStrings.lifeCheckWebHook} value="lifeCheckWebHook" />
            }
          </Tabs>
          {currentTab === 'general' &&  <BrandCreateUpdateForm/>}
          {currentTab === 'chargeAccount' &&  <SelectAmountToPay/>}
          {currentTab === 'paymentList' &&  <PaymentList/>}
          {currentTab === 'chargeHistory' &&  <ChargeHistory/>}
          {currentTab === 'brandUser' &&  <BrandUsers/>}
          {currentTab === 'lifeCheckWebHook' &&  <LifeCheckWebHook/>}

        </Box>
      </Container>
    </Page>
  );
};

export default AccountCreateUpdateView;
