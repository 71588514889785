import React, {useEffect, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader, Collapse,
  Divider,
  IconButton, Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import localStrings from "../../../localStrings";
import {executeQueryUtil} from "../../../utils/gqlUtil";
import {getBillingQuery} from "../../../gql/billingGql";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from "moment";

//import { DataGrid } from '@mui/x-data-grid';

function ChargeHistory() {

  const {currentBrand} = useAuth();
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState({});

  async function getBilling() {
    setLoading(true)
    const res = await executeQueryUtil(getBillingQuery(currentBrand().id));
    setBilling(res?.data?.getBilling);
    setLoading(false)
  }

  useEffect(() => {
    getBilling()
  }, [])

  function formatMonthDate(date) {
    return moment(date, "YYYYMM").format("YYYY-MM");
  }

  function formatDayDate(date) {
    return moment(date, "YYYYMMDD").format("YYYY-MM-DD");
  }

  function formatAmount(total) {
    return total + " " + localStrings.kioskCredits;
  }

  function Row(props) {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    let allBillingDayDeviceItem = [];
    row.billingDayItem.forEach(item => {
      allBillingDayDeviceItem = allBillingDayDeviceItem.concat(item.billingDayDeviceItem);
    })


    return(
      <>
        {/*<p>{JSON.stringify(billing)}</p>*/}
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {formatMonthDate(row.date)}
          </TableCell>
          <TableCell align="right">
            {formatAmount(row.total)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {localStrings.detail}
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>{localStrings.date}</TableCell>
                      <TableCell>{localStrings.device}</TableCell>
                      <TableCell>{localStrings.board}</TableCell>
                      <TableCell>{localStrings.amount}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {allBillingDayDeviceItem.map((billingDayDeviceItem) => (
                      // <p>{JSON.stringify(billingDayDeviceItem)}</p>
                      <TableRow key={billingDayDeviceItem.date}>
                        <TableCell component="th" scope="row">
                          {formatDayDate(billingDayDeviceItem.date)}
                        </TableCell>
                        <TableCell>{billingDayDeviceItem.deviceId || ""}</TableCell>
                        <TableCell>{billingDayDeviceItem.boardId || ""}</TableCell>
                        <TableCell align="right">{formatAmount(billingDayDeviceItem.total)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <Card>
        <CardHeader title={localStrings.chargeHistory}/>
        <Divider/>
        <CardContent>
          {loading ?
            <ClipLoaderComponent/>
            :
            <>
               <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>{localStrings.date}</TableCell>
                      <TableCell align="right">{localStrings.amount}</TableCell>
                      {/*<TableCell align="right">Fat&nbsp;(g)</TableCell>*/}
                      {/*<TableCell align="right">Carbs&nbsp;(g)</TableCell>*/}
                      {/*<TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(billing?.billingMonthItem || []).map((row) => (
                      <Row key={row.date} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }

        </CardContent>
      </Card>
    </div>
  );
}

export default ChargeHistory;
