import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, TextField} from "@mui/material";
import {addDataFunctionToCache, createFunctionMutation, updateFunctionMutation} from "../../../../../gql/functionGql";
import {useParams} from "react-router-dom";
import localStrings from "../../../../../localStrings";
import {executeMutationUtil, executeQueryUtil} from "../../../../../utils/gqlUtil";
import useAuth from "../../../../../hooks/useAuth";
import GeneralEditor from "../../../../../components/CrudFrom/GeneralEditor";
import * as Yup from "yup";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/theme/monokai";
import {useFullScreenHandle} from "react-full-screen";
import {defaultCodeHead, defaultCodeMain, defaultCodeTail} from "./defaultCode";
import TextFieldWithCopy from "../../../../../components/TextFieldWithCopy";
import config from "../../../../../conf/config.json";
import axios from "axios";
import {getProjectQuery} from "../../../../../gql/projectGql";
import ClipLoaderComponent from "../../../../../components/ClipLoaderComponent";
import DialogConfirm from "../../../../../components/DialogConfirm";
import AlertHtmlLocal from "../../../../../components/alert/AlertHtmlLocal";

const conf = require('../../../../../conf/config.json')

const FunctionDetail = ({selectedFunction,setDetailNavigate}) => {
  const handle = useFullScreenHandle();
  let {projectId} = useParams();
  const [checkingCode, setCheckingCode] = useState(false)
  const [project, setProject] = useState(null);
  //const [dirtySourceCode, setDirtySourceCode] = useState(false);
  const [displayCodeError, setDisplayCodeError] = useState(false);
  const [codeError, setCodeError] = useState(null);

  const { currentBrand } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const resProject =  await executeQueryUtil(getProjectQuery(currentBrand().id, projectId));
      setProject(resProject.data?.getProject);
    }
    fetchData()
      .catch(console.error);
  }, [])

  const buildFormValues = (item) => {
    return {
      initialValues: {
        name: item.name || '',
        codeContent: item.codeContent || defaultCodeMain,
      },
      validationSchema: {
        name: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {

    function getUrlFunction() {
      return conf.functionProcessorUrl + "/execute/" + currentBrand().id + "/" + projectId + "/" + selectedFunction.id;
    }

    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label={localStrings.functionName}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.name}
            variant="outlined"
          />
        </Grid>
        {selectedFunction &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextFieldWithCopy
              inputProps={{
                autocomplete: "new-password"
              }}
              readOnly
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label={localStrings.functionUrl}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
              value={getUrlFunction()}
              variant="outlined"/>

          </Grid>
        }
        {selectedFunction &&
        <Grid
          item
          md={12}
          xs={12}
        >

          <AceEditor
            //height="100%"
            readOnly
            //style={{width: "100%"}}
            style={{width: "100%", height:"290px", marginBottom:2}}
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={defaultCodeHead}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}/>

          {codeError ?
            <Box mb={2} mt={2}>
              <AlertHtmlLocal severity="warning" title={localStrings.issueWithCode} content={codeError}></AlertHtmlLocal>
            </Box>
            :
            <Box mb={2} mt={2}>
              <AlertHtmlLocal severity="info" title={localStrings.issueWithCode} content={localStrings.noIssueWithCode}></AlertHtmlLocal>
            </Box>
          }

          {/*<FullScreen handle={handle}>*/}
          {checkingCode ?
            <ClipLoaderComponent/>
            :
            <AceEditor
              //height="100%"
              style={{width: "100%"}}
              placeholder={localStrings.yourCodeHere}
              mode="javascript"
              theme="monokai"
              onChange={(code) => {
                setFieldValue('codeContent', code)
              }}
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={values.codeContent}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}/>
          }

          <AceEditor
            //height="100%"
            readOnly
            style={{width: "100%", height:"50px", marginTop:2}}
            placeholder="Placeholder Text"
            mode="javascript"
            theme="monokai"
            name="blah2"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={defaultCodeTail}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}/>
          {/*</FullScreen>*/}
        </Grid>
        }


      </Grid>
    );
  }


  async function saveForm(item, values) {
    let data;
    data = {...item, ...values};
    if (!selectedFunction){
      const functionCreated = await executeMutationUtil(createFunctionMutation(currentBrand().id, projectId, data));
      const resAdd = await addDataFunctionToCache(currentBrand().id, projectId, [functionCreated.data?.addFunction]);
      data = functionCreated.data.addFunction;
      setDetailNavigate(false);
    }
    else {
      const functionUpdated = await executeMutationUtil(updateFunctionMutation(currentBrand().id, projectId, data));
      data = functionUpdated.data.updateFunction;
      //await addDataFunctionToCache(currentBrand().id, projectId, [functionUpdated.data?.updateFunction]);
    }
    //setDirtySourceCode(false);
    await checkCode();
    return data;
  }

  async function checkCode() {
    if (!selectedFunction) {
      return;
    }
    setCheckingCode(true)

    //brandId/:projectId/:functionId
    const url = config.functionProcessorUrl + "/check/" + currentBrand().id + "/" + projectId + "/" + selectedFunction.id;
    try {
      const res = await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': project.authKey,
        }
      })
      //alert(JSON.stringify(res.data));
      if (!res.data.compile && res.data.error) {
        setCodeError(res.data.error)
        //setDisplayCodeError(true)
      }
      else if (res.data.compile) {
        setCodeError(null)
        //setDisplayCodeError(true)
      }
      //enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.rebootSent);
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setCheckingCode(false)
    }
  }

  return (
    <>



      <div style={{width: '100%'}}>
        <Box
          sx={{
            mr: 2,
            mt: 2,
            ml: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => setDetailNavigate(false)}
          >
            {localStrings.bascToFunctionList}
          </Button>
        </Box>
      </div>


      <GeneralEditor
        getItemFunction={() => selectedFunction}
        buildFormValues={buildFormValues}
        getCardContentForm={getCardContentForm}
        saveForm={saveForm}
        applyButton={!selectedFunction ? localStrings.create : localStrings.update}
        resetFormProp={!selectedFunction }
        messageSuccess={!selectedFunction  ?
          localStrings.notif.functionCreated : localStrings.notif.functionUpdated}
        titleForm={!selectedFunction ? localStrings.createFunction : localStrings.editFunction}
        getPathToData={(result) => {
          return result;
        }}>
      </GeneralEditor>

    </>
  )

};


export default FunctionDetail;
