import React, {forwardRef, useEffect} from 'react';
import MaterialTable, {MTableToolbar} from "material-table";
import {Button, createMuiTheme, createTheme, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import localStrings from '../../localStrings';
import useAppUtil from '../../hooks/useAppUtil';
import {useSnackbar} from 'notistack';
import useSettings from '../../hooks/useSettings';
import useSound from 'use-sound';
import enqueueSnackbarWithSound from '../../utils/SnackBarWithSound';
import config from '../../conf/config.json';
import Typography from "@mui/material/Typography";
import ClipLoaderComponent from '../ClipLoaderComponent';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';

const theme = createTheme({
  fontSize: '1.5rem'
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'mt',
  seed: 'mt'
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const MAX_OPTIONS = 2;
const TIMEOUT_EDIT = 0;
export default function GeneralResultWithEdition({
                                                   titleForm,
                                                   getItemsFunction,
                                                   itemSource,
                                                   getColumns,
                                                   // columns,
                                                   // setColumns,
                                                   validateData,
                                                   schemaValidation,
                                                   addItemFunction,
                                                   updateItemFunction,
                                                   updateAllItemsFunction,
                                                   deleteItemFunction,
                                                   getToolbarActionFunction,
                                                   notEditableRows,
                                                   refreshDataVariation,
                                                   messageUpdate,
                                                   messageCreate,
                                                   messageDelete,
                                                   detailPanel,
                                                   duplicate,
                                                   reorder,
                                                   matchingFunc,
                                                   duplicateToolTip,
                                                   updateCallBack,

                                                 }) {

  const [openDialog, setOpenDialog] = React.useState(false);
  const [optionsDialog, setOptionsDialog] = React.useState({});
  const [dialogContent, setDialogContent] = React.useState(null);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [columns, setColumns] = React.useState(getColumns());
  const [state, setState] = React.useState({ data: []});
  const [dataLoading, setDataLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { playSoundIfActive } = useSettings()

  const [playDelete] = useSound(
    config.sound.delete,
    { volume: 0.5 }
  );

  const [playDone] = useSound(
    config.sound.done,
    { volume: 0.5 }
  );

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function getColumnMapping(columns) {
    return  columns.map(column => {
        return (
          {
            title: column.name,
            field: column.mappingId,
            editable: 'never'
          });
      }
    );
  }

  const handleConfirmDialog = async () => {
    // setDataLoading(true)
    // setOpenDialog(false);
    // var data = await optionsDialog.getData(checkBoxesSelected);
    // if (!data) {
    //   return;
    // }
    updateData(await refreshDataVariation());
    setDataLoading(false);
    setOpenDialog(false);
  };

  const updateData = (data) => {
    if (!data) {
      return;
    }
    if (data.columns) {
      var newColums = getColumnMapping(data.columns);
      setColumns([...newColums, ...getColumns()])
    }

    setState({ data: filterDatas(data.values)});
  }


  const { addError } = useAppUtil();

  useEffect(() => {
    async function fecthData() {
      var result = itemSource || await getItemsFunction();
      if (result && result.data) {
        var dataRes = result.data;
        var dataFiltered = [];
        dataRes.forEach(data => {
          var copy = { ...data };
          delete copy["id"];
          copy.internalId = data.id;
          dataFiltered.push(copy);
        })
        setState({ data: dataFiltered });
        if (result.columns) {
          //alert("result.columns " + result.columns)
          setColumns([...getColumnMapping(result.columns), ...getColumns()])
        }
      }
    }

    fecthData();
  }, [])

  function filterData(data) {
    var copy = { ...data };
    delete copy['id'];
    copy.id = data.internalId;
    delete copy['internalId'];
    return copy;
  }

  function filterDatas(datas) {
    var ret = [];
    datas.forEach(data => ret.push(filterData(data)))
    return ret;
  }


  async function moveUp(rowData, matchingFunc) {
    let dataCopy = [...state.data];


    //let index = dataCopy.findIndex(item => item.extRef == rowData.extRef);
    let index = dataCopy.findIndex(item => matchingFunc(item, rowData))
    //item.extRef == rowData.extRef);
    if (index > 0) {
      let element = dataCopy[index];
      //dataCopy.splice(index-1, 1);
      dataCopy.splice(Math.max(index - 1, 0), 0, element);
      dataCopy.splice(index + 1, 1);
    }
    await updateAllItemsFunction(dataCopy)
    //let copyData = [...prevState.data];

    setState(() => {
      return {data: dataCopy};
    });
    // setState( async (prevState) => {
    //   let dataCopy = [...prevState.data];
    //   let index = dataCopy.findIndex(item =>  matchingFunc(item, rowData)));
    //   if (index == -1) {
    //     return
    //   }
    //
    //   if (index > 0) {
    //     let element = dataCopy[index];
    //     //dataCopy.splice(index-1, 1);
    //     dataCopy.splice(Math.max(index - 1, 0), 0, element);
    //     dataCopy.splice(index + 1, 1);
    //   }
    //   await updateAllItemsFunction(dataCopy)
    //   //return {...prevState};
    //
    //   setState(() => {
    //     return {data: dataCopy};
    //   });
    // });
  }

  async function moveDown(rowData, matchingFunc) {
    let dataCopy = [...state.data];
    let index = dataCopy.findIndex(item => matchingFunc(item, rowData));
    if (index == -1) {
      return
    }
    if (index < dataCopy.length - 1) {
      let element = dataCopy[index];
      dataCopy.splice(Math.max(index + 2, 0), 0, element);
      dataCopy.splice(index, 1);
    }
    await updateAllItemsFunction(dataCopy)
    //return {...prevState};

    setState(() => {
      return {data: dataCopy};
    });
  }

  function getActions() {
    let allActions = [];
    if (duplicate) {
      allActions.push( {
        icon: 'filterNone',
        tooltip: duplicateToolTip || "dupliquer",
        onClick: async (event, rowData) => {
          let newData = await duplicate(rowData);
          let ids = state.data.map(item => item.id);
          let maxId = Math.max(...ids);
          //let maxId = 0
          //newData.id = maxId + 1;
          setState(prevState => {
            const data = [...prevState.data];
            data.push(newData);
            return { ...prevState, data };
          });
        }
      })
    }

    if (reorder && updateAllItemsFunction) {
      allActions = allActions.concat( [
        {
          icon: () => <KeyboardArrowUpIcon/>,
          tooltip: localStrings.moveUp,
          onClick: async (event, rowData) => {
            moveUp(rowData, matchingFunc);
            //alert("up")
          }
        },
        {
          icon: () => <KeyboardArrowDownIcon/>,
          tooltip: localStrings.moveDown,
          onClick: async (event, rowData) => {
            moveDown(rowData, matchingFunc);
            //alert("down")
          }
        },

      ]);
    }

    return allActions;
  }

  return (
    <div>
      { dataLoading ?
        <div style={{width: "100%"}}>
          <ClipLoaderComponent/>
        </div>
        :
        <div>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>

            <DialogContent>
              {dialogContent}
              {/*  <FormGroup>*/}
              {/*    {optionsDialog.options && optionsDialog.options.map(option =>*/}
              {/*      <FormControlLabel*/}
              {/*        key={option.id}*/}
              {/*        control={*/}
              {/*          <Checkbox*/}
              {/*            checked={*/}
              {/*              checkBoxesSelected.includes(option.id)*/}
              {/*            }*/}
              {/*            onChange={(e) => handleChangeCheckBox(e, option.id)}*/}
              {/*            name={option.variant}*/}
              {/*            //disabled={checkBoxesSelected.length >= MAX_OPTIONS}*/}
              {/*            color="primary"*/}
              {/*          />*/}
              {/*        }*/}
              {/*        label={option.variant}*/}
              {/*      />*/}



              {/*    )}*/}
              {/*  </FormGroup>*/}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}
                      color="primary"
                      autoFocus>
                {localStrings.cancel}
              </Button>
              <Button onClick={handleConfirmDialog}
                      color="primary"
                //disabled={checkBoxesSelected.length == 0}
              >
                {localStrings.confirm}
              </Button>
            </DialogActions>
          </Dialog>

          <StylesProvider generateClassName={generateClassName}>
            <div>
              <MaterialTable
                actions={getActions()}
                icons={tableIcons}
                options={{
                  //headerStyle: { fontSize: '35', position: 'sticky', top: 0 },
                  //showTitle: false,
                }}

                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} of {count}',
                    labelRowsSelect: localStrings.materialTable.labelRowsSelect,
                    labelRowsPerPage: localStrings.materialTable.labelRowsPerPage,
                    firstAriaLabel: localStrings.materialTable.firstAriaLabel,
                    firstTooltip: localStrings.materialTable.firstTooltip,
                    previousAriaLabel: localStrings.materialTable.previousAriaLabel,
                    previousTooltip: localStrings.materialTable.previousTooltip,
                    nextAriaLabel: localStrings.materialTable.nextAriaLabel,
                    nextTooltip: localStrings.materialTable.nextTooltip,
                    lastAriaLabel: localStrings.materialTable.lastAriaLabel,
                    lastTooltip: localStrings.materialTable.lastTooltip,
                  },
                  header: {
                    actions: localStrings.materialTable.actions
                  },
                  toolbar: {
                    searchTooltip: localStrings.materialTable.search,
                    searchPlaceholder: localStrings.materialTable.search,
                  },
                  body: {

                    addTooltip: localStrings.materialTable.add,
                    deleteTooltip: localStrings.materialTable.delete,
                    editTooltip: localStrings.materialTable.edit,
                    emptyDataSourceMessage: localStrings.materialTable.noRecords,
                    filterRow: {
                      filterTooltip: localStrings.materialTable.filterRow
                    },
                    editRow: {
                      cancelTooltip: localStrings.materialTable.cancel,
                      saveTooltip: localStrings.materialTable.save,
                      deleteText: localStrings.materialTable.deleteText
                    }
                  }
                }}
                components={
                  getToolbarActionFunction ?
                    {
                      Toolbar: props => (
                        <div>
                          <MTableToolbar {...props} />
                          <div style={{padding: '0px 10px'}}>
                            {getToolbarActionFunction(props).map (item =>
                              <Button
                                key={item}
                                color="secondary"
                                variant="contained"
                                onClick={async () => {
                                  //if (state.data.length == 0) {
                                  setOptionsDialog(item);
                                  setOpenDialog(true);
                                  setDialogContent(item.dialogContent)
                                  setDialogTitle(item.dialogTitle)
                                  //}
                                  //else {
                                  //  updateData(await refreshDataVariation())
                                  //}
                                }}
                              >
                                {state.data.length == 0 ? (item.titleNoData || item.title) : (item.title)}
                              </Button>

                            )}
                          </div>
                        </div>
                      ),
                    } : null
                }

                title={
                  <Typography variant="h5">
                    {titleForm}
                  </Typography>
                }
                columns={columns}
                //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                data={state.data}
                detailPanel = {detailPanel}


                editable={{
                  isEditable: rowData => rowData => notEditableRows ?!notEditableRows.includes(rowData.name) : true,
                  onRowAdd: addItemFunction ? newData =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        if (schemaValidation) {
                          try {
                            await schemaValidation.validate(newData);
                          }
                          catch (err) {
                            console.log(err);
                            reject();
                            if (err.errors) {
                              addError(err.errors.join().toString());
                            }
                            else {
                              addError(err.message);
                            }
                            return;
                          }
                        }

                        else if (validateData && !await validateData(newData)) {
                          reject();
                          return;
                        }

                        if (addItemFunction) {
                          try {
                            var res = await addItemFunction(newData);
                            if (res && res.data) {
                              setColumns(getColumns(res.data));
                            }
                            if (res && res.id) {
                              newData.internalId = res.id;
                            }
                            if (res && res.doNotProcess) {
                              resolve();
                              return;
                            }
                          }
                          catch (err) {
                            console.log(err);
                            addError(err.message);
                            reject();
                            return
                          }
                        }
                        resolve();
                        setState(prevState => {
                          const data = [...prevState.data];
                          data.push(newData);
                          return { ...prevState, data };
                        });
                        if (updateCallBack) {
                          updateCallBack();
                        }
                        enqueueSnackbarWithSound(enqueueSnackbar, playDone, playSoundIfActive, messageCreate);
                      }, TIMEOUT_EDIT);
                    }) : null,
                  onRowUpdate: updateItemFunction ?  (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        if (schemaValidation) {
                          try {
                            await schemaValidation.validate(newData);
                          }
                          catch (err) {
                            console.log(err);
                            reject();
                            if (err.errors) {
                              addError(err.errors.join().toString());
                            }
                            else {
                              addError(err.toString());
                            }

                            //
                            return;
                          }
                        }

                        else if (validateData && !await validateData(newData)) {
                          reject();
                          return;
                        }
                        resolve();
                        if (oldData) {

                          if (updateItemFunction) {
                            var copy = filterData(newData);
                            try {
                              let res = await updateItemFunction(copy);
                              if (res && res.data) {
                                setColumns(getColumns(res.data));
                              }
                              if (res && res.doNotProcess) {
                                resolve();
                                return;
                              }
                            }
                            catch (err) {
                              console.log(err);
                              addError(err.message);
                              reject();
                              return
                            }
                          }
                          resolve()
                          setState(prevState => {
                            const data = [...prevState.data];
                            data[data.indexOf(oldData)] = newData;
                            return { ...prevState, data };
                          });
                          if (updateCallBack) {
                            updateCallBack();
                          }
                          enqueueSnackbarWithSound(enqueueSnackbar, playDone, playSoundIfActive, messageUpdate);
                        }
                      }, TIMEOUT_EDIT);
                    }) : null,
                  onRowDelete: deleteItemFunction ? oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        if (deleteItemFunction) {
                          var copy = {...oldData}
                          delete copy["id"];
                          copy.id = oldData.internalId;
                          try {
                            let res = await deleteItemFunction(copy);
                            if (res && res.data) {
                              setColumns(getColumns(res.data));
                            }
                            if (res && res.doNotProcess) {
                              resolve();
                              return;
                            }
                            //deleteItemFunction()
                          }
                          catch (err) {
                            console.log(err);
                            addError(err.message);
                            reject();
                            return
                          }
                        }
                        resolve();
                        setState(prevState => {
                          const data = [...prevState.data];
                          data.splice(data.indexOf(oldData), 1);
                          return { ...prevState, data };
                        });
                        if (updateCallBack) {
                          updateCallBack();
                        }
                        enqueueSnackbarWithSound(enqueueSnackbar, playDelete, playSoundIfActive, messageDelete);
                      }, TIMEOUT_EDIT);
                    }) : null,
                }}
              />
            </div>
          </StylesProvider>

        </div>
      }
    </div>
  );
}
