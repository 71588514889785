import React from 'react';
import {Alert, AlertTitle, Typography} from "@mui/material";

function AlertHtmlLocal({severity, title, content, children, marginBottom}) {
    return (
        <>
        <Alert severity={severity} variant="outlined" title={title}>
            {content &&
            <Typography>
                {content}
            </Typography>
            }
            {children}
        </Alert>

        </>
    )
}

export default AlertHtmlLocal;

