import React, {useEffect, useState} from 'react';
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import localStrings from "../../../localStrings";
import {getProject, saveFormProject} from "../ProjectAddView/ProjectCreateUpdateForm";
import * as Yup from "yup";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider, FormControl,
  FormControlLabel,
  Grid, InputLabel,
  MenuItem, Select, Switch,
  TextField
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";

export const SEND_EVENT_ACTION = "sendEvent"
export const TRIGGER_WEB_HOOK_ACTION = "triggerWebHook"
export const GO_TO_HOME_PAGE_ACTION = "gotoHomePage"
export const GO_TO_PREVIOUS_PAGE_ACTION = "gotoPreviousPage"

export const DEFAULT_VOICE = "fr-FR-Wavenet-A";
export const DEFAULT_LANGUAGE = "fr-FR";
const voiceList = require("./voices.json")


const buildFormValues = (item) => {
  return {
    initialValues: {
      homePageUrl: item.homePageUrl || '',
      inactivityTouchScreenAction: item.inactivityTouchScreenAction || GO_TO_HOME_PAGE_ACTION,
      useInactivityTouchScreen: item.useInactivityTouchScreen || false,
      durationInactivityTouchScreen: item.durationInactivityTouchScreen || 120,

      useCamera: item.useCamera || false,
      cameraCancelPictureAction: item.cameraCancelPictureAction || GO_TO_PREVIOUS_PAGE_ACTION,

      cloudTextToSpeechVoiceName: item.cloudTextToSpeechVoiceName || DEFAULT_VOICE,
    },
    validationSchema: {
      durationInactivityTouchScreen: Yup.number()
        .integer(localStrings.check.minuteFormat)
        .min(1, localStrings.formatString(localStrings.check.mustBeGreaterThan, "1"))
    }
  };
}

const getCardContentForm = ({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                              touched,
                              values
                            }) => {

  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        md={12}
        xs={12}
      >

        <TextField
          inputProps={{
            autocomplete: "new-password"
          }}
          error={Boolean(touched.homePageUrl && errors.homePageUrl)}
          fullWidth
          helperText={touched.homePageUrl && errors.homePageUrl}
          label={localStrings.homePageUrl}
          name="homePageUrl"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.homePageUrl}
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        lg={12}
      >

        <Box mt={3}>
          <CardHeader title={localStrings.inactivityTouchScreen}/>
          <Divider/>
          <Card>
            <CardContent>
              <Box mt={3}>
              <FormControlLabel
                // className={classes.formControl}
                control={(
                  <Switch
                    checked={values.useInactivityTouchScreen}
                    edge="start"
                    name="direction"
                    onChange={() => {
                      //alert("change ");
                      setFieldValue('useInactivityTouchScreen', !values.useInactivityTouchScreen)
                    }}
                  />
                )}
                label={localStrings.useInactivityTouchScreen}
              />
              </Box>

              {values.useInactivityTouchScreen &&
                <>
                  <Box mt={3}>
                    <TextField
                      inputProps={{
                        autocomplete: "new-password"
                      }}
                      error={Boolean(touched.durationInactivityTouchScreen && errors.durationInactivityTouchScreen)}
                      fullWidth
                      helperText={touched.durationInactivityTouchScreen && errors.durationInactivityTouchScreen}
                      label={localStrings.durationInactivityTouchScreen}
                      name="durationInactivityTouchScreen"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.durationInactivityTouchScreen}
                      variant="outlined"
                    />
                  </Box>

                  <Box mt={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        htmlFor="outlined-age-native-simple">{localStrings.inactivityTouchScreenAction}</InputLabel>
                      <InputLabel>{localStrings.inactivityTouchScreenAction}</InputLabel>
                      <Select
                        value={values.inactivityTouchScreenAction}
                        label={localStrings.inactivityTouchScreenAction}
                        onChange={handleChange}
                        fullWidth
                        required
                        name="inactivityTouchScreenAction"
                        displayEmpty
                        variant="outlined"
                        onBlur={handleBlur}
                      >
                        <MenuItem value={GO_TO_HOME_PAGE_ACTION}>{localStrings.gotoHomePageAction}</MenuItem>
                        <MenuItem value={TRIGGER_WEB_HOOK_ACTION}>{localStrings.triggerInactivityWebhook}</MenuItem>
                        <MenuItem value={SEND_EVENT_ACTION}>{localStrings.sendEvent}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>


                </>
              }

            </CardContent>
          </Card>
        </Box>

        <Box mt={3}>
          <CardHeader title={localStrings.camera}/>
          <Divider/>
          <Card>
            <CardContent>
              <Box mt={3}>
                <FormControlLabel
                  // className={classes.formControl}
                  control={(
                    <Switch
                      checked={values.useCamera}
                      edge="start"
                      name="direction"
                      onChange={() => {
                        //alert("change ");
                        setFieldValue('useCamera', !values.useCamera)
                      }}
                    />
                  )}
                  label={localStrings.useCamera}
                />
              </Box>

              {values.useCamera &&
                <>
                  <Box mt={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        htmlFor="outlined-age-native-simple">{localStrings.cameraCancelPictureAction}</InputLabel>
                      <InputLabel>{localStrings.cameraCancelPictureAction}</InputLabel>
                      <Select
                        value={values.cameraCancelPictureAction}
                        label={localStrings.cameraCancelPictureAction}
                        onChange={handleChange}
                        fullWidth
                        required
                        name="cameraCancelPictureAction"
                        displayEmpty
                        variant="outlined"
                        onBlur={handleBlur}
                      >
                        <MenuItem value={GO_TO_PREVIOUS_PAGE_ACTION}>{localStrings.gotoPreviousPageAction}</MenuItem>
                        <MenuItem value={TRIGGER_WEB_HOOK_ACTION}>{localStrings.triggerCancelCameraWebhook}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </>
              }

            </CardContent>
          </Card>
        </Box>

        <Box mt={3}>
          <CardHeader title={localStrings.cloudTextToSpeech}/>
          <Divider/>
          <Card>
            <CardContent>
                <>
                  <Box mt={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        htmlFor="outlined-age-native-simple">{localStrings.cloudTextToSpeechVoiceName}</InputLabel>
                      <InputLabel>{localStrings.cloudTextToSpeechVoiceName}</InputLabel>
                      <Select
                        value={values.cloudTextToSpeechVoiceName}
                        label={localStrings.cloudTextToSpeechVoiceName}
                        onChange={handleChange}
                        fullWidth
                        required
                        name="cloudTextToSpeechVoiceName"
                        displayEmpty
                        variant="outlined"
                        onBlur={handleBlur}
                      >
                        {voiceList.map((item, key) =>
                          <MenuItem value={item}>{item}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </>

            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}


function Config({}) {
  let {projectId} = useParams();
  const { currentBrand } = useAuth();
  const navigate = useNavigate();
  return <GeneralEditor getItemFunction={() => getProject(currentBrand().id, projectId, MODE_EDIT)}
                        buildFormValues={buildFormValues}
                        getCardContentForm={getCardContentForm}
                        saveForm={(item, values) => saveFormProject(item, values, MODE_EDIT, currentBrand().id, navigate)}
                        applyButton={localStrings.update}
                        messageSuccess={localStrings.notif.projectUpdated}
                        titleForm={localStrings.config}
                        getPathToData={(result) => {
                          return  result.data.getProject;
                        }}
  />
}

export default Config;
