import React, {useState} from 'react';
import useAuth from "../../../hooks/useAuth";
import * as Yup from "yup";
import localStrings from "../../../localStrings";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {
  addDataTempBrandUserToCache, bulkDeleteTempBrandUserMutation,
  createTempBrandUserMutation, deleteTempBrandUserCache,
  getTempBrandUsersQuery
} from "../../../gql/tempBrandUserGql";
import {getProductUsersByBrandIdQuery, updateProductUserMutation} from "../../../gql/productUserGql";
import cloneDeep from "clone-deep";
import {Box, Card, CardContent, Chip, Grid, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {uuid} from "uuidv4";
import * as yup from "yup";
import GeneralResultWithEdition from "../../../components/CrudFrom/GeneralResultWithEdition";
import {roles} from "./brandUsers";

function PendingUserTable(props) {
  const {currentBrand} = useAuth();

  async function loadTempBrandUsers() {
    if (!currentBrand()) {
      return;
    }
    const resTempUsers = await executeQueryUtil(getTempBrandUsersQuery(currentBrand().id));
    return {data: resTempUsers.data.getTempBrandUsers}
  }


  const getColumns = () => {
    return(
      [
        {
          title: localStrings.email,
          field: 'email',
        }
      ]
    );
  }

  const addItem = async (item) => {
    const res = await executeMutationUtil(createTempBrandUserMutation({...item, brandId: currentBrand().id}));
    const resExisting = await executeQueryUtil(getProductUsersByBrandIdQuery(currentBrand().id))
    const existingUsers = resExisting.data?.getProductUsersByBrandId || [];
    if (existingUsers.find(u => u.email === item.email)) {
      throw new Error(localStrings.errorMessage.userAlreadyDefined);
      return;
    }

    await addDataTempBrandUserToCache(currentBrand().id, [res.data?.addTempBrandUser]);
    return {
      id: uuid,
      data: {
        ...res.data?.addTempBrandUser
      }}
  }

  const deleteItem = async (item) => {
    //alert("item " + JSON.stringify(item))
    await executeMutationUtil(bulkDeleteTempBrandUserMutation([item.internalId]));
    await deleteTempBrandUserCache(currentBrand().id, [item.internalId]);
  }

  let schema = yup.object().shape({
    // startDate: yup.string().required(localStrings.formatString(localStrings.check.fieldRequired, localStrings.startDate)),
    // endDate: yup.string()
    //   .required(localStrings.formatString(localStrings.check.fieldRequired, localStrings.startDate))
    //   .when('startDate', (valueStart, schema) => {
    //     return schema
    //       .test("testFuture", localStrings.check.endDateGreaterThanStart,
    //         value => parseInt(value) > parseInt(valueStart))
    //   })
  });

  return (
    <Box mt={3}>
      <GeneralResultWithEdition
        getItemsFunction={loadTempBrandUsers}
        addItemFunction={addItem}
        deleteItemFunction={deleteItem}
        schemaValidation={schema}
        getPathToData={(result) => result}
        titleForm={localStrings.pendingUsers}
        getColumns={getColumns}
        messageDelete={localStrings.notif.userPendingDeleted}
        messageCreate={localStrings.notif.userPendingCreated}
        messageUpdate={localStrings.notif.userPendingCreated}
      >
      </GeneralResultWithEdition>
    </Box>
  );
}

export default PendingUserTable;
