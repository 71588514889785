import React, { useEffect, useState } from 'react';
import { TableCell } from "@mui/material";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";
import useAppUtil from "../../../hooks/useAppUtil";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {getAllProjectsQuery, bulkDeleteProjectMutation, deleteProjectCache} from "../../../gql/projectGql"
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";

const Results = ({className, ...rest}) => {
  const { currentBrand } = useAuth();
  const { addWarning, addError} = useAppUtil();
  const [itemSource, setItemSource] = useState({});

  useEffect(() => {
    async function setItemSourceAsync() {
      let result = await getProjects();
      setItemSource(result);
    }
    setItemSourceAsync();
  }, [currentBrand]);


  const getProjects = async () => {
    return await executeQueryUtil(getAllProjectsQuery(currentBrand().id));
  }

  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.projectName}
        </TableCell>
      </React.Fragment>
    )
  }

  const getTableCell = (item) => {
    return(
      <React.Fragment>
        <TableCell>
          {item.name}
        </TableCell>
      </React.Fragment>
    )
  }

  const deleteProjects = async (ids, size) => {
    await executeMutationUtil(bulkDeleteProjectMutation(currentBrand().id, ids));
    await deleteProjectCache(currentBrand().id, ids);
    //await deletedDataToSiteUserCache(currentBrand().id, ids);
    let itemUpdate = await getProjects();
    setItemSource(itemUpdate)
    return true;
  }


  const sortOptions = [
    {
      value: 'tag|asc',
      label: localStrings.projectName
    },
  ];

  return (
    <>
      { Object.keys(itemSource).length > 0 ?
        <GeneralResult
          className={className}
          getPathToData={(result) => result.data.getProjects}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          getEditLink={(item) => "/app/projects/detail/" + item.id}
          deleteDialogText={localStrings.confirmMessage.deleteProject}
          deleteItemsFunction={deleteProjects}
          deleteItemNotifMessage={localStrings.notif.projectDeleted}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >
        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default Results;
