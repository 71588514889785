import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader} from "@mui/material";
import localStrings from "../../../localStrings";
import GeneralResultWithEdition from "../../../components/CrudFrom/GeneralResultWithEdition";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {useParams} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import * as yup from 'yup';
import {getBoardQuery, updateBoardMutation} from "../../../gql/boardGql";
const { uuid } = require('uuidv4');
const cloneDeep = require("clone-deep");

function ColumnDefinition() {
  let {boardId} = useParams();
  const {currentBrand} = useAuth();
  const [board, setBoard] = useState(null);

  const addItem = async (item) => {
    //alert("item " + JSON.stringify(item))
    let boardClone = cloneDeep(board);
    let uuidValue = uuid();
    if (!board.boardColumns) {
      boardClone.boardColumns = [{
        ...item,
        uuid: uuidValue
      }]
    }
    else {
      boardClone.boardColumns = [
        ...boardClone.boardColumns, {
          ...item,
          uuid: uuidValue
        }]
    }
    let result =  await executeMutationUtil(updateBoardMutation(currentBrand().id, boardClone));
    setBoard(result.data.updateBoard);
  }

  const updateItem = async (item) => {
    let boardClone = cloneDeep(board);
    let newColumns = [...board.boardColumns]
    let oldIndex = newColumns.findIndex(column => column.uuid == item.uuid)

    //let itemCopy = cloneDeep(item);
    newColumns.splice(oldIndex, 1, {...item})
    boardClone.boardColumns = newColumns;
    let result =  await executeMutationUtil(updateBoardMutation(currentBrand().id, boardClone));
    setBoard(result.data.updateBoard);
  }

  const deleteItem = async (item) => {
    let boardClone = cloneDeep(board);
    let newColumns = [...board.boardColumns].filter(column => column.uuid !== item.uuid)
    boardClone.boardColumns = newColumns;
    let result =  await executeMutationUtil(updateBoardMutation(currentBrand().id, boardClone));
    setBoard(result.data.updateBoard);
  }

  let schema = yup.object().shape({
    // startDate: yup.string().required(localStrings.formatString(localStrings.check.fieldRequired, localStrings.startDate)),
    // endDate: yup.string()
    //   .required(localStrings.formatString(localStrings.check.fieldRequired, localStrings.startDate))
    //   .when('startDate', (valueStart, schema) => {
    //     return schema
    //       .test("testFuture", localStrings.check.endDateGreaterThanStart,
    //         value => parseInt(value) > parseInt(valueStart))
    //   })
  });


  const getColumns = () => {
    return(
      [
        {
          title: localStrings.columnKey,
          field: 'key',
        },
        {
          title: localStrings.columnDisplay,
          field: 'display',
        },
      ]
    );
  }

  useEffect(() => {
    const loadBoard = async () => {
      const projectRes = await executeQueryUtil(getBoardQuery(currentBrand().id, boardId));
      const projectData = projectRes?.data?.getBoard;
      setBoard(projectData)
    }
    loadBoard();
  }, [])

  const updateAllItems = async (items) => {
    let copyItems = cloneDeep(items)
    copyItems.forEach(item => {
      delete item.tableData
      delete item.internalId
    })

    let boardUpdated = {...cloneDeep(board), boardColumns:copyItems};
    let result = await executeMutationUtil(updateBoardMutation(currentBrand().id, boardUpdated))
    setBoard(result.data.updateBoard);
    // setProductOptionsItem(result.data.updateProductOptionList);
  }

  return (
    <div style={{ width: '100%' }}>
      <Card>
        <CardContent>
          {board &&
            <GeneralResultWithEdition
              getItemsFunction={() => {
                return {
                  data : board?.boardColumns || []
                }
             }}
              reorder
              updateAllItemsFunction={updateAllItems}
              matchingFunc={(item, rowData) => item.uuid == rowData.uuid}
              addItemFunction={addItem}
              updateItemFunction={updateItem}
              deleteItemFunction={deleteItem}
              schemaValidation={schema}
              getPathToData={(result) => result}
              titleForm={board.name}
              getColumns={getColumns}
              //notEditableRows={ ["dataValue"] }
              messageDelete={localStrings.notif.boardColumnDeleted}
              messageUpdate={localStrings.notif.boardColumnUpdated}
              messageCreate={localStrings.notif.boardColumnCreated}
            >
            </GeneralResultWithEdition>
          }
        </CardContent>
      </Card>
    </div>
  );
}

export default ColumnDefinition;
