import React from 'react';
import {FormControlLabel, Grid, Switch, TextField, Typography} from "@mui/material";
import * as Yup from 'yup';
import useAuth from "../../../hooks/useAuth";
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {addDataProjectToCache, getProjectQuery} from "../../../gql/projectGql";
import localStrings from "../../../localStrings";
import {useNavigate} from "react-router";
import {addDataBrandToCache, createBrandMutation, updateBrandMutation} from "../../../gql/BrandGql";
import {updateProductUserMutation} from "../../../gql/productUserGql";
import {updateBrandAdminMutation} from "../../../gql/BrandAdminGql";
import {uuid} from "uuidv4";
import TextFieldPassword from "../../../components/TextFieldPassword";
import TextFieldWithCopy from "../../../components/TextFieldWithCopy";
import useAppUtil from "../../../hooks/useAppUtil";

export const getProject = async (brandId, projectId, modeEdit) => {
  if (modeEdit == MODE_CREATE) {
    return null;
  }
  if (!modeEdit || modeEdit == MODE_EDIT) {
    var items =  await executeQueryUtil(getProjectQuery(brandId, projectId));
    return items;
  }
  return null;
}

const BrandCreateUpdateForm = ({className, modeEdit}) => {
  const { currentBrand, setBrand, userInDb, superAdmin } = useAuth();
  const { addError } = useAppUtil();
  const navigate = useNavigate();

  function getMutationFunction() {
    if (superAdmin) {
      return updateBrandAdminMutation
    }
    return updateBrandMutation;
  }

  const saveForm = async (values) => {

    if (!superAdmin && modeEdit === MODE_CREATE) {
      addError(localStrings.error.needSuperUser);
      return;
    }

    if (!values.authKey) {
      values.authKey = uuid();
    }
    delete values.brandId;
    if (!superAdmin || modeEdit === MODE_CREATE) {
      delete values.credit;
      delete values.minimalCreditToLock;
      delete values.blockWhenInsufficientCredit;
    }
    if (!currentBrand() || modeEdit == MODE_CREATE) {
      let res = await executeMutationUtil(createBrandMutation(values));
      await setBrand(res.data.addBrand);
      if (!currentBrand()) {
        await executeMutationUtil(updateProductUserMutation({
          ...userInDb,
          brandId: res.data.addBrand.id
        }));
        await setBrand(res.data.addBrand);
      }
      if (modeEdit == MODE_CREATE) {
        await addDataBrandToCache([res.data?.addBrand]);
      }
      navigate('/app/dashboard', { replace: true });
    }
    else {
      let res = await executeMutationUtil(getMutationFunction()(
        (superAdmin ?
            {
              ...values,
              id: currentBrand().id,
              minimalCreditToLock: values.minimalCreditToLock ? parseInt(values.minimalCreditToLock) : null,
              credit: values.credit ? parseInt(values.credit) : null,
            }
            :
            {
              ...values,
              id: currentBrand().id,
            }
        )

      ))
      await setBrand(superAdmin ? res.data.updateBrandAdmin : res.data.updateBrand)
      navigate('/app/dashboard', { replace: true });
    }

  }

  const DEFAULT_PROJECT_ID = "totemsystem-5889b";

  const buildFormValues = (item) => {
    return {
      initialValues: {
        brandName: item.brandName || '',
        brandId: currentBrand()?.id,
        contactEmail: item.contactEmail || '',
        credit: item.credit || '0',
        minimalCreditToLock: item.minimalCreditToLock || '0',
        blockWhenInsufficientCredit: item.blockWhenInsufficientCredit || false,
        loggingProjectId: item.loggingProjectId || '',
        authKey: item.authKey || '',
      },
      validationSchema: {
        brandName: Yup.string().max(255).required(localStrings.check.fieldRequired),
        contactEmail: Yup.string().email(localStrings.check.badEmailFormat).required(localStrings.check.fieldRequired),
      }
    };
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.brandName && errors.brandName)}
            fullWidth
            helperText={touched.brandName && errors.brandName}
            label={localStrings.brandName}
            name="brandName"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.brandName}
            variant="outlined"
          />
        </Grid>

        {currentBrand() &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextFieldWithCopy
              inputProps={{
                autocomplete: "new-password"
              }}
              error={Boolean(touched.brandId && errors.brandId)}
              fullWidth
              helperText={touched.brandId && errors.brandId}
              label={localStrings.brandId}
              name="brandId"
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
              value={values.brandId}
              variant="outlined"
            />
          </Grid>
        }

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.contactEmail && errors.contactEmail)}
            fullWidth
            helperText={touched.contactEmail && errors.contactEmail}
            label={localStrings.contactEmail}
            name="contactEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.contactEmail}
            variant="outlined"
          />
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.loggingProjectId && errors.loggingProjectId)}
            fullWidth
            helperText={touched.loggingProjectId && errors.loggingProjectId}
            label={localStrings.loggingProjectId}
            name="loggingProjectId"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.loggingProjectId}
            variant="outlined"
          />
        </Grid>

        {currentBrand() &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextFieldPassword
              inputProps={{
                autocomplete: "new-password"
              }}
              error={Boolean(touched.authKey && errors.authKey)}
              fullWidth
              helperText={touched.authKey && errors.authKey}
              label={localStrings.authKey}
              name="authKey"
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
              value={values.authKey}
              variant="outlined"
            />
          </Grid>
        }



        {superAdmin && modeEdit !== MODE_CREATE &&
          <>
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography>{localStrings.adminSection}</Typography>
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                inputProps={{
                  autocomplete:"new-password"
                }}
                error={Boolean(touched.credit && errors.credit)}
                fullWidth
                helperText={touched.credit && errors.credit}
                label={localStrings.kioskCredits}
                name="credit"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.credit}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                inputProps={{
                  autocomplete:"new-password"
                }}
                error={Boolean(touched.minimalCreditToLock && errors.minimalCreditToLock)}
                fullWidth
                helperText={touched.minimalCreditToLock && errors.minimalCreditToLock}
                label={localStrings.minimalCreditToLock}
                name="minimalCreditToLock"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.minimalCreditToLock}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
                // className={classes.formControl}
                control={(
                  <Switch
                    checked={values.blockWhenInsufficientCredit}
                    edge="start"
                    name="direction"
                    onChange={() => {
                      //alert("change ");
                      setFieldValue('blockWhenInsufficientCredit', !values.blockWhenInsufficientCredit)
                    }}
                  />
                )}
                label={localStrings.blockWhenInsufficientCredit}
              />
            </Grid>

          </>
        }
      </Grid>
    );
  }




  return(
    <GeneralEditor getItemFunction={() => {
      if (modeEdit == MODE_CREATE) {
        return null;
      }
      return currentBrand()
    }}
                   buildFormValues={buildFormValues}
                   getCardContentForm={getCardContentForm}
                   saveForm={(item, values) => {
                     saveForm(values)
                   }}
                   applyButton={(!currentBrand() || modeEdit == MODE_CREATE) ? localStrings.create : localStrings.update}
                   messageSuccess={(!currentBrand() || modeEdit == MODE_CREATE) ?
                     localStrings.notif.brandCreated : localStrings.notif.brandUpdated}
                   titleForm={(!currentBrand() || modeEdit == MODE_CREATE) ? localStrings.createBrand : localStrings.updateBrand}
                   getPathToData={(result) => {
                     return result;
                   }}
    />
  )
};

export default BrandCreateUpdateForm;
