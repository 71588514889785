import React, {useEffect, useState} from 'react';
import {Box, Button, SvgIcon, TableCell} from "@mui/material";
import {bulkDeleteFunctionMutation, deleteFunctionCache, getAllFunctionsQuery} from "../../../../../gql/functionGql";
import {useParams} from "react-router-dom";
import localStrings from "../../../../../localStrings";
import {executeMutationUtil, executeQueryUtil} from "../../../../../utils/gqlUtil";
import ClipLoaderComponent from "../../../../../components/ClipLoaderComponent";
import GeneralResult from "../../../../../components/CrudFrom/GeneralResult";
import useAuth from "../../../../../hooks/useAuth";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const FunctionResults = ({setSelectedFunction, setDetailNavigate}) => {

  let {projectId} = useParams();
  const { currentBrand } = useAuth();
  const [itemSource, setItemSource] = useState({});

  async function setItemSourceAsync() {
    let result = await getFunctions();
    setItemSource(result);
  }

  useEffect(() => {
    setItemSourceAsync();
  }, [currentBrand]);


  const getFunctions = async () => {
    return await executeQueryUtil(getAllFunctionsQuery(currentBrand().id, projectId));
  }

  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.functionName}
        </TableCell>
      </React.Fragment>
    )
  }

  const getTableCell = (item) => {

    return(
      <React.Fragment>
        <TableCell>
          {item.name}
        </TableCell>
      </React.Fragment>
    )
  }

  const removeFunction = async (ids, size) => {
    await executeMutationUtil(bulkDeleteFunctionMutation(currentBrand().id, projectId, ids));
    await deleteFunctionCache(currentBrand().id, projectId, ids);
    await setItemSourceAsync();
    return true;
  }


  const sortOptions = [
    {
      value: 'name|asc',
      label: localStrings.deviceName
    },
  ];

  return (
    <>
      <div style={{width: '100%'}}>
        <Box
          sx={{
            mr: 2,
            mt: 2,
            ml: 2,
            mb: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSelectedFunction(null);
              setDetailNavigate(true);
            }}
          >
            {localStrings.createFunction}
          </Button>
        </Box>
      </div>

      { Object.keys(itemSource).length > 0 ?
        <GeneralResult
          getPathToData={(result) => result.data.getFunctions}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          editFunc={(item) => {
            setSelectedFunction(item);
            setDetailNavigate(true);
          }}
          deleteDialogText={localStrings.confirmMessage.removeDevice}
          deleteItemsFunction={removeFunction}
          deleteItemNotifMessage={localStrings.notif.functionRemoved}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >

        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default FunctionResults;
