import React from 'react';
import {Box, Container} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import StripePayAccountCreateUpdateForm from "./StripePayAccountCreateUpdateForm";
import localStrings from "../../../localStrings";
import {MODE_CREATE} from "../../../components/CrudFrom/GeneralEditor";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const BoardAddView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title={localStrings.createProject}
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <StripePayAccountCreateUpdateForm modeEdit={MODE_CREATE}/>
        </Box>
      </Container>
    </Page>
  );
};

export default BoardAddView;
