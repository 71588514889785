import {gql} from "@apollo/client";

const common = `
  version
`

export const getSystemVersionQuery = () => {
  var debug = `
    query {
      getSystemVersion {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getSystemVersion {
        ${common}
      }
    }
  `;
}


