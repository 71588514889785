const firebaseConfig = {
  apiKey: "AIzaSyAAZxxjYpWTxb2biMDnIkdbz49JRtErgv4",
  authDomain: "totemsystem-5889b.firebaseapp.com",
  projectId: "totemsystem-5889b",
  storageBucket: "totemsystem-5889b.appspot.com",
  messagingSenderId: "576881927604",
  appId: "1:576881927604:web:263102ffff2ad35baa3ee1",
  measurementId: "G-WY0YDBV59D"
};

export default firebaseConfig;


