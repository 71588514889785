import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Breadcrumbs, Button, Container, Grid, Link, SvgIcon, Typography} from "@mui/material";
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <div style={{ width: '100%' }}>
        <Container>
        <Box
          sx={{
            mt: 2,
            ml: 2,
            display: 'flex',
            // justifyContent: 'flex-end',
            width: '100%'
          }}
        >
          <Typography
            sx={{ flexGrow: 1 }}
            variant="body1"
            color="textPrimary"
          >
            {localStrings.deviceList}
          </Typography>
          <RouterLink to="/app/devices/add">
            <Button
              color="primary"
              variant="contained"
            >
              {localStrings.addDevice}
            </Button>
          </RouterLink>
        </Box>
        </Container>
      </div>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
