import React, {useState} from "react";
import "./dropZoneStyle.css";

import Dropzone from "react-dropzone";
import localStrings from "../localStrings";
import {Box, Button, Typography} from "@mui/material";

export default function DropZoneFile({uploadCallBack, downloadLink}) {
  const [fileNames, setFileNames] = useState([]);
  const [file, setFile] = useState([]);
  const handleDrop = acceptedFiles => {
    setFile(acceptedFiles[0]);
    setFileNames(acceptedFiles.map(file => file.name));
  }

  return (
    <div style={{width: "100%"}}>
      <Dropzone
        onDrop={handleDrop}
        maxFiles={1}
        accept={{
          'application/zip': ['.zip']
        }}
      >
        {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject
          }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
              ? "reject"
              : "";

          return (
            <div
              {...getRootProps({
                className: `dropzone ${additionalClass}`
              })}
            >
              <input {...getInputProps()} />
              <span>{isDragActive ? "📂" : "📁"}</span>
              <p>{localStrings.dragAndDropZipFile}</p>
            </div>
          );
        }}
      </Dropzone>
      <Box>
        <Typography>{localStrings.files}</Typography>
        <ul>
          {fileNames.map(fileName => (
            <li key={fileName}><Typography>{fileName}</Typography></li>
          ))}
        </ul>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          p: 1,
          m: 1,
        }}
      >
        <Button
          sx={{margin: 1}}
          onClick={() => {
            if (uploadCallBack) {
              uploadCallBack(file)
            }

          }}
          color="primary"
          variant="contained">
          {localStrings.upload}
        </Button>

        {downloadLink &&
          <a href={downloadLink}>
          <Button
            sx={{margin: 1}}
            // onClick={() => {
            //   if (downloadCallBack) {
            //     downloadCallBack();
            //   }
            // }}
            color="primary"
            variant="contained">
            {localStrings.download}
          </Button>
          </a>
        }
      </Box>
    </div>
  );
}
