import React from 'react';
import {Card, CardContent} from "@mui/material";
import PendingUserTable from "./PendingUserTable";
import ExistingUserTable from "./ExistingUserTable";

export const ROLE_ADMIN = "admin";
export const ROLE_USER = "user";

export const roles = [ROLE_ADMIN, ROLE_USER]


function BrandUsers(props) {
  return (
    <>
      <div style={{ width: '100%' }}>
        <Card>
          <CardContent>
            <PendingUserTable/>
            <ExistingUserTable/>
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default BrandUsers;
