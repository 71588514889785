import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import localStrings from "../../../localStrings";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {useParams} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {getBoardQuery, updateBoardMutation} from "../../../gql/boardGql";
import GeneralEditor from "../../../components/CrudFrom/GeneralEditor";
import AceEditor from "react-ace";
import "brace/mode/css";
import "brace/theme/monokai";

const { uuid } = require('uuidv4');
const cloneDeep = require("clone-deep");

function CssDefinition() {
  let {boardId} = useParams();
  const {currentBrand} = useAuth();
  const [board, setBoard] = useState(null);

  useEffect(() => {
    const loadBoard = async () => {
      const projectRes = await executeQueryUtil(getBoardQuery(currentBrand().id, boardId));
      const data = projectRes?.data?.getBoard;
      setBoard(data)
    }
    loadBoard();
  }, [])

  const buildFormValues = (item) => {
    return {
      initialValues: {
        cssDefinition: item.cssDefinition || '',
      },
    };
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {

    return (
      <Grid
        container
        spacing={4}
      >

          <Grid
            item
            md={12}
            xs={12}
          >
            <AceEditor
              //height="100%"
              style={{width: "100%"}}
              placeholder={localStrings.yourCodeHere}
              mode="css"
              theme="monokai"
              onChange={(code) => {
                setFieldValue('cssDefinition', code)
              }}
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={values.cssDefinition}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}/>

          </Grid>
        }
      </Grid>
    );
  }

  async function saveForm(item, values) {
    let data;
    data = {...item, ...values};
    await executeMutationUtil(updateBoardMutation(currentBrand().id, data));
    return data;
  }

  return (
    <div style={{ width: '100%' }}>
      {board &&
        <GeneralEditor
          getItemFunction={() => board}
          buildFormValues={buildFormValues}
          getCardContentForm={getCardContentForm}
          saveForm={saveForm}
          applyButton={localStrings.update}
          messageSuccess={localStrings.notif.cssStyleUpdated}
          titleForm={localStrings.customCssStyle}
          getPathToData={(result) => {
            return result;
          }}>
        </GeneralEditor>
      }
    </div>
  );
}

export default CssDefinition;
