import React, {useState} from 'react';
import {Box, Container, Tab, Tabs} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import DeviceCreateUpdateForm from "../DeviceAddView/DeviceCreateUpdateForm";
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";
import {MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {getUriSetting, saveUriSetting, SELECTED_TAB} from "../../../utils/optionsUriLocalStorage";
import SendCommands from "./SendCommands";
import PaymentTerminal from "./PaymentTerminal";

import SendEvents from "./SendEvents";
import Logs from "./Logs";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));


const DeviceDetailsView = () => {
  const [currentTab, setCurrentTab] = useState(getUriSetting(SELECTED_TAB) || 'general');
  const classes = useStyles();
  const {currentBrand} = useAuth()
  const handleTabsChange = (event, value) => {
    saveUriSetting(SELECTED_TAB, value)
    setCurrentTab(value);
  };

  return (
    <Page
      className={classes.root}
      title={localStrings.device}
    >
      {/*{brandGetEnoughCredits (currentBrand()) ?*/}
        <Container maxWidth="lg">
          <Header />
          <Box mt={3}>
            <Box mt={3}>
              <Tabs
                onChange={handleTabsChange}
                scrollButtons="auto"
                value={currentTab}
                textColor="secondary"
                variant="scrollable"
              >
                <Tab label={localStrings.general} value="general" />
                <Tab label={localStrings.commands} value="commands" />
                <Tab label={localStrings.events} value="events" />
                <Tab label={localStrings.devicelogs} value="devicelogs" />
                <Tab label={localStrings.stripePaymentTerminal} value="stripePaymentTerminal" />

              </Tabs>
              {currentTab === 'general' && <DeviceCreateUpdateForm modeEdit={MODE_EDIT}/>}
              {currentTab === 'commands' && <SendCommands/>}
              {currentTab === 'stripePaymentTerminal' && <PaymentTerminal/>}
              {currentTab === 'events' && <SendEvents/>}
              {currentTab === 'devicelogs' && <Logs/>}
            </Box>
          </Box>
        </Container>
    </Page>
  );
};

export default DeviceDetailsView;
