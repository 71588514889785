import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
  codeContent
`

export const getAllFunctionsQuery = (brandId, projectId) => {
  var debug = `
    query {
      getFunctions(brandId: "${brandId}", projectId:"${projectId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getFunctions(brandId: "${brandId}", projectId:"${projectId}") {
        ${common}
      }
    }
  `;
}


export const getFunctionQuery = (brandId, projectId, functionId) => {
  var debug = `
    query {
      getFunction(brandId: "${brandId}", projectId: "${projectId}", functionId: "${functionId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
       getFunction(brandId: "${brandId}", projectId: "${projectId}", functionId: "${functionId}") {
        ${common}
      }
    }
  `;
}

export const bulkDeleteFunctionMutation = (brandId, projectId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteFunction(brandId: "${brandId}", projectId: "${projectId}", functionIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteFunction(brandId: "${brandId}", projectId: "${projectId}", functionIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const createFunctionMutation = (brandId, projectId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
    [] );

  var debug = `
    mutation {
      addFunction(brandId: "${brandId}", projectId: "${projectId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addFunction(brandId: "${brandId}", projectId: "${projectId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const updateFunctionMutation = (brandId, projectId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"],
    [] );

  var debug = `
    mutation {
      updateFunction(brandId: "${brandId}", projectId: "${projectId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateFunction(brandId: "${brandId}", projectId: "${projectId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const deleteFunctionCache = async (brandId, projectId, ids) => {
  const query = getAllFunctionsQuery(brandId, projectId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getFunctions.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getFunctions: [...newData]
      },
    });
  }
}

export const addDataFunctionToCache = async (brandId, projectId, datas) => {
  if (!datas) {
    return
  }
  const query = getAllFunctionsQuery(brandId, projectId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getFunctions: [...data.data.getFunctions, ...datas]
      },
    });
  }
}

