
export const defaultCodeHead = `function buildCommandJson(commandType, commandParam, commandContext) {
  //call me to build command
}

const axios = require('axios');
const HTMLParser = require('node-html-parser');
const { Http } = require('@status/codes');

const commandTypeOpenUrl ="openurl";
const commandTypeReboot="reboot";
const commandTypeDeployWebSite="deploywebsite";
const commandTypeUpdate="update";
const commandTypePrintTicket="printticket";
const commandTypeSpeak="speak";

app.post('/:deviceId', async (req, res) => {
`

export const defaultCodeMain = `
const deviceId = req.params.deviceId;
const body=req.body

// Start to write you code here to implement your
// cloud function function

res.status(Http.Ok)
res.json([]);
`

export const defaultCodeTail = `
})`
