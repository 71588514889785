import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import localStrings from "../localStrings";
import {Alert, Box, Button, Container, Grid, Link, Stack, TextField, Typography} from "@mui/material";
import GoogleIcon from "../icons/Google";
import {LoadingButton} from "@mui/lab";
import {useFormik} from "formik";
import * as Yup from "yup";
import useAuth from "../hooks/useAuth";
import {fillUserContext} from "./Login";
import {loginRoute} from "../routes";

function ResetPassword() {

  const [resetting, setResetting] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [linkSent, setLinkSent] = useState(false);
  const [email, setEmail] = useState(false);
  const {sendPasswordResetEmailContext} = useAuth();

  async function sendresetPasswordLink(values) {
    try {
      setResetting(true);
      const user = await sendPasswordResetEmailContext(values.email);
      setLinkSent(true);
      setEmail(values.email)
      //await fillUserContext(user, setAuthenticated, setEmailVerified, setContextDbUser)
    }
    catch (err) {
      setErrorDisplay(err.message)
      console.log(err)
    }
    finally {
      setResetting(false);
    }

  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),
    onSubmit: sendresetPasswordLink
  });

  return (
    <>
      <Helmet>
        <title>{localStrings.login}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}
      >
        <Container maxWidth="sm">

          {linkSent &&
            <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
              <Alert severity="success">{localStrings.formatString(localStrings.linkSent, email)}</Alert>
            </Stack>
          }

          {errorDisplay &&
            <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
              <Alert severity="error">{errorDisplay}</Alert>
            </Stack>
          }

          {!linkSent &&
            <form onSubmit={formik.handleSubmit}>
            <Box sx={{my: 3}}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                {localStrings.resetPassword}
              </Typography>
            </Box>



            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />

            <Box sx={{py: 2}}>
              <LoadingButton
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={resetting}
                loadingPosition="end"
              >
                {localStrings.sendResetPassword}
              </LoadingButton>
            </Box>


          </form>
          }

          <Typography
            color="textSecondary"
            variant="body2"
          >
            {localStrings.haveAnAccount + ' '}
            <Link
              variant="subtitle2"
              underline="hover"
              href={"/" + loginRoute}
            >
              {localStrings.signIn}
            </Link>
          </Typography>
        </Container>
      </Box>

    </>
  );
}

export default ResetPassword;
