import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  brandName
  contactEmail
  minimalCreditToLock
  blockWhenInsufficientCredit
  credit
  authKey
  loggingProjectId
  enableLifeCheckWebHook
  lifeCheckWebHookUrl
`

export const getBrandAdminQuery = (brandId) => {
  var debug = `
    query {
      getBrandAdmin(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getBrandAdmin(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}

export const updateBrandAdminMutation = (data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      updateBrandAdmin(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateBrandAdmin(data: ${dataString}) {
        ${common}
    }
  }
  `;
}


