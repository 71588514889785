import React from 'react';
import { Playground, store } from 'graphql-playground-react'
import { Provider } from 'react-redux'
const config = require('../conf/config.json')

const configGraph = {
  projects: {
    default: {
      extensions: {
        endpoints: {
          default: {
            url: config.graphQlUrl,
            headers: {
              Authorization:
                'Bearer ' + localStorage.getItem('authToken'),
            },
          },
        },
      },
    },
  }
}

const GraphQl = () => {
  return (
    <div>
      {process.env.NODE_ENV && process.env.NODE_ENV === 'development' &&
        <Provider store={store}>
          <Playground
            config={configGraph}
          />
        </Provider>
      }
    </div>
  );
};

export default GraphQl;
