import React from 'react';
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import localStrings from "../../../localStrings";
import {getProject, saveFormProject} from "../ProjectAddView/ProjectCreateUpdateForm";
import * as Yup from "yup";
import {Box, Card, CardContent, CardHeader, Divider, Grid, TextField} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";

const buildFormValues = (item) => {
  return {
    initialValues: {
      webHookEventUrl: item.webHookEventUrl || '',
    },
  };
}

const getCardContentForm = ({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                              touched,
                              values
                            }) => {
  return (
    <Grid
      container
      spacing={4}
    >
      <Grid
        item
        md={12}
        xs={12}
      >

              <TextField
                inputProps={{
                  autocomplete:"new-password"
                }}
                error={Boolean(touched.webHookEventUrl && errors.webHookEventUrl)}
                fullWidth
                helperText={touched.webHookEventUrl && errors.webHookEventUrl}
                label={localStrings.webHookEventUrl}
                name="webHookEventUrl"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.webHookEventUrl}
                variant="outlined"
              />
      </Grid>
    </Grid>
  );
}

function Webhook({}) {
  let {projectId} = useParams();
  const { currentBrand } = useAuth();
  const navigate = useNavigate();
  return <GeneralEditor getItemFunction={() => getProject(currentBrand().id, projectId, MODE_EDIT)}
                        buildFormValues={buildFormValues}
                        getCardContentForm={getCardContentForm}
                        saveForm={(item, values) => saveFormProject(item, values, MODE_EDIT, currentBrand().id, navigate)}
                        applyButton={localStrings.update}
                        messageSuccess={localStrings.notif.projectUpdated}
                        titleForm={localStrings.webhook}
                        getPathToData={(result) => {
                          return  result.data.getProject;
                        }}
  />
}

export default Webhook;
