import React from 'react';
import { SvgIcon } from "@mui/material";
import { ShoppingBag as ShoppingBagIcon } from 'react-feather';
import localStrings from "../localStrings";
import ListWithSearch from "./ListWithSearch";
import {executeQueryUtil} from "../utils/gqlUtil";
import {getAllBrandsQuery, getBrandQuery} from "../gql/BrandGql";
import useAuth from "../hooks/useAuth";
import {getBrandAdminQuery} from "../gql/BrandAdminGql";

const SwitchBrand = () => {
  const { setBrand, currentBrand, userInDb, superAdmin } = useAuth();
  const getBrands = async () => {
    if (superAdmin) {
      let result = await executeQueryUtil(getAllBrandsQuery());
      return result.data.getBrands;
    }
    else {
      let allBrand = [currentBrand()];
      let allowedBrandIds = userInDb.allowedBrandIds;
      for (let i = 0; i < allowedBrandIds.length; i++) {
        const allowedBrandId = allowedBrandIds[i];
        let resBrand = await executeQueryUtil(getBrandQuery(allowedBrandId));
        let brandToAdd = resBrand?.data?.getBrand;
        if (brandToAdd && brandToAdd.id != currentBrand()?.id) {
          allBrand.push(brandToAdd);
        }
      }
      return allBrand;
    }
    return [];
  }

  const switchBrand = async (brand) => {
    let brandAdmin = await executeQueryUtil(getBrandAdminQuery(brand.id));
    await setBrand(brandAdmin.data.getBrandAdmin);
  }

  const getMessageDone = (brand) => {
    return localStrings.formatString(localStrings.notif.switchBrand, brand.brandName);
  }

  const getIconTop = () => {
    return <SvgIcon fontSize="small">
      <ShoppingBagIcon />
    </SvgIcon>;
  }

  return (
    <ListWithSearch
      getItemsFunc={getBrands}
      getItemId={item => item.id}
      getTextDisplayItem={item => item.brandName}
      selectItem={switchBrand}
      getMessageDone={getMessageDone}
      //redirectPageUriProp="/app/account"
      title={localStrings.switchBrand}
      searchTitle={localStrings.searchBrand}
      getIconTop={getIconTop}
      toolTip={localStrings.switchBrand}
      activeItem={(item) =>
        (currentBrand() == null && item.id == "-1") ||
        (currentBrand() != null &&
          (item.id == currentBrand().id))}
    />
  );
};

export default SwitchBrand;
