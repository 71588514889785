import React from 'react';
import { Avatar } from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '3',
    maxHeight: theme.spacing(10),
    //backgroundColor: 'red',
    color: theme.palette.text.primary,
  },
  text: {
    backgroundColor: 'white',
  },

  large: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    backgroundColor: 'white',
    margin: 10,
    padding: 10,
    position: 'relative',
    top: -theme.spacing(6)
  },
}));

function ModalPresenter({content}) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.root}>
        <Avatar src="/static/icons/achieving-goals.svg" className={classes.large} />
      </div>

      <div className={classes.text}>s
        <p>{content}</p>
      </div>
    </div>
  );
}

export default ModalPresenter;
