import React, {useEffect, useState} from 'react';
import {TableCell} from "@mui/material";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {getAllProjectsQuery} from "../../../gql/projectGql"
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";
import {
  deleteDeviceByBrandIdCache,
  getAllDevicesByBrandIdQuery,
  getDeviceQuery,
  updateDeviceMutation
} from "../../../gql/DeviceGql";
import cloneDeep from "clone-deep";
import {useNavigate} from "react-router";
import {devicesRoute, getAppRoute} from "../../../routes";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {updateHealthCheckMutation} from "../../../gql/BrandGql";
import {BRAND_COLLECTION, DEVICE_HEALTH_COLLECTION} from "../../../utils/constants";
import {collection, getFirestore} from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import moment from "moment";
import DoneIcon from '@mui/icons-material/Done';
import {Link as RouterLink} from 'react-router-dom';

const timeoutRefreshStatus = 60000;

const Results = ({className}) => {

  const {currentBrand} = useAuth();
  const db = getFirestore();

  const refCollection =  collection(db, BRAND_COLLECTION
    + "/" + currentBrand().id + "/" + DEVICE_HEALTH_COLLECTION);

  const [devicesHealth, devicesHealthLoading, devicesHealthError] = useCollectionData(
    refCollection
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  const [itemSource, setItemSource] = useState({});
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    async function setItemSourceAsync() {
      let result = await getDevices();
      setItemSource(result);
      let resultProjects = await getProjects();
      setProjects(resultProjects?.data?.getProjects || []);
    }
    setItemSourceAsync();
  }, [currentBrand]);

  async function getHealthStatus() {
    await executeMutationUtil(updateHealthCheckMutation(currentBrand().id));
  }

  useEffect(() => {
    getHealthStatus();
    // const interval = setInterval(() => {
    //   getHealthStatus();
    // }, timeoutRefreshStatus);
    // return () => clearInterval(interval);
  }, []);

  const getProjects = async () => {
    return await executeQueryUtil(getAllProjectsQuery(currentBrand().id));
  }

  const getDevices = async () => {
    return await executeQueryUtil(getAllDevicesByBrandIdQuery(currentBrand().id));
  }

  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.deviceId}
        </TableCell>
        <TableCell>
          {localStrings.deviceName}
        </TableCell>
        <TableCell>
          {localStrings.status}
        </TableCell>
        <TableCell>
          {localStrings.ipAdress}
        </TableCell>
        <TableCell>
          {localStrings.liteDevice}
        </TableCell>
        <TableCell>
          {localStrings.projectName}
        </TableCell>
        <TableCell>
          {localStrings.rdpAddress}
        </TableCell>
      </React.Fragment>
    )
  }

  function getStatusColor(item) {
    var alive = item.alive
    const nowMs = moment().valueOf()
    //let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id && nowMs - h.updateDate > 2000);
    let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id);
    if (deviceHealthMatching) {
      alive = deviceHealthMatching.alive;
    }
    if (alive) {
      return 'green';
    }
    return 'red';
  }

  function getIp(item) {
    let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id);
    return deviceHealthMatching?.ip || '-';
  }

  const getTableCell = (item) => {

    return(
      <React.Fragment>
        <TableCell>
          {item.id}
        </TableCell>
        <TableCell>
          {item.name}
        </TableCell>
        <TableCell>
          <CheckCircleOutlineIcon style={{ fill: getStatusColor(item) }}/>
        </TableCell>
        <TableCell>
          {getIp(item)}
        </TableCell>
        <TableCell>
          {item.lite &&
            <DoneIcon/>
          }
        </TableCell>
        <TableCell>
          {projects.find(p => p.id === item.projectId)?.name || ""}
        </TableCell>
        <TableCell>
          {item.rdpAddress}
        </TableCell>
      </React.Fragment>
    )
  }

  const removeDevices = async (ids, size) => {
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const res = await executeQueryUtil(getDeviceQuery(id));
      if (res?.data?.getDevice) {
        const data = cloneDeep(res.data.getDevice)
        delete data.brandId;
        await executeMutationUtil(updateDeviceMutation(data));
      }

    }

    // await executeMutationUtil(bulkDeleteDeviceMutation(currentBrand().id, ids));
    await deleteDeviceByBrandIdCache(currentBrand().id, ids);
    navigate(getAppRoute(devicesRoute))
    return true;
  }


  const sortOptions = [
    {
      value: 'tag|asc',
      label: localStrings.deviceName
    },
  ];

  return (
    <>
      { Object.keys(itemSource).length > 0 ?
        <GeneralResult
          className={className}
          getPathToData={(result) => result.data.getDevicesByBrandId}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          getEditLink={(item) => "/app/devices/detail/" + item.id}
          deleteDialogText={localStrings.confirmMessage.removeDevice}
          deleteItemsFunction={removeDevices}
          deleteItemNotifMessage={localStrings.notif.deviceRemoved}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >
        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default Results;
