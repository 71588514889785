import React from 'react';
import {Box, Container, Grid, TextField} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import localStrings from "../../../localStrings";
import GeneralEditor from "../../../components/CrudFrom/GeneralEditor";
import {makeStyles} from "@mui/styles";
import * as Yup from "yup";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import useAppUtil from "../../../hooks/useAppUtil";
import {addDataDeviceByBrandIdToCache, getDeviceQuery, updateDeviceMutation} from "../../../gql/DeviceGql";
import useAuth from "../../../hooks/useAuth";
import {devicesRoute, getAppRoute} from "../../../routes";
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const DeviceCreateView = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const {addError} = useAppUtil()
  const {currentBrand} = useAuth()

  const buildFormValues = (item) => {
    return {
      initialValues: {
        deviceId: '',
      },
      validationSchema: {
        deviceId: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  const saveForm = async (item, values) => {


    const res = await executeQueryUtil(getDeviceQuery(values.deviceId))
    if (!res.data.getDevice) {
      addError(localStrings.errorMessage.noSuchDevice)
      return null;
    }
    if (res.data.getDevice.brandId === currentBrand().id) {
      addError(localStrings.errorMessage.deviceAlreadyAssociated)
      return null;
    }

    const created = await executeMutationUtil(updateDeviceMutation({
      id: values.deviceId,
      brandId: currentBrand().id
    }))
    await addDataDeviceByBrandIdToCache(currentBrand().id, [created.data?.updateDevice]);
    navigate(getAppRoute(devicesRoute))
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.deviceId && errors.deviceId)}
            fullWidth
            helperText={touched.deviceId && errors.deviceId}
            label={localStrings.deviceId}
            name="deviceId"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.deviceId}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Page
      className={classes.root}
      title={localStrings.addDevice}
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <GeneralEditor getItemFunction={() => null}
                         buildFormValues={buildFormValues}
                         getCardContentForm={getCardContentForm}
                         saveForm={(item, values) => saveForm(item, values)}
                         applyButton={localStrings.addDevice}
                         resetFormProp={true}
                         messageSuccess={localStrings.notif.deviceAdded}
                         titleForm={localStrings.addDevice}
                         // getPathToData={(result) => {
                         //   return  result.data.getDevice;
                         // }}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DeviceCreateView;
