import React, {useState} from 'react';
import {Box, Container, Tab, Tabs} from "@mui/material";
import Page from 'src/components/Page';
import Header from './Header';
import BoardCreateUpdateForm from "../BoardAddView/BoardCreateUpdateForm";
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";
import {MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {getUriSetting, saveUriSetting, SELECTED_TAB} from "../../../utils/optionsUriLocalStorage";
import {brandGetEnoughCredits} from "../../../utils/checkUtils";
import NoCredit from "../../NotCredit";
import useAuth from "../../../hooks/useAuth";
import ColumnDefinition from "./ColumnDefinition";
import CssDefinition from "./CssDefinition";
import Commands from "./Commands";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const ProjectDetailsView = () => {
  const classes = useStyles();
  const {currentBrand} = useAuth()
  const [currentTab, setCurrentTab] = useState(getUriSetting(SELECTED_TAB) || 'general');

  const handleTabsChange = (event, value) => {
    saveUriSetting(SELECTED_TAB, value)
    setCurrentTab(value);
  };

  return (
    <Page
      className={classes.root}
      title={localStrings.project}
    >
      {brandGetEnoughCredits(currentBrand()) ?
        <Container maxWidth="lg">
          <Header/>
          <Box mt={3}>

            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              textColor="secondary"
              variant="scrollable"
            >
              <Tab label={localStrings.general} value="general"/>
              <Tab label={localStrings.commands} value="commands"/>
              <Tab label={localStrings.columnsDefinition} value="columns"/>
              <Tab label={localStrings.customCssStyle} value="customCssStyle"/>
              {/*<Tab label={localStrings.config} value="config"/>*/}
              {/*<Tab label={localStrings.uploadLocalSile} value="uploadLocalSile"/>*/}
              {/*<Tab label={localStrings.functions} value="functions"/>*/}

            </Tabs>
            {currentTab === 'general' && <BoardCreateUpdateForm modeEdit={MODE_EDIT}/>}
            {currentTab === 'commands' && <Commands/>}
            {currentTab === 'columns' && <ColumnDefinition/>}
            {currentTab === 'customCssStyle' && <CssDefinition/>}
          </Box>
        </Container>
        :
        <NoCredit/>
      }
    </Page>
  );
};

export default ProjectDetailsView;
