import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, CardHeader, Divider, FormControlLabel, Switch, TextField} from "@mui/material";
import localStrings from "../../../localStrings";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import useAuth from "../../../hooks/useAuth";
import * as Yup from "yup";
import {executeMutationUtil} from "../../../utils/gqlUtil";
import {updateBrandMutation} from "../../../gql/BrandGql";
import {useNavigate} from "react-router";

function LifeCheckWebHook(props) {

  const {currentBrand, setBrand} = useAuth();
  const [errorUrl, setErrorUrl] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [lifeCheckWebHookUrl, setLifeCheckWebHookUrl] = useState(null);
  const [enableLifeCheckWebHook, setEnableLifeCheckWebHook] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLifeCheckWebHookUrl(currentBrand().lifeCheckWebHookUrl);
    setEnableLifeCheckWebHook(currentBrand().enableLifeCheckWebHook);
  }, [currentBrand()])

  const updateBrand = async () => {
    let brandCopy = {...currentBrand()}
    delete brandCopy.minimalCreditToLock;
    delete brandCopy.credit;
    delete brandCopy.blockWhenInsufficientCredit;
    let res = await executeMutationUtil(updateBrandMutation( {
      ...brandCopy,
      enableLifeCheckWebHook: enableLifeCheckWebHook,
      lifeCheckWebHookUrl: lifeCheckWebHookUrl
    }))
    await setBrand(res.data.updateBrand);
    navigate('/app/dashboard', { replace: true });
  }

  const validationSchema = Yup.object({
    lifeCheckWebHookUrl: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        localStrings.check.badUrlFormat
      )
    //.required(localStrings.check.required)
  })

  return (
    <div style={{ width: '100%' }}>
      <Card>
        <CardHeader title={localStrings.lifeCheckWebHook}/>
        <Divider/>
        <CardContent>
          <>

            <Box mb={2}>
              <FormControlLabel
                control={(
                  <Switch
                    checked={enableLifeCheckWebHook}
                    edge="start"
                    name="direction"
                    onChange={() => {
                      setEnableLifeCheckWebHook(!enableLifeCheckWebHook)
                    }}
                  />
                )}
                label={localStrings.enableLifeCheckWebHookUrl}
              />
            </Box>

            <Box mb={2}>
              <TextField
                error={Boolean(errorUrl)}
                fullWidth
                label={localStrings.lifeCheckWebHookUrl}
                helperText={errorText}
                onChange={(e) => {
                  setLifeCheckWebHookUrl(e.target.value)
                  validationSchema.validate({lifeCheckWebHookUrl: e.target.value})
                    .then(function(value) {
                      setErrorText(null)
                      setErrorUrl(false)
                      // setAmount(value.amount);
                    })
                    .catch(function(err) {
                      setErrorUrl(true)
                      setErrorText(err.message)
                    });
                }}
                value={lifeCheckWebHookUrl}
                variant="outlined"
              />
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant="contained"
                color="primary"
                disabled={errorUrl}
                onClick={updateBrand}
              >
                {localStrings.update}
              </Button>
            </Box>
          </>
        </CardContent>
      </Card>
    </div>
  );
}

export default LifeCheckWebHook;
