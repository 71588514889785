import React, {createContext, useEffect, useReducer} from 'react';
import firebase from 'src/lib/firebase';
import {GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
    createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail,
  signInWithEmailLink, applyActionCode, verifyPasswordResetCode, confirmPasswordReset} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {Navigate} from "react-router";
import {
  accountRoute, authChangeRoute,
  displayEventsFoundRoute,
  emailVerificationRoute,
  graphQlRoute,
  loginRoute,
  registerRoute, resetPasswordRoute
} from "../routes";
import {executeMutationUtil, executeMutationUtilSync, executeQueryUtil} from "../utils/gqlUtil";
import {getProductUserQuery, updateProductUserMutation} from "../gql/productUserGql";
import {getBrandQuery} from "../gql/BrandGql";
import SplashScreen from "../components/SplashScreen";
import moment from "moment";
import {getSuperUserWhiteListQuery} from "../gql/superUserWhiteList";
import {getBrandAdminQuery} from "../gql/BrandAdminGql";
import {
  bulkDeleteTempBrandUserMutation,
  getTempBrandUserByEmailQuery,
  getTempBrandUsersQuery
} from "../gql/tempBrandUserGql";
import cloneDeep from "clone-deep";

const db = getFirestore();

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isSkipPhoneVerification: false,
  userGetAtLeastOnEstablishment: false,
  user: null,
  userInDb: null,
  brand : null,
  superAdmin: false,
  emailVerified: false,
};

const UPDATE_DB_PROFILE = 'UPDATE_DB_PROFILE';
const AUTHENTICATE_TO_APP = 'AUTHENTICATE_TO_APP';
const INITIALIZED = 'INITIALIZED';
const AUTHENTICATED = 'AUTHENTICATED';
const E_MAIL_VERIFIED = 'E_MAIL_VERIFIED';
const BRAND = 'BRAND';
const SUPER_ADMIN = 'SUPER_ADMIN';

const reducer = (state, action) => {
  switch (action.type) {

    case BRAND: {
      const { brand } = action.payload;
      return {
        ...state,
        brand: brand,
      };
    }

    case UPDATE_DB_PROFILE: {
      const { userInDb } = action.payload;
      return {
        ...state,
        userInDb: userInDb,
      };
    }

    case SUPER_ADMIN: {
      const { superAdmin } = action.payload;
      return {
        ...state,
        superAdmin: superAdmin,
      };
    }

    case INITIALIZED: {
      const { isInitialized } = action.payload;
      return {
        ...state,
        isInitialized: isInitialized,
      };
    }

    case AUTHENTICATED: {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated: isAuthenticated,
      };
    }

    case E_MAIL_VERIFIED: {
      const { emailVerified } = action.payload;
      return {
        ...state,
        emailVerified: emailVerified,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  applyActionCodeContext: () => Promise.resolve(),
  confirmPasswordResetContext: () => Promise.resolve(),
  verifyPasswordResetCodeContext: () => Promise.resolve(),
  sendPasswordResetEmailContext: () => Promise.resolve(),
  createUserWithEmailAndPasswordContext: () => Promise.resolve(),
  signInWithEmailAndPasswordContext: () => Promise.resolve(),
  sendEmailVerificationContext: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  setAuthenticated: () => Promise.resolve(),
  setEmailVerified: () => Promise.resolve(),
  setInitialized: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setContextDbUser: () => Promise.resolve(),
  currentBrand: () => {},
  setBrand: () => Promise.resolve(),
  userInRole: () => {},
});

export const AuthProvider = ({ children }) => {

  firebase.useDeviceLanguage();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const currentBrand = () => {
    return state.brand;
  }

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(firebase, provider);
  };

  const createUserWithEmailAndPasswordContext = async (email, password) => {
    return createUserWithEmailAndPassword(firebase, email, password);
  };

  const applyActionCodeContext = async (actionCode) => {
    return applyActionCode(firebase, actionCode);
  };

  const confirmPasswordResetContext = async (actionCode, newPassword) => {
    return confirmPasswordReset(firebase, actionCode, newPassword);
  };

  const verifyPasswordResetCodeContext = async (actionCode) => {
    return verifyPasswordResetCode(firebase, actionCode);
  };

  const sendPasswordResetEmailContext = async (email) => {
    return sendPasswordResetEmail(firebase, email);
  };

  const signInWithEmailAndPasswordContext = async (user, password) => {
    return signInWithEmailAndPassword(firebase, user, password)
  }

  const sendEmailVerificationContext = async () => {
    return sendEmailVerification(firebase.currentUser);
  };


  const updateProfile = async (profileInfo) => {
    var currentUser = await firebase.currentUser;
    if (currentUser) {
      await currentUser.updateProfile(profileInfo);
    }
  };

  const setAuthenticated = async (authenticated) => {
    dispatch({
      type: AUTHENTICATED,
      payload: {
        isAuthenticated: authenticated,
      }
    });
  };

  const setEmailVerified = async (emailVerified) => {
    dispatch({
      type: E_MAIL_VERIFIED,
      payload: {
        emailVerified: emailVerified,
      }
    });
  };

  const setInitialized = async (initialized) => {
    dispatch({
      type: INITIALIZED,
      payload: {
        isInitialized: initialized,
      }
    });
  };


  const setBrand = async (brand) => {
    dispatch({
      type: BRAND,
      payload: {
        brand: brand,
      }
    });
  };

  const userInRole = (role) => {
    return (state.userInDb?.roles || []).includes(role);
  }

  const currentUser = () => {
    var user = firebase.currentUser;
    return user;
  };


  function setContextDbUser(userInDb) {
    dispatch({
      type: UPDATE_DB_PROFILE,
      payload: {
        userInDb: userInDb,
      }
    });
  }

  function setUserSuperAdmin(value) {
    dispatch({
      type: SUPER_ADMIN,
      payload: {
        superAdmin: value,
      }
    });
  }



  const logout = async () => {
    await setContextDbUser(null);
    return firebase.signOut();
  };


  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged(async (user) => {
      if (user) {
        if (!firebase.currentUser) {
          return
        }
        try {
          let token = await firebase.currentUser.getIdToken(true)
          localStorage.setItem("authToken", token)
          localStorage.setItem("genTimeToken", moment().unix())
        }
        catch (err) {
          console.log(err);
        }
        const resSuperAdmins = await executeQueryUtil(getSuperUserWhiteListQuery())
        const allSuperAdminEmails = (resSuperAdmins.data?.getSuperUsers || []).map(item => item.email);
        let isSuperAdmin = allSuperAdminEmails.includes(user.email);
        setUserSuperAdmin(isSuperAdmin);
        const res = await executeQueryUtil(getProductUserQuery(user.uid));

        setContextDbUser(res?.data?.getProductUser);
        let userDb = res?.data?.getProductUser;

        if (userDb) {
          let resUser = await executeQueryUtil(getTempBrandUserByEmailQuery(user.email));
          let tempBrandUser = resUser?.data?.getTempBrandUserByEmail;
          if (tempBrandUser && tempBrandUser.id && user.emailVerified) {
              let userDbClone = cloneDeep(userDb)
              if (!userDb.brandId) {
                userDbClone.brandId = tempBrandUser.brandId;
                let resUpdateUser = await executeMutationUtil(updateProductUserMutation(userDbClone));
                userDb = resUpdateUser.data.updateProductUser;
              }
              if (!(userDbClone?.allowedBrandIds || []).includes(tempBrandUser.brandId)) {
                if (!userDbClone.allowedBrandIds) {
                  userDbClone.allowedBrandIds = [];
                }
                userDbClone.allowedBrandIds.push(tempBrandUser.brandId);
                let resUpdateUser = await executeMutationUtil(updateProductUserMutation(userDbClone));
                userDb = resUpdateUser.data.updateProductUser;
              }
              await executeMutationUtil(bulkDeleteTempBrandUserMutation([tempBrandUser.id]));
              setContextDbUser(userDbClone);
          }
          if (userDb.brandId) {
            let resBrand;
            if (isSuperAdmin) {
              resBrand = await executeQueryUtil(getBrandAdminQuery(userDb.brandId));
              await setBrand(resBrand?.data?.getBrandAdmin);
            } else {
              resBrand = await executeQueryUtil(getBrandQuery(userDb.brandId));
              await setBrand(resBrand?.data?.getBrand);
            }
          }
        }

        setAuthenticated(true);
        setEmailVerified(user.emailVerified)
      } else {
        localStorage.removeItem("authToken")
        setAuthenticated(false)
      }

      setInitialized(true)
    });

    return unsubscribe;
  }, [dispatch]);

  function isPathAllowedNotLogged(pathname) {
    if (pathname.startsWith('/displayEvents')) {
      return true;
    }

    return [
      "/" + authChangeRoute,
      "/" + authChangeRoute,
      "/" + resetPasswordRoute,
      "/" + loginRoute,
      "/" + emailVerificationRoute,
      "/" + registerRoute,
      // "/app/" + accountRoute,
      "/" + graphQlRoute,
      "/" + displayEventsFoundRoute,
    ]
      .includes(pathname);
  }
  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  if ((!state.isAuthenticated || !state.emailVerified) && !isPathAllowedNotLogged(window.location.pathname)) {
    return <Navigate to={"/login"}/>
  }


  //if (!currentBrand() && !isPathAllowedNotLogged(window.location.pathname)) {
  // if (!currentBrand() ) {
  //   return <Navigate to={"/app/account"}/>
  // }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signInWithGoogle,
        verifyPasswordResetCodeContext,
        confirmPasswordResetContext,
        sendPasswordResetEmailContext,
        createUserWithEmailAndPasswordContext,
        signInWithEmailAndPasswordContext,
        sendEmailVerificationContext,
        applyActionCodeContext,
        logout,
        currentUser,
        updateProfile,
        setAuthenticated,
        setEmailVerified,
        setInitialized,
        setContextDbUser,
        currentBrand,
        setBrand,
        userInRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
