import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
  brandId
  projectId
  localPrinterIp
  classicPrinterName
  usbDevice
  barCodeUsbDeviceName
  mouseSystemDevPath
  version
  commandLogUrl
  wsLogUrl
  screenSize
  stripeTerminalId
  stripeLocationId
  stripeAccountId
  lite
  screenRotation
  rdpAddress
`

export const getAllDevicesQuery = () => {
  var debug = `
    query {
      getDevices {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getDevices {
        ${common}
      }
    }
  `;
}

export const getDeviceQuery = (deviceId) => {
  var debug = `
    query {
      getDevice(deviceId: "${deviceId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getDevice(deviceId: "${deviceId}") {
        ${common}
      }
    }
  `;
}


export const getAllDevicesByBrandIdQuery = (brandId) => {
  var debug = `
    query {
      getDevicesByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getDevicesByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}

export const updateDeviceMutation = (data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      updateDevice(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateDevice(data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const deleteDeviceCache = async (ids) => {
  const query = getAllDevicesQuery();
  const res = await executeQueryUtil(query);
  const newData = res.data.getDevices.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getDevices: [...newData]
      },
    });
  }
}

export const deleteDeviceByBrandIdCache = async (brandId, ids) => {
  const query = getAllDevicesByBrandIdQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getDevicesByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getDevicesByBrandId: [...newData]
      },
    });
  }
}



export const addDataDeviceByBrandIdToCache = async (brandId,datas) => {
  const query = getAllDevicesByBrandIdQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getDevicesByBrandId: [...data.data.getDevicesByBrandId, ...datas]
      },
    });
  }
}

export const addDataDeviceToCache = async (datas) => {
  const query = getAllDevicesQuery();
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getDevices: [...data.data.getDevices, ...datas]
      },
    });
  }
}
