import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Alert, Box, Button, Container, Grid, Link, Stack, TextField, Typography} from "@mui/material";
import FacebookIcon from '../icons/Facebook';
import GoogleIcon from '../icons/Google';
import useAuth from "../hooks/useAuth";
import {createProductUserMutation, getProductUserQuery} from "../gql/productUserGql";
import {executeMutationUtil, executeQueryUtil} from "../utils/gqlUtil";
import {useEffect, useState} from "react";
import localStrings from "../localStrings";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {Router} from "react-router";
import {LoadingButton} from "@mui/lab";

export async function fillUserContext(user, setAuthenticated, setEmailVerified, setContextDbUser) {
  try {
    if (!user) {
      return;
    }
    const userActive =
      {
        id: user.user.uid,
        email: user.user.email,
        displayName: user.user.displayName,
        mainUser: true,
      }

    let productUser = await executeQueryUtil(getProductUserQuery(user.user.uid));
    if (!productUser.data.getProductUser) {
      await executeMutationUtil(createProductUserMutation(user.user.uid, userActive));
    }
    await setAuthenticated(true);
    await setEmailVerified(user.user.emailVerified);
    await setContextDbUser(userActive);
  }
  catch (err) {
    console.error(err);
  }
}

const Login = () => {
  const query = new URLSearchParams(window.location.search);
  const verificationCodeParam = query.get('verificationCode');
  const [logging, setLogging] = useState(false);
  const navigate = useNavigate();
  const { signInWithGoogle, setAuthenticated, isAuthenticated, setContextDbUser, emailVerified,
    signInWithEmailAndPasswordContext, setEmailVerified, currentBrand} = useAuth();

  const [errorDisplay, setErrorDisplay] = useState(null);
  const [warningDisplay, setWarningDisplay] = useState(null);

  async function loginSubmit(values) {
    try {
      setLogging(true);
      const user = await signInWithEmailAndPasswordContext(values.email, values.password)
      await fillUserContext(user, setAuthenticated, setEmailVerified, setContextDbUser)
    }
    catch (err) {

      if (err.code == "auth/wrong-password") {
        setErrorDisplay(localStrings.warningMessage.wrongUserCredentials)
      }
      else if (err.code == "auth/user-not-found") {
        setErrorDisplay(localStrings.warningMessage.unknownUser)
      }

      else {
        setErrorDisplay(err.message)
      }
      console.log(err)
    }
    finally {
      setLogging(false);
    }

  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup
        .string()
        .max(255)
        .required('Password is required')
    }),
    onSubmit: loginSubmit
  });



  useEffect(() => {
      if (isAuthenticated && emailVerified) {
        if (currentBrand()) {
          navigate('/app/dashboard', { replace: true });
        }
        else {
          navigate('/app/account', { replace: true });
        }

      }
      if (isAuthenticated && !emailVerified) {
        setWarningDisplay(localStrings.warningMessage.pleaseValidateYourAccount)
      }
    },
    [isAuthenticated, emailVerified]);

  async function handleGoogleClick() {
    try {
      var user = await signInWithGoogle();
      await fillUserContext(user, setAuthenticated, setEmailVerified, setContextDbUser)
    } catch(err) {
      console.error(err);
    }
  }

  return (
    <>
      <Helmet>
        <title>{localStrings.login}</title>
      </Helmet>

      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}
      >
        <Container maxWidth="sm">

          {(errorDisplay || warningDisplay)&&
            <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
              {errorDisplay &&
                <Alert severity="error">{errorDisplay}</Alert>
              }
              {warningDisplay &&
                <Alert severity="warning">{warningDisplay}</Alert>
              }
            </Stack>
          }
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{my: 3}}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                {localStrings.signIn}
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                md={12}
              >
                <Button
                  color="error"
                  fullWidth
                  onClick={handleGoogleClick}
                  size="large"
                  startIcon={<GoogleIcon/>}
                  variant="contained"
                >
                  {localStrings.loginWithGoogle}
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                pb: 1,
                pt: 3
              }}
            >
              <Typography
                align="center"
                color="textSecondary"
                variant="body1"
              >
                {localStrings.orLoginEmail}

              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <Box sx={{py: 2}}>
              <LoadingButton
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={logging}
                loadingPosition="end"
              >
                {localStrings.signInNow}
              </LoadingButton>
            </Box>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {localStrings.dontHaveAccount}
              {' '}

              <Link
                href="/register"
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: 'pointer'
                }}
              >
                {localStrings.signUpNow}
              </Link>
            </Typography>

            <Typography
              color="textSecondary"
              variant="body2"
            >
              {localStrings.forgotPassword}
              {' '}

              <Link
                href="/resetPassword"
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: 'pointer'
                }}
              >
                {localStrings.resetPassword}
              </Link>
            </Typography>


            <Typography
              sx={{mt: 2}}
              color="textSecondary"
              variant="body2"
            >
              {localStrings.visitWebSite}
              {' '}

              <Link
                href="https://kioskreactor.com/"
                target='new'
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: 'pointer'
                }}
              >
                kioskreactor.com
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>

    </>
  );
};

export default Login;
