import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Breadcrumbs, Button, Container, Grid, Link, SvgIcon, Typography} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import localStrings from "../../../localStrings";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const Header = ({
  className,
  invoice,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justify="space-between"
      spacing={3}
      {...rest}
    >
      <div style={{ width: '100%' }}>
        <Container>
          <Box
            sx={{
              mt: 2,
              ml: 2,
              display: 'flex',
              // justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <Typography
              sx={{ flexGrow: 1 }}
              variant="body1"
              color="textPrimary"
            >
              {localStrings.project}
            </Typography>
            <RouterLink to="/app/projects">
              <Button
                color="primary"
                variant="contained"
              >
                {localStrings.backToProjectList}
              </Button>
            </RouterLink>
          </Box>
        </Container>
      </div>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.object.isRequired
};

export default Header;
