import React, {useEffect, useState} from 'react';
import {Card, CardContent, CardHeader, Divider, Typography} from "@mui/material";
import localStrings from "../../localStrings";
import {useParams, useSearchParams} from "react-router-dom";
import config from "../../conf/config.json";
import axios from "axios";
const conf = require('../../conf/config.json')

function PaymentDone() {
  const [searchParams, setSearchParams] = useSearchParams();
  //const payment_intent = searchParams.get("payment_intent")
  const [loading, setLoading] = useState(false);
  //const [paymentIntent, setPaymentIntent] = useState(null);

  // const getPaymentIntent = async () => {
  //   setLoading(true)
  //   const url = config.paymentServiceUrl + "/stripe/paymentIntent/" + payment_intent;
  //   try {
  //     const res = await axios.get(url,{
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     })
  //
  //     setPaymentIntent(res?.data)
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  //   finally {
  //     setLoading(false);
  //   }
  // }
  //
  // useEffect(() => {
  //   getPaymentIntent();
  // }, [])

  // function getAmount() {
  //   return parseFloat(paymentIntent.amount / 100).toFixed(2) ;
  // }

  return (
    <div style={{ width: '100%' }}>
      <Card>


        <CardHeader title={localStrings.paymentSuccess}/>
        <Divider/>
        <CardContent>
          <Typography>{localStrings.message.paymentDoneConfirm}</Typography>
          {/*{paymentIntent &&*/}
          {/*  <>*/}
          {/*    {(paymentIntent?.charges?.data || [])[0]?.paid ?*/}
          {/*      <Typography>{localStrings.formatString(localStrings.message.paymentDoneConfirm, getAmount())}</Typography>*/}
          {/*      :*/}
          {/*      <Typography>{localStrings.formatString(localStrings.message.paymentNotPaid, getAmount())}</Typography>*/}
          {/*    }*/}
          {/*  </>*/}
          {/*}*/}
        </CardContent>
      </Card>
    </div>
  );
}

export default PaymentDone;
