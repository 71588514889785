import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert, Box, Button, Container, FormHelperText, Link, Stack, TextField, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import localStrings from "../localStrings";
import {Helmet} from "react-helmet";
import {loginRoute} from "../routes";
import useAuth from "../hooks/useAuth";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {fillUserContext} from "./Login";

export const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.+]{8,}$/;

const Register = () => {
  const { setAuthenticated, setContextDbUser, setEmailVerified} = useAuth();
  const [registering, setRegistering] = useState(false);
  const navigate = useNavigate();
  const [errorDisplay, setErrorDisplay] = useState(null);

  const {createUserWithEmailAndPasswordContext, sendEmailVerificationContext} = useAuth();

  async function submitForm(values) {

    try {
      setRegistering(true);
      const user = await createUserWithEmailAndPasswordContext(values.email, values.password);
      await sendEmailVerificationContext();
      await fillUserContext(user, setAuthenticated, setEmailVerified, setContextDbUser);
      setRegistering(false);
      navigate('/login', { replace: true });

    } catch (err) {

      if (err.code == "auth/email-already-in-use") {
        setErrorDisplay(localStrings.warningMessage.userAlreadyExists)
      }
      else {
        setErrorDisplay(err.message);
      }
      console.error(err);
      setRegistering(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      policy: false
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email(localStrings.check.badEmailFormat)
        .max(255)
        .required(
          localStrings.check.required),
      password: Yup.string().matches(passwordRegEx, localStrings.check.passwordNotValid)
        .required(localStrings.check.required),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], localStrings.check.passwordsMustMatch),
    }),
    onSubmit: submitForm
  });

  return (
    <>
      <Helmet>
        <title>{localStrings.login}</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%'
        }}
      >
        <Container maxWidth="sm">

          {errorDisplay &&
            <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
              <Alert severity="error">{errorDisplay}</Alert>
            </Stack>
          }

          {/*<Link*/}
          {/*  href="/login"*/}
          {/*  passHref*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    component="a"*/}
          {/*    startIcon={<ArrowBackIcon fontSize="small" />}*/}
          {/*  >*/}
          {/*    {localStrings.backToLogin}*/}
          {/*  </Button>*/}
          {/*</Link>*/}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography
                color="textPrimary"
                variant="h4"
              >
                {localStrings.createNewAccount}
              </Typography>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {localStrings.useEmailTocreateNewAccount}
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label={localStrings.password}
              margin="normal"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
              variant="outlined"
            />
            <TextField
              error={Boolean(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation)}
              fullWidth
              helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
              label={localStrings.passwordConfirmation}
              margin="normal"
              name="passwordConfirmation"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.passwordConfirmation}
              variant="outlined"
            />
            {Boolean(formik.touched.policy && formik.errors.policy) && (
              <FormHelperText error>
                {formik.errors.policy}
              </FormHelperText>
            )}
            <Box sx={{ py: 2 }}>
              <LoadingButton
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={registering}
                loadingPosition="end"
              >
                {localStrings.signUpNow}
              </LoadingButton>
            </Box>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {localStrings.haveAnAccount + ' '}
              <Link
                variant="subtitle2"
                underline="hover"
                href={"/" + loginRoute}
              >
                {localStrings.signIn}
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Register;
