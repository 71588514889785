import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from "@mui/material";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Box as BoxIcon,
  Code as CodeIcon,
  Smartphone as SmartphoneIcon,
  Clipboard as ClipboardIcon,
  DollarSign  as DollarSignIcon
} from 'react-feather';
import NavItem from './NavItem';
import firebase from 'src/lib/firebase';
import localStrings from "../localStrings";
import useAuth from "../hooks/useAuth";


const items = (currentBrand) => {
  if (!currentBrand) {
    return [{
      href: '/app/account',
      icon: BoxIcon,
      title: localStrings.account
    }]
  }

  return [
    {
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: 'Dashboard'
    },
    {
      href: '/app/projects',
      icon: CodeIcon,
      title: localStrings.projects
    },
    {
      href: '/app/devices',
      icon: SmartphoneIcon,
      title: localStrings.devices
    },
    {
      href: '/app/boards',
      icon: ClipboardIcon,
      title: localStrings.boards
    },
    {
      href: '/app/stripePayAccounts',
      icon: DollarSignIcon,
      title: localStrings.stripePayAccountList
    },
    {
      href: '/app/account',
      icon: ClipboardIcon,
      title: localStrings.account
    },

  ]
}


const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const {currentBrand} = useAuth();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={firebase?.currentUser?.photoURL}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {firebase?.currentUser?.displayName || firebase?.currentUser?.email}
        </Typography>
        {currentBrand() &&
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {currentBrand().brandName}
          </Typography>
        }
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items(currentBrand()).map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xlDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
