import LocalizedStrings from 'react-localization';
import React from 'react';

let localStrings = new LocalizedStrings({
  en: {
    classicPrinterName: "Classic printer windows name",
    cleanBoard: "Clean board",
    cleanBoardDesc: "Remove all events present in the board",

    lifeCheckWebHook: "Life check webHook",
    lifeCheckWebHookUrl: "WebHook url",
    enableLifeCheckWebHookUrl: "Enable life check webHook",
    contentDisplaySameLine: "Display content on the same line",
    usbDevice: "Usb device",

    ipAdress: "Ip adress",
    rotationNormal: "Normal",
    rotationRight: "Right",
    rotationFlip: "Flip",
    rotationLeft: "Left",

    screenRotation: "Screen Rotation",
    maxItemPerColumn: "Maximum item numbers per column",
    forgotPassword: "I forgot my password ?",
    passwordChanged: "Your password have been changed, go back to login and sign in with your new password",
    linkSent: "An email have been sent to {0} with the information to reset your password",
    validationEmailDone: "Your email have been validated",
    orLoginEmail: "Or login with email address",
    signInNow: "Sign In Now",
    resetPassword: "Reset password",
    sendResetPassword: "Send me a linnk to reset my password",
    visitWebSite: "Visit our web site",
    dontHaveAccount: "D'ont have account ?",
    signIn: "Sign In",
    haveAnAccount: "Have an account?",
    signUpNow: "Sign Up Now",
    password: "Password",
    passwordConfirmation: "Password confirmation",
    newPassword: "New password",
    newPasswordConfirmation: "New password confirmation",
    useEmailTocreateNewAccount: "Use your email to create a new account",
    createNewAccount: "Create a new account",
    backToLogin: "Back to login",
    loginWithGoogle: "Login with Google",
    allowUserToAccess: "Allow user to access to my account",
    email: "e mail",
    roles: "Roles",
    pendingUser: "Pending user",
    pendingUsers: "Pending users",
    usersInBrand: "User in brand",
    pending: "Pending",
    existing: "Existing",
    allowOtherUsers: "Allow other users",
    brandUsers: "Brand users",
    inviteUser: "Invite other user",
    noValue: "No value",
    stripeLocationId: "Stripe location",
    stripeTerminalId: "Stripe terminal",
    name: "Name",
    brandId: "Brand Id",
    titleHeaderBoard: "Board header title",
    urlLogoBoard: "Logo image url",
    enableHeaderBoard: "Enable header in the board",
    enableDisplayMessage: "Enable message display at the bottom of the board",
    enableDisplayEventPopup: "Enable popup display when events ar created or modified",
    playSoundWhenNewEventAppears: "Play sound when new event appears in the board",
    speakWhenNewEventAppears: "speak when new event appears in the board",
    displayMessageDelay: "Time in milliseconds for each character to display info messages",
    changePageDelay: "Time in milliseconds to change page when events not fit in a single page",
    yourCodeHere: "Your code here",
    yourCssCodeHere: "Your css code",
    screenSize: "screen size (width, height)",
    browseWsLogs: "Browse to ws logs",
    browseCommandLogs: "Browse to command logs",
    wsLogUrl: "Google shared url for ws logs",
    commandLogUrl: "Google shared url for command logs",
    notFound: "Not Found",
    sendingCommand: "Sending Command to device",
    noCreditMessageOne: "You are running out of credits, please buy some credits to continue using kiosk Reactor",
    buyCredit: "Buy credits",
    notFoundMessageOne: "404: The page you are looking for isn’t here",
    notFoundMessageTwo: "You either tried some shady route or you came here by mistake.\n" +
      "Whichever it is, try using the navigation",
    loggingProjectId: "Google id project for logging",
    issueWithCode: "Issue wit your code !!",
    noIssueWithCode: "No Issue with your code !!",
    mouseSystemDevPath: "Mouse system dev path",
    fullScreen: "Edit in full screen",
    checkMyCode: "Check my code",
    blockWhenInsufficientCredit: "Block the devices when no sufficient credits",
    minimalCreditToLock: "Mininum credit amount before locking device",
    adminSection: "admin Section",
    switchBrand: "Switch company",
    searchBrand: "Search company by name",

    detail: "Detail",
    kioskCredits: "Kiosk Credits",
    chargeHistory: "Charge history",
    amount: "Amount",
    totalAmount: "Total amount",
    creationDate: "Creation date",
    date: "Date",
    paymentHistory: "Payment history",
    contactEmail: "Contact email",
    paymentSuccess: "Payment processed successfully",
    paymentDone: "Payment done",
    goToPayment: "Go to payment",
    selectAmount: "Select amount to pay",
    payAmount: "Pay {0} €",
    back: "Back",
    backToAmountSelection: "Back to amount selection",
    amountEuros: "Amount in euros",
    amountCredit: "Amount in kiosk credit",
    pay: "Pay",
    chargeMyAccountWithPrice: "Charge my account (1 Kiosk credit={0}€)",
    chargeMyAccount: "Charge my account",
    payment: "Payment",
    status: "Status",
    refresh: "Refresh",
    commandlogs: "Command logs",
    devicelogs: "Device logs",
    liteDevice: "lite device",
    wslogs: "Web socket logs",
    logs: "Logs",
    logout: "logout",
    download: "Download",
    uploadDeviceWebSite: "Upload device web site",
    files: "Files:",
    upload: "Upload",
    dragAndDropZipFile: "Drag'n'drop single zip file containing you web site, or click to select file",
    barCodeUsbDeviceName: "Bar code devive name",
    inactivityTouchScreen: "Touch Screen Inactivity configuration",
    useInactivityTouchScreen: "Use Inactivity event when touch screen is not used",
    durationInactivityTouchScreen: "Duration for inactivity time out (in seconds)",
    inactivityTouchScreenAction: "Action to do after inactivity",
    gotoHomePageAction: "Navigate To Home page",
    gotoPreviousPageAction: "Navigate To previous page",
    triggerInactivityWebhook: "Trigger inactivity event on my webhook",
    triggerCancelCameraWebhook: "Trigger cancel camera event on my webhook",
    sendEvent: "SendEvent",

    camera: "Camera",
    useCamera: "Use camera to take pictures",
    cameraCancelPictureAction: "Action to do when user cancel the camera picture page",

    cloudTextToSpeech: "Google cloud text to speech",
    cloudTextToSpeechVoiceName: "Cloud text To speech voice name",

    uploadLocalSile: "Upload project web site",

    stripePaymentTerminal: "Stripe payment terminal",
    stripePrivateKey: "Stripe private key",
    stripeTerminalPairCode: "Stripe terminal pair code",

    config: "Config",
    webhook: "webhook",
    dashboard: "Dashboard",
    general: "general",
    actions: "actions",
    commands: "Commands",
    events: "Events",

    error: "Error",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    cancel: "Cancel",
    save: "Save",
    search: "Search",
    confirm: "Confirm",
    submit: "Submit",

    update: "Update",
    create: "Create",
    brandDefinition: "Company definition",
    brand: "Company",
    brandName: "Company name",
    login: "Log in",
    emailVerification: "Email verification",

    authChange: "Auth change",
    account: "Account",
    addAccount: "Add account",

    authKey: "Authorization key",
    projectName: "Project name",
    rdpAddress: "rdp address",
    boardName: "Board name",
    projects: "Projects",
    project: "Project",
    projectList: "Project list",
    createProject: "Create project",
    editProject: "Edit Project",
    management: "Management",
    backToProjectList: "Back to projects list",

    boards: "Boards",
    board: "Board",
    boardList: "Board list",
    createBoard: "Create board",
    editBoard: "Edit board",
    backToBoardList: "Back to boards list",
    boardUrl: "Board url",
    boardId: "Board id",

    stripePayAccounts: "Stripe accounts",
    stripePayAccountName: "Account name",
    stripePayAccount: "Stripe account",
    stripePayAccountList: "Stripe account list",
    createStripePayAccount: "Create stripe account",
    editStripePayAccount: "Edit stripe account",
    backToStripePayAccountList: "Back to Stripe accounts list",
    noStripePayAccountAssociated: "No stripe pay account associated",
    noStripeLocation: "No stripe location",
    noStripeTerminal: "No stripe terminal",

    columnsDefinition: "Columns definition",
    columnKey: "column key",
    columnDisplay: "text to display in the column",

    customCssStyle: "Custom css definition",

    createBrand: "Create brand",
    updateBrand: "Update brand",

    localPrinterIp: "Local printer Ip",
    printerUsbPath: "Printer usb path",

    sendDisplayUrlToDevice: "Send url",
    sendSourceToPrinter: "Send to printer",
    sendSpeakToDevice: "Send speak text to device",

    bascToFunctionList: "Back to function list",
    createFunction: "Create Function",
    editFunction: "Edit Function",
    functions: "Functions",
    functionName: "Function name",
    functionUrl: "Function web Hook url",
    deviceName: "Device name",
    devices: "Devices",
    device: "Device",
    editDevice: "Edit Device",
    deviceList: "Device list",
    addDevice: "Add device",
    backToDeviceList: "Back to device list",
    deviceId: "Device Id",
    functionId: "Function Id",
    noProjectAssociated: "No project associated",
    displayUrlOnDevice: "Send Url to device for chrome display",
    commandsToDevice: "Sends commands to device",
    simulateEventsOnDevice: "Simulate events on device",

    simulateQrCodeScan: "Simulate QR code scan",
    qrCoreValue: "QR code value",

    webHooksEvent: "Your webHook url for events coming from device",
    webHookEventUrl: "WebHook for events coming from device",

    settings: "Settings",
    homePageUrl: "Home page to display on the device",

    driveUrls: "Urls list to drive your device",
    changeChromeAddressUrl: "Url to use to change displayed page on device",
    printTicketUrl: "Url to use to print tickets on device",
    rebootUrl: "Url to reboot your device",
    speakUrl: "Url for speak",

    cameraUrl: "Device url to display the camera page",

    errorMessage: {
      userAlreadyDefined: "This user already have an account",
      issueFileReading: "Issue with file reading",
      issueFileAbort: "File rading aborted",
      invalidJsonFile: "invalid json file",
      unableToFetchData: "Unable to fetch data",
      noSuchDevice: "No device found with this id, please use the Id displayed on your device",
      deviceAlreadyAssociated: "This device is already associated to your company"
    },
    check: {
      required: 'Required',
      badUrlFormat: 'Bad url format',
      fieldRequired: "The field is required",
      badEmailFormat: "The email have a wrong format",
      minuteFormat: "Bad format for time duration in minutes",
      msFormat: "Bad format for time duration in milliseconds",
      mustBeGreaterThan: "Must be greater than {0}",
      amountFormat: "The amount should be a decimal with maximum two digits after comma",
      minAmount: "The minimal amount is 50 Credits",
      passwordsMustMatch: "Passwords must match",
      passwordNotValid: 'Password must have Minimum eight characters, at least one letter, one number and one special character among: @$!%*#?&.+',
    },
    confirmMessage: {
      deleteStripePayAccount: "Are you sure you want to delete selected account(s) ?",
      deleteBoard: "Are you sure you want to delete selected board(s) ?",
      deleteProject: "Are you sure you want to delete selected project(s) ?",
      removeDevice: "Are you sure you want to remove selected device(s) ?",
      removeFunction: "Are you sure you want to remove selected functions(s) ?",
      reboot: "Are you sure you want to reboot this device ?",
      update: "Are you sure you want to update and reboot this device ?",
    },
    notif : {
      userPendingCreated: "Invitation sent",
      userPendingDeleted: "Pending user deleted",

      userDeleted: "User deleted",
      userCreated: "User created",
      userUpdated: "User updated",

      boardDeleted: "Board deleted",
      boardUpdated: "Board updated",
      boardCreated: "Board created",

      stripePayAccountDeleted: "Account deleted",
      stripePayAccountUpdated: "Account updated",
      stripePayAccountCreated: "Account created",

      boardColumnDeleted: "Board column deleted",
      boardColumnUpdated: "Board column updated",
      boardColumnCreated: "Board column created",

      projectDeleted: "Project deleted",
      projectUpdated: "Project updated",
      projectCreated: "Project created",

      brandUpdated: "Brand updated",
      brandCreated: "Brand created",

      deviceDeleted: "Device deleted",
      deviceRemoved: "Device removed",
      deviceUpdated: "Device updated",
      deviceAdded: "Device added",

      functionDeleted: "Function deleted",
      functionRemoved: "Function removed",
      functionUpdated: "Function updated",
      functionCreated: "Function created",

      cssStyleUpdated: "CSS Style updated",

      rebootSent: "Reboot request sent !!",
      takePicture: "Take picture request sent !!",
      updateSent: "Update request sent !!",
      changeUrlSent: "Change Url request sent !!",
      speakSent: "Speak request sent !!",
      requestTicketSent: "Ticket request sent !!",

      siteUploaded: "Web site zip file uploaded",

      QrCodeScanSent: "Qr code scan sent !!",
      switchBrand: "Current company is now {0}",

      rdpStarted: "Rdp service started",
      rdpStopped: "Rdp service stopped",
      boardCleaned: "Board cleaned",
    },

    warningMessage: {
      needSuperUser: "Need to be a super user",
      noPayerUser: "No payer user found",
      moreThanOnePayerUser: "More than one payer user found",

      invalidInvitationKey: "Invalid Invitation Key",
      message404: "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.",
      userAlreadyDefined: "This user is already defined in the system",
      atLeastoneEstabishmentShouldRemains: "At least one establishment should remains in the list",
      notAuthorizedPage: "Access to this page is not authorized",
      notInContextPage: "This page page is not accessible in this context",
      fileReadingAborted: "file reading was aborted",
      fileReadingFailed: "file reading has failed",
      fileSameName: "file with same name already exist",
      integrityError: "One or several references already exists on this object",
      noBrandCreated: "To finish subscription, please fill you company info",

      wrongUserCredentials: "Wrong credentials, please verify user and password",
      invalidActionCode: "Invalid action code",
      userAlreadyExists: "This user already exists",
      unknownUser: "Unknown user",
      pleaseValidateYourAccount: "Your account have not been verified, please check your mailbox and activate your account, then try to login again",
    },

    message: {
      paymentDoneConfirm: "Thank for your payment, your account have been credited",
      // paymentNotPaid: "your payment of {0}€ is in incomplete status please contact our support",
    },

    roleList: {
      user: "User",
      admin: "Administrator",
    },

    materialTable: {
      actions: "actions",
      noRecords: "No records to display",
      filterRow: "Filter",
      add: "Add",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      save: "Save",
      search: "Search",
      labelRowsSelect: "Rows",
      labelRowsPerPage: "Rows per page:",
      firstAriaLabel: "First page",
      firstTooltip: "First page",
      previousAriaLabel: "Previous page",
      previousTooltip: "Previous page",
      nextAriaLabel: "Next page",
      nextTooltip: "Next page",
      lastAriaLabel: "Last Page",
      lastTooltip: "Last Page",
      deleteText: "Are you sure delete this row ?",
    },

    actionReboot: "Reboot device",
    actionRebootDesc: "Reboot the remote device to take into account changes",
    actionTakePicture: "Take picture",
    actionTakePictureDesc: "Display camera web page to take pictures and manage it with your web hook",
    actionUpdateReboot: "Update and reboot device",
    actionUpdateRebootDesc: "Update the device with the latest version of the firmware current version:{0} latest version available:{1}",
    actionUpdateWebSite: "Update web site",
    actionUpdateWebSiteDesc: "Update the web site associated to the selected project",
    actionToHomeSiteDeviceWebSite: "Device Web site",
    actionStartRdp: "Start RDP",
    actionStartRdpDesc: "Start RDP to allow remote connection",
    actionStopRdp: "Stop RDP",
    actionStopRdpDesc: "Stop RDP",

    actionToHomeSiteDeviceWebSiteDesc: "Navigate to your home web site installed on your device",
    actionToHomePage: "Home page",
    actionToHomePageDesc: "Navigate to home page defined in the project",
  },

});

export default localStrings;
