const getValueByPath = (obj, path) => {
  try {
    for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
      obj = obj[path[i]];
    }
    ;
    return obj || "";
  }
  catch (err) {
    return "";
  }
};

export default getValueByPath;
