import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
  stripePrivateKey
`

export const getAllStripePayAccountsQuery = (brandId) => {
  var debug = `
    query {
      getStripePayAccounts(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getStripePayAccounts(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}


export const getStripePayAccountQuery = (brandId, stripePayAccountId) => {
  var debug = `
    query {
      getStripePayAccount(brandId: "${brandId}", stripePayAccountId: "${stripePayAccountId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getStripePayAccount(brandId: "${brandId}", stripePayAccountId: "${stripePayAccountId}") {
        ${common}
      }
    }
  `;
}

export const bulkDeleteStripePayAccountMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkStripeDeletePayAccount(brandId: "${brandId}", stripPayAccountIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkStripeDeletePayAccount(brandId: "${brandId}", stripPayAccountIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const createStripePayAccountMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
    [] );

  var debug = `
    mutation {
      addStripePayAccount(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addStripePayAccount(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const updateStripePayAccountMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"],
    [] );

  var debug = `
    mutation {
      updateStripePayAccount(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateStripePayAccount(brandId: "${brandId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const deleteStripePayAccountCache = async (brandId, ids) => {
  const query = getAllStripePayAccountsQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getBoards.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getStripePayAccounts: [...newData]
      },
    });
  }
}

export const addDataStripePayAccountToCache = async (brandId, datas) => {
  if (!datas) {
    return
  }
  const query = getAllStripePayAccountsQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getStripePayAccounts: [...data.data.getStripePayAccounts, ...datas]
      },
    });
  }
}

