import React, {useEffect, useState} from 'react';
import {Button, Card, CardContent, CardHeader, Divider} from "@mui/material";
import localStrings from "../../../localStrings";
import {getButtonWithDesc} from "../../Devices/DeviceDetailsView/SendCommands";
import {useParams} from "react-router-dom";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {deleteDisplayEventsByBoardMutation, getBoardQuery} from "../../../gql/boardGql";
import useAuth from "../../../hooks/useAuth";
import {createBrandMutation} from "../../../gql/BrandGql";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import {useSnackbar} from "notistack";

function Commands() {
  let {boardId} = useParams();
  const {currentBrand} = useAuth();
  const [board, setBoard] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadBoard = async () => {
      const projectRes = await executeQueryUtil(getBoardQuery(currentBrand().id, boardId));
      const projectData = projectRes?.data?.getBoard;
      setBoard(projectData)
    }
    loadBoard();
  }, [])

  async function cleanBoard() {
      let res = await executeMutationUtil(deleteDisplayEventsByBoardMutation(currentBrand().id,
          board.id));
      enqueueSnackbarWithSound(enqueueSnackbar, null, null, localStrings.notif.boardCleaned);
  }

  return (
    <Card>
      {board &&
        <>
          <CardHeader title={localStrings.commandsToDevice}/>
          <Divider/>

          <CardContent>
            {getButtonWithDesc(
              <Button
                //disabled={device.version === systemVersion.version}
                sx={{margin: 1}}
                onClick={() => cleanBoard()}
                color="primary"
                variant="contained">
                {localStrings.cleanBoard}
              </Button>
              , localStrings.cleanBoardDesc)}
          </CardContent>
        </>
      }
    </Card>
  );
}

export default Commands;
